import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  width: 100%;
  cursor: pointer;
`;

export const DateWrapper = styled.div`
  width: 46px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 7px;
  background: ${({ theme }) => theme.colors.solitude};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DateDayWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const DateMonthWrapper = styled.div`
  font-weight: 600;
  font-size: 12px;
  font-style: normal;
  line-height: 20px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

export const TitleWrapper = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;

  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const TimeWrapper = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.marrow};
`;
