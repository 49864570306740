import { useTranslation } from 'react-i18next';

import { Wrapper } from './GamificationWidget.styles';
import { RankingPosition } from './RankingPosition';

import { GamificationSummary } from '@/components/GamificationSummary';
import { WidgetBase } from '@/components/WidgetBase';
import { WidgetProps } from '@/types';

type Props = WidgetProps;

export const GamificationWidget: React.FC<Props> = props => {
  const { t } = useTranslation('CommonDashboardTranslations');

  return (
    <WidgetBase title={t('WHAT_TO_DO_TODAY')} {...props}>
      <Wrapper>
        <GamificationSummary />
        <RankingPosition />
      </Wrapper>
    </WidgetBase>
  );
};
