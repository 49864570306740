import { IFile } from '@sdk/contracts';

import { AudioPlayer } from '@/components/AudioPlayer';

type Props = {
  file: IFile;
};

export const AudioPreview: React.FC<Props> = ({ file }) => {
  return <AudioPlayer file={file} />;
};
