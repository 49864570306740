import { ETranscriptionStatus } from '@constants';
import styled from 'styled-components';

export const TitleWrapper = styled.div<{ status: ETranscriptionStatus }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > :last-child {
    & > .ant-tag {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ status, theme }) =>
        ({
          [ETranscriptionStatus.PROCESSING]: theme.colors.neoreg,
          [ETranscriptionStatus.READY]: theme.colors.meadreg,
          [ETranscriptionStatus.NOT_READY]: theme.colors.error
        }[status])};
    }
  }
`;
