import { ESectionReviewAnswer, ESectionReviewQuestion } from '@constants';
import { ISectionReview } from '@sdk/contracts';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale } from 'chart.js';
import React, { useEffect, useMemo, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import {
  ChartContainer,
  TitleWrapper,
  ChartWrapper,
  Wrapper,
  NoReviewsWrapper
} from './ReviewsCharts.styles';

import { useSectionReviews } from '@/store';

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);

const CHART_OPTIONS = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  }
};

type Props = {
  sectionId: string;
};

type ReviewCounts = {
  [answer in ESectionReviewAnswer]: number;
};

type ChartData = {
  [question: string]: ReviewCounts;
};

const aggregateReviews = (reviews: ISectionReview[]): ChartData => {
  const aggregated: ChartData = {};

  reviews.forEach(review => {
    Object.entries(review.answers).forEach(([question, answer]) => {
      if (!aggregated[question]) {
        aggregated[question] = {
          TOTALLY_DISAGREE: 0,
          DISAGREE: 0,
          AGREE: 0,
          TOTALLY_AGREE: 0
        };
      }
      aggregated[question][answer as ESectionReviewAnswer] += 1;
    });
  });

  return aggregated;
};

export const ReviewsCharts: React.FC<Props> = ({ sectionId }) => {
  const { sectionReviews } = useSectionReviews(sectionId);
  const [chartData, setChartData] = useState<ChartData>({});
  const theme = useTheme();
  const { t } = useTranslation('SectionReviewTranslations');

  const labels = useMemo(
    () => Object.values(ESectionReviewAnswer).map(value => t(value)),
    [ESectionReviewAnswer]
  );
  const colors = useMemo(
    () => [theme.colors.error, theme.colors.koi, theme.colors.sandstorm, theme.colors.meadreg],
    [theme]
  );

  useEffect(() => {
    if (sectionReviews) {
      const aggregatedData = aggregateReviews(sectionReviews);
      setChartData(aggregatedData);
    }
  }, [sectionReviews]);

  if (!chartData || Object.keys(chartData).length === 0)
    return <NoReviewsWrapper>{t('NO_REVIEWS')}</NoReviewsWrapper>;

  return (
    <Wrapper>
      {Object.keys(chartData).map((question, index) => {
        const data = {
          labels,
          datasets: [
            {
              data: Object.values(chartData[question]),
              backgroundColor: colors
            }
          ]
        };

        return (
          <ChartContainer key={index}>
            <TitleWrapper>{t(question as ESectionReviewQuestion)}</TitleWrapper>
            <ChartWrapper>
              <Pie data={data} options={CHART_OPTIONS} />
            </ChartWrapper>
          </ChartContainer>
        );
      })}
    </Wrapper>
  );
};
