import styled from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 32px;
`;

export const ColumnWrapper = styled.div`
  min-width: 300px;
`;
