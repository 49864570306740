import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: ${({ theme }) => theme.colors.gunmetal} !important;
`;

export const TitleWrapper = styled.div`
  font-weight: 700;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

export const StyledDot = styled.div<{ backgroundColor: string }>`
  border: 4px solid ${({ backgroundColor }) => backgroundColor};
  border-radius: 4px;
  box-sizing: content-box;
  height: 0px;
  margin: 0px 4px;
  width: 0px;
`;
