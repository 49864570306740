import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  border: ${({ theme }) => `1px solid ${theme.colors.solitude}`};
  box-shadow: ${({ theme }) => theme.boxShadow.primary};
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 80px 40px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  max-width: 460px;
`;

export const DescriptionWrapper = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

export const EmptyTitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.2px;
`;

export const CollapseWrapper = styled.div`
  width: 100%;

  .ant-collapse {
    width: 100%;
  }
`;
