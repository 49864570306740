import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0px;
  .ant-radio-group {
    width: 100%;
  }
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

export const AnswerWrapper = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
  gap: 8px;

  & > .ant-form-item {
    width: 100%;
  }

  & > .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.meadreg};
    border-color: ${({ theme }) => theme.colors.meadreg};
  }
`;
