import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 240px;
  height: min-content;
  padding: 24px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  cursor: pointer;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.cadet};
  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: 768px) {
    width: 100%;
    padding: 16px 8px;
    flex-direction: row;
    border-radius: 0;
    border: none;
  }
`;

export const TagLabelWrapper = styled.div`
  color: ${({ theme }) => theme.colors.gunmetal};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.beau};
  background: ${({ theme }) => theme.colors.solitude};
  margin-bottom: 8px;
  padding: 0px 4px;
  width: fit-content;
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
