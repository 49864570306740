import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  gap: 1rem;
  max-height: 400px;
  margin-bottom: 2rem;
`;

export const Subject = styled.section`
  background-color: ${({ theme }) => theme.colors.solitude};
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
`;

export const TitleWrapper = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
  }
`;

export const DateSpanWrapper = styled.span`
  color: ${({ theme }) => theme.colors.cadet};
  font-size: 10px;
  font-weight: 400;
  margin-right: 0.5rem;
  padding-right: 0.5rem;
  border-right: 1px solid ${({ theme }) => theme.colors.cadet};

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    margin-left: 0.5rem;
  }
`;

export const HourSpanWrapper = styled.span`
  color: ${({ theme }) => theme.colors.cadet};
  font-size: 10px;
  font-weight: 400;
`;

export const InnerWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 13px;

  & > :first-child {
    flex: none;
  }

  & > section {
    flex-grow: 1;
  }
`;
