import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected {
    & > .ant-table-cell {
      background-color: ${({ theme }) => theme.colors.meadghost} !important;
    }
  }
`;

export const TooltipWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const SelectionWrapper = styled.div`
  border-top: 1px dashed ${({ theme }) => theme.colors.gunmetal};
  padding-top: 4px;
`;
