import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.ghost};
  display: flex;
  width: 242px;
  padding: 24px 16px 24px 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
