import { IEventByType } from '@sdk/contracts';
import { dateProvider } from '@utils';

/**
 * It takes an array of events and returns an array of arrays of events, grouped by month and year
 * @param {IEventByType[]} events - IEventByType[] - this is the array of events that we want to group by date.
 * @returns An array of arrays. Each array contains a date string and an array of events.
 */
export const groupEventsByDate = (events: IEventByType[]) => {
  const dates = events.reduce((acc, event) => {
    const date = dateProvider.zonedTimeToUtcFormat(event.startDate, 'MMMM yyyy');
    if (!acc[date]) acc[date] = [];
    acc[date].push(event);
    return acc;
  }, {} as { [key: string]: IEventByType[] });

  for (const date in dates) {
    dates[date].sort((a, b) =>
      dateProvider.diff(
        dateProvider.parse(b.startDate.dateTime),
        dateProvider.parse(a.startDate.dateTime),
        'milliseconds'
      )
    );
  }

  return Object.entries(dates);
};
