import { IEventByType } from '@sdk/contracts';
import { dateProvider } from '@utils';

export const getEventsOfMonth = (groupedEvents: [string, IEventByType[]][], currentDate: Date) =>
  groupedEvents.reduce((acc: IEventByType[], curr: [string, IEventByType[]]) => {
    const [month, monthEvents] = curr;
    const isMonthWithEvents = month.includes(dateProvider.format(currentDate, 'MMMM yyyy'));
    if (isMonthWithEvents) return [...acc, ...monthEvents];
    return acc;
  }, [] as IEventByType[]);
