import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { CourseTranslations } from '@/pages/Common';
import { useMyTagsList } from '@/store';
import { handleInputValue } from '@/utils';

type Props = {
  value?: string[] | string;
  filters?: string[];
  allowClear?: boolean;
  onChange?: (value: string[] | string) => void;
  mode?: 'multiple' | 'tags';
  maxTagCount?: number | 'responsive';
  placeholder?: keyof typeof CourseTranslations;
};

export const TagSelector: React.FC<Props> = ({
  value,
  onChange,
  mode,
  filters,
  allowClear,
  maxTagCount = 'responsive',
  placeholder = 'SELECT_TAG'
}) => {
  const { t } = useTranslation('CourseTranslations');
  const { tags = [], isLoading } = useMyTagsList();

  const options = tags
    .filter(tag => {
      if (!filters) return true;
      return filters.includes(tag._id);
    })
    .map(cat => ({ label: cat.name, value: cat._id }));

  return (
    <Select
      showSearch
      allowClear={allowClear}
      mode={mode}
      maxTagCount={maxTagCount}
      options={options}
      onChange={onChange}
      loading={isLoading}
      placeholder={t(placeholder as keyof typeof CourseTranslations)}
      value={handleInputValue(value, mode === 'multiple')}
      filterOption={(input, option) =>
        (option?.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
      }
    />
  );
};
