import { Tag } from 'antd';
import styled from 'styled-components';

import { hexToRGBA } from '@/utils';

export const Wrapper = styled.div<{ bordered?: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 12px 8px;
  border-radius: 4px;
  border: ${({ bordered, theme }) => (bordered ? `2px solid ${theme.colors.marrow}` : 'none')};
`;

export const StartContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 90px;

  @media (max-width: ${({ theme }) => theme.mediaQueries.sm}) {
    min-width: 60px;
  }
`;

export const EndContentWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  color: ${({ theme }) => theme.colors.charcoal};
  min-width: 200px;

  @media (max-width: ${({ theme }) => theme.mediaQueries.sm}) {
    min-width: 120px;
  }
`;

export const PositionWrapper = styled.div<{ position: number }>`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  width: 20px;
  color: ${({ theme, position }) =>
    position === 1
      ? theme.colors.warning
      : position === 2
      ? theme.colors.charcoal
      : position === 3
      ? theme.colors.koi
      : theme.colors.meadreg};
`;

export const BadgeWrapper = styled.img`
  width: 24px;
  height: 24px;
`;

export const AverageScoreWrapper = styled(Tag)<{ score: number }>`
  width: 50px;
  text-align: center;
  color: ${({ theme, score }) =>
    score > 7 ? theme.colors.meadreg : score > 5 ? theme.colors.koi : theme.colors.error};
  background-color: ${({ theme, score }) =>
    hexToRGBA(
      score > 7 ? theme.colors.meadreg : score > 5 ? theme.colors.koi : theme.colors.error,
      0.1
    )};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border: none;
  border-radius: 4px;
  padding: 3px 6px;
`;

export const PointsWrapper = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  justify-content: end;
  color: ${({ theme }) => theme.colors.warning};
  width: 85px;

  & > img {
    width: 24px;
  }

  @media (max-width: ${({ theme }) => theme.mediaQueries.sm}) {
    width: 75px;
    font-size: 18px;

    & > img {
      width: 20px;
    }
  }
`;

export const NameWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.mediaQueries.sm}) {
    max-width: 80px;
  }
`;
