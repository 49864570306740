import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  gap: 8px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
  }
`;

export const TitleText = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    width: unset;
  }

  .ant-input:hover {
    border-color: ${({ theme }) => theme.colors.meadlight};
  }

  .ant-input:focus {
    border-color: ${({ theme }) => theme.colors.meadlight};
    box-shadow: unset;
  }

  .ant-input-group-addon {
    & > button:hover {
      border-color: ${({ theme }) => theme.colors.meadlight};
    }

    .ant-btn-icon:hover {
      color: ${({ theme }) => theme.colors.meadlight};
    }
  }
`;
