/* eslint-disable @typescript-eslint/no-explicit-any */
import { EActivityType } from '@constants';
import { Alert } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper } from './ActivityTypes.styles';
import { AudioType } from './AudioType';
import { DeliverableType } from './DeliverableType';
import { InteractiveVideoType } from './InteractiveVideoType';
import { PdfType } from './PdfType';
import { QuizType } from './QuizType';
import { VideoType } from './VideoType';

type Props = {
  value?: any;
  onChange?: (value: any) => void;
  type?: keyof typeof EActivityType | null;
};

const options: { [key in EActivityType]: React.FC<Omit<Props, 'type'>> } = {
  [EActivityType.VIDEO]: VideoType,
  [EActivityType.AUDIO]: AudioType,
  [EActivityType.INTERACTIVE_VIDEO]: InteractiveVideoType,
  [EActivityType.PDF]: PdfType,
  [EActivityType.QUIZ]: QuizType,
  [EActivityType.DELIVERABLE]: DeliverableType
};

export const ActivityTypes: React.FC<Props> = props => {
  const { type, value, onChange } = props;

  const { t } = useTranslation('CourseTranslations');

  if (!type)
    return (
      <Alert type="info" role="alert" showIcon message={t('SELECT_AN_ACTIVITY_TYPE_TO_CONTINUE')} />
    );

  const Component = options[type];
  return <Wrapper>{<Component value={value} onChange={onChange} />}</Wrapper>;
};
