import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const SelectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.gunmetal};
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  align-items: center;
`;

export const DropdownWrapper = styled.div`
  position: relative;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  z-index: 3;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gunmetal};
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  margin-top: 2px;
`;
