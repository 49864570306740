import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { UpcomingEvents } from '@/components/UpcomingEvents';
import { WidgetBase } from '@/components/WidgetBase';
import { WidgetProps } from '@/types';

type Props = WidgetProps;

export const StudentLiveClassesWidget: React.FC<Props> = props => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const navigateTo = useNavigate();

  const navigateToCalendar = () => navigateTo('/content/calendar');

  return (
    <WidgetBase onHeaderClick={navigateToCalendar} title={t('LIVE_CLASSES')} {...props}>
      <UpcomingEvents />
    </WidgetBase>
  );
};
