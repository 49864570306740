import styled from 'styled-components';

type Props = {
  isStudent: boolean;
};

export const Message = styled.section<Props>`
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.beau};
  background-color: ${({ isStudent, theme }) => (isStudent ? theme.colors.solitude : '')};
  & > :first-child {
    display: flex;
    margin-bottom: 0.4rem;
    color: ${({ theme }) => theme.colors.cadet};
    font-size: 10px;
    & > :first-child {
      margin-right: 0.5rem;
      padding-right: 0.5rem;
      border-right: 1px solid ${({ theme }) => theme.colors.cadet};
    }
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 13px;
  & > section {
    flex-grow: 1;
  }

  .ant-avatar {
    flex-shrink: 0;
  }
`;

export const DocWrapper = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  i {
    padding: 12px;
    font-size: 22px;
  }
`;
