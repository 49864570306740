import styled from 'styled-components';

export const Wrapper = styled.div<{ color: string }>`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  min-width: 372px;

  flex: 1 0 0;
  align-self: stretch;
  overflow-y: auto;
  border-radius: 8px;
  background: ${({ color }) => color};
`;

export const TitleWrapper = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.marrow};
`;
