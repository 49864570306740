import styled from 'styled-components';

export const Img = styled.img`
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    max-width: 150px;
  }
`;

export const ModalTitle = styled.h2`
  font-size: 32px;
  margin: 0;
  padding: 10px 24px;
  font-weight: 700;
  border-bottom: 1px solid ${({ theme }) => theme.colors.beau};
`;

export const Content = styled.div`
  padding: 0px 24px;
  max-height: 500px;
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    max-height: 200px;
  }
  overflow: auto;
  .fa-CheckmarkOutline {
    color: ${({ theme }) => theme.colors.meadreg};
  }

  .fa-CloseOutline {
    color: ${({ theme }) => theme.colors.error};
  }

  .fa-circle-light {
    color: ${({ theme }) => theme.colors.beau};
  }
`;
