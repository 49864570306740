import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { Wrapper } from './ReportsWidget.styles';

import { WidgetBase } from '@/components/WidgetBase';
import { useDownloadUploadedFilesData, useSubscriptionsStats, useTicketsStats } from '@/store';
import { WidgetProps } from '@/types';

export const ReportsWidget: React.FC<WidgetProps> = props => {
  const { t } = useTranslation('CommonDashboardTranslations');

  const { isCsatLoading, downloadCsatData, isTicketsByTagLoading, downloadTicketsByTagData } =
    useTicketsStats();
  const { isDownloadActivityRatingLoading, downloadActivityRating } = useSubscriptionsStats();
  const { isUploadedFilesLoading, downloadUploadedFilesData } = useDownloadUploadedFilesData();

  return (
    <WidgetBase title={t('STATS')} {...props}>
      <Wrapper>
        <Button type="primary" loading={isCsatLoading} onClick={() => downloadCsatData()}>
          {t('DOWNLOAD_CSAT')}
        </Button>
        <Button
          type="primary"
          loading={isTicketsByTagLoading}
          onClick={() => downloadTicketsByTagData()}>
          {t('DOWNLOAD_TICKETS')}
        </Button>
        <Button
          type="primary"
          loading={isDownloadActivityRatingLoading}
          onClick={() => downloadActivityRating()}>
          {t('DOWNLOAD_ACTIVITY_RATING')}
        </Button>
        <Button
          type="primary"
          loading={isUploadedFilesLoading}
          onClick={() => downloadUploadedFilesData()}>
          {t('DOWNLOAD_UPLOADED_FILES')}
        </Button>
      </Wrapper>
    </WidgetBase>
  );
};
