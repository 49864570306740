import styled from 'styled-components';

export const ItemsWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const Wrapper = styled.div`
  margin-bottom: 12px;
`;

export const FormulaWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const WarningWrapper = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-weight: 600;
`;

export const TitleWrapper = styled.div`
  font-weight: 500;
`;

export const QuizItemWrapper = styled.li`
  color: ${({ theme }) => theme.colors.meadreg};
  font-weight: 500;
  cursor: pointer;
  padding-left: 8px;
`;
