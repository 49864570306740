import styled from 'styled-components';

import { Icon } from '@/components/Icon';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.marrow};
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  :hover {
    .addon-icon {
      transform: scale(1.2);
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
`;

export const TitleWrapper = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
`;

export const StyledIcon = styled(Icon)`
  transition: transform 0.3s ease;
  transform-origin: left;
`;
