import { RequestService } from '../request';

import { ICreatePostCategory, IPostCategory } from './post-category.contract';

export class PostCategoryService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async getPostCategories() {
    return this.fetcher<IPostCategory[]>('/blog/post-categories');
  }

  async createPostCategory(createPostCategory: ICreatePostCategory) {
    return this.poster<IPostCategory, ICreatePostCategory>(
      '/blog/post-categories',
      createPostCategory
    );
  }

  async remove(categoryId: string) {
    return this.deleter(`/blog/post-categories/${categoryId}`);
  }
}
