import styled from 'styled-components';

export const Wrapper = styled.div`
  & > i {
    font-size: 20px;
  }

  .IconFaceSatisfiedFilled {
    fill: ${({ theme }) => theme.colors.meadreg};
  }
  .IconFaceNeutralFilled {
    fill: ${({ theme }) => theme.colors.warning};
  }
  .IconFaceDissatisfiedFilled {
    fill: ${({ theme }) => theme.colors.error};
  }
`;
