import styled from 'styled-components';

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    flex-direction: row;
  }
`;

type Props = {
  isPublished: boolean;
};

export const ExamDateWrapper = styled.div<Props>`
  color: ${({ theme, isPublished }) => (isPublished ? theme.colors.gunmetal : theme.colors.marrow)};

  & > ul {
    padding-inline-start: 16px;
  }
`;
