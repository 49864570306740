import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 0px 32px;
  align-items: center;
  justify-content: center;
  & > div {
    width: 400px;
    max-width: 400px;
  }
  & > div > div > img {
    margin-bottom: 41px;
  }

  & > div > form {
    text-align: center;
    & > :nth-child(3) {
      display: flex;
      justify-content: space-between;
      & > :nth-child(2) {
        color: ${({ theme }) => theme.colors.marrow};
      }
    }

    & > button {
      margin-top: 32px;
    }
  }
`;
