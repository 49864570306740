import { Flex } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  padding: 16px;
  flex: 1 0 0;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.solitude};
  min-height: 610px;
  height: 100%;
`;

export const TitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const ActiveWrapper = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const ImageWrapper = styled.img`
  width: 16px;
  height: 16px;
`;

export const ButtonWrapper = styled(Flex)`
  cursor: pointer;
`;

export const IconWrapper = styled(Flex)`
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.gunmetal};
`;

export const NameWrapper = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;
