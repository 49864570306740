import { ICreatePostCategory } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const PostCategoryService = sdk.getService('PostCategoryService');

export const useMutatePostCategory = () => {
  const { t } = useTranslation('CommonPostTranslations');
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['post-categories'] });

  const { isPending: isCreating, mutate: createPostCategory } = useMutation({
    mutationFn: (data: ICreatePostCategory) => PostCategoryService.createPostCategory(data),
    onSuccess: () => {
      onSuccess();
      message.success(t('CATEGORY_CREATED_SUCCESSFULLY'));
    }
  });

  const { isPending: isDeleting, mutate: removePostCategory } = useMutation({
    mutationFn: (categoryId: string) => PostCategoryService.remove(categoryId),
    onSuccess: () => {
      onSuccess();
      message.success(t('CATEGORY_REMOVED_SUCCESSFULLY'));
    }
  });

  return {
    isPending: isCreating || isDeleting,
    createPostCategory,
    removePostCategory
  };
};
