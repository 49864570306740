import { IFile } from '@sdk/contracts';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

type MediaProps = {
  file: IFile;
};

export const VideoPreview: React.FC<MediaProps> = ({ file }) => {
  const { t } = useTranslation('CourseTranslations');

  if (!file || !file?.url)
    return <Alert type="error" showIcon message={t('CANT_LOAD_ACTIVITY_RESOURCE')} />;
  return (
    <ReactPlayer
      controls
      width="100%"
      height="100%"
      url={file.url}
      style={{ maxWidth: 472, maxHeight: 255 }}
      config={{
        file: {
          attributes: {
            controlsList: 'nodownload',
            onContextMenu: function () {
              return false;
            }
          }
        }
      }}
    />
  );
};
