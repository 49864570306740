import { ETicketStatus } from '@constants';
import { ITicketsFilters } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 3;
const DEFAULT_FILTERS = { status: [ETicketStatus.IN_PROGRESS, ETicketStatus.NEW] };

interface IStore {
  page: number;
  limit: number;
  filter: ITicketsFilters;
  setPageAndLimit: (page: number, limit: number) => void;
  setFilter: (filter: ITicketsFilters) => void;
}

const store = createStore<IStore>(set => ({
  page: INITIAL_PAGE,
  limit: INITIAL_LIMIT,
  filter: DEFAULT_FILTERS,
  setPageAndLimit: (page: number, limit: number) => set(state => ({ ...state, page, limit })),
  setFilter: (filter: ITicketsFilters) => set(state => ({ ...state, page: INITIAL_PAGE, filter }))
}));

const TicketService = sdk.getService('TicketService');

export const useMyTicketsWidget = () => {
  const { page, limit, filter, setPageAndLimit, setFilter } = store();

  const queryKey = ['tickets-widget', `page-${page}`, `limit-${limit}`, JSON.stringify(filter)];
  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn: () => TicketService.getTickets(undefined, { page, limit }, undefined, filter)
  });

  return {
    tickets: data?.docs,
    isLoading,
    error,
    setPageAndLimit,
    pagination: data && {
      page: data.page,
      limit: data.limit,
      hasNextPage: data.hasNextPage,
      hasPrevPage: data.hasPrevPage,
      nextPage: data.nextPage,
      pagingCounter: data.pagingCounter,
      prevPage: data.prevPage,
      totalDocs: data.totalDocs,
      totalPages: data.totalPages
    },
    filter,
    setFilter
  };
};
