import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const GamificationService = sdk.getService('GamificationService');

export const useMyWeeklyPointCount = () => {
  const queryKey = ['gamification', 'my-rewards', 'week'];

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => GamificationService.getWeeklyPointCount()
  });

  return {
    weeklyPointCount: data,
    isLoading
  };
};
