import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

export const ResourceWrapper = styled.div`
  overflow: hidden;
  transform: translateZ(0);
  width: 100%;
  video {
    position: relative;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.marrow};
  border-radius: 8px;

  & :first-child {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
`;
