export const CommonTutoringGroupTranslations = {
  ADD_MEMBERS: {
    es: 'Añadir miembros',
    en: 'Add members',
    cat: 'Afegir membres',
    eus: 'Gehitu kideak'
  },
  DELETE_MEMBER: {
    es: 'Eliminar miembro',
    en: 'Delete member',
    cat: 'Eliminar membre',
    eus: 'Kide bat ezabatu'
  },
  TAGS: {
    es: 'Formación',
    en: 'Formation',
    cat: 'Formació',
    eus: 'Formakuntza'
  },
  SEARCH: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena'
  },
  EMAIL: {
    es: 'Email',
    en: 'Email',
    cat: 'Email',
    eus: 'Email'
  },
  LAST_NAME: {
    es: 'Apellidos',
    en: 'Last name',
    cat: 'Cognoms',
    eus: 'Abizenak'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Ezeztatu'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desar',
    eus: 'Gorde'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak'
  },
  TUTOR: {
    es: 'Tutor:',
    en: 'Tutor:',
    cat: 'Tutor:',
    eus: 'Tutorea:'
  },
  IN_GROUP: {
    es: 'En grupo:',
    en: 'In group:',
    cat: 'En grup:',
    eus: 'Taldean:'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragazkiak'
  },
  MESSAGES: {
    es: 'Mensajes',
    en: 'Messages',
    cat: 'Missatges',
    eus: 'Mezuak'
  },
  UNREAD_MANAGER: {
    es: 'Sin leer por el manager',
    en: 'Unread by manager',
    cat: 'Sense llegir pel manager',
    eus: 'Ez dago zuzenketa gabe'
  },
  UNREAD_TEACHER: {
    es: 'Sin leer por el tutor',
    en: 'Unread by teacher',
    cat: 'Sense llegir pel tutor',
    eus: 'Ez dago zuzenketa gabe'
  },
  WITHOUT_CORRECTION: {
    es: 'Sin corrección',
    en: 'Without correction',
    cat: 'Sense correcció',
    eus: 'Zuzenketa gabe'
  },
  REGISTRATION_DATE: {
    es: 'Fecha de inscripción',
    en: 'Registration date',
    cat: "Data d'inscripció",
    eus: 'Izena eman den data'
  },
  LAST_CONNECTION_DATE: {
    es: 'Última conexión',
    en: 'Last connection',
    cat: 'Última connexió',
    eus: 'Azken konexioa'
  }
};
