import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 200px;
  height: 100%;
  max-height: 95vh;
  overflow: auto;
  padding: 36px 8px 0px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-left: 1px solid ${({ theme }) => theme.colors.cadet};
`;

export const TitleWrapper = styled.div`
  font-weight: 600;
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  .ant-switch-checked {
    background: ${({ theme }) => theme.colors.meadreg};
    :hover:not(.ant-switch-disabled) {
      background: ${({ theme }) => theme.colors.meadlight};
    }
  }

  .ant-switch:hover:not(.ant-switch-disabled) {
    background: ${({ theme }) => theme.colors.meadlight};
  }
`;
