import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  cursor: pointer;
  align-items: center;

  i + span {
    margin-left: 8px;
  }
`;
