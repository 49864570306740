import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding-top: 24px;
  width: 100%;
  height: 100%;

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none;
  }
  .ant-tabs-tab-active {
    border-bottom: 2px solid ${({ theme }) => theme.colors.meadreg};
  }

  .ant-tabs {
    max-width: 100%;
    height: 100%;
    width: 100%;
  }
  .ant-tabs-content {
    height: 100%;
    width: 100%;
  }
  .ant-tabs-tabpane {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    background-color: ${({ theme }) => theme.colors.white};
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    .ant-tabs {
      flex-direction: row;
      gap: 48px;
    }
    .ant-tabs-nav-list {
      flex-direction: column;
    }
    .ant-tabs-tab {
      margin: 0 !important;
    }
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    .ant-tabs {
      max-width: calc(100% - 240px);
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const ReturnButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.marrow};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.gunmetal};
  }
`;
