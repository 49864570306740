import { Button } from 'antd';

import { usePostForm } from '../../../../Post/store';

import { Icon } from '@/components/Icon';

type Props = {
  editable?: boolean;
};

export const CreatePost: React.FC<Props> = ({ editable }) => {
  const { setIsOpen } = usePostForm();
  if (!editable) return null;
  return (
    <Button type="link" size="large" onClick={() => setIsOpen(true)} icon={<Icon i="AddAlt" />} />
  );
};
