import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  .ant-empty {
    margin: 56px 8px;
  }

  & div.ant-collapse-header[role='tab'] {
    align-items: center;
  }
`;
