import { Form, Radio } from 'antd';
import styled from 'styled-components';

export const AnswerWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;

  .ant-form-item {
    margin-bottom: 8px;
  }
`;

export const StyledRadio = styled(Radio)`
  margin-top: 5px;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.meadreg};
    border-color: ${({ theme }) => theme.colors.meadreg};
  }
`;

export const StyledFormItem = styled(Form.Item)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Wrapper = styled.div`
  margin-left: 36px;
  margin-top: -20px;
`;

export const FormulaWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;
