import { IQuestion } from '@sdk/contracts';
import { create as createStore } from 'zustand';

interface IStore {
  question?: IQuestion;
  setQuestion: (question?: IQuestion) => void;
}

export const useManualQuestionForm = createStore<IStore>(set => ({
  setQuestion: (question?: IQuestion) => set({ question })
}));
