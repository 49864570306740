import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
`;

export const CalendarWrapper = styled.div`
  padding-top: 52px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    padding-top: 24px;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    padding-top: 0px;
  }

  .fc-daygrid-day-top {
    flex-direction: row;
    & > a {
      color: ${({ theme }) => theme.colors.gunmetal};
    }
  }

  .fc-daygrid-day-bg {
    display: none;
  }

  .fc-daygrid-day-frame {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
  .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .fc-daygrid-day.fc-day-today {
    background-color: ${({ theme }) => theme.colors.neoghost};
  }
  .fc-timegrid-col.fc-day-today {
    background-color: ${({ theme }) => theme.colors.neoghost};
  }

  .fc-theme-standard th {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.beau};
  }

  .fc-scrollgrid {
    border: none;
  }
  .fc-scrollgrid table {
    border-left-style: inherit;
  }
`;

export const MoreEventsWrapper = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gunmetal};
`;
