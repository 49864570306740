import styled from 'styled-components';

export const FormWrapper = styled.div`
  padding: 0 30px;
`;

export const ButtonWrapper = styled.div`
  text-align: right;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px;
`;

export const ContractSignedWrapper = styled.div`
  display: flex;
  height: 350px;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 4px solid ${({ theme }) => theme.colors.neolight};
  border-radius: 8p;
  padding: 56px 16px;
`;

export const TextWrapper = styled.div`
  text-align: center;
  text-justify: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
`;
