import styled from 'styled-components';

export const TitleWrapper = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.cadet};
  background: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    width: 800px;
  }

  .free-mode:hover {
    background: ${({ theme }) => theme.colors.neowhite};
    border: 1px solid ${({ theme }) => theme.colors.neostrong};
    box-shadow: 0px 0px 6px 0px ${({ theme }) => theme.colors.neolight};
  }
  .review-mode:hover {
    background: ${({ theme }) => theme.colors.meadwhite};
    border: 1px solid ${({ theme }) => theme.colors.meadstrong};
    box-shadow: 0px 0px 6px 0px ${({ theme }) => theme.colors.meadreg};
  }
  .practice-mode:hover {
    background: ${({ theme }) => theme.colors.error}10;
    border: 1px solid ${({ theme }) => theme.colors.error};
    box-shadow: 0px 0px 6px 0px ${({ theme }) => theme.colors.error};
  }
`;
