import { ETicketStatus } from '@constants';
import { AnalyticsTrackEvents } from '@constants';
import { dateProvider } from '@utils';
import { Typography } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Wrapper } from './TicketItem.styles';

import { Icon } from '@/components/Icon';
import { TicketStatusBadge } from '@/components/TicketStatusBadge';
import { useMixpanel } from '@/hooks';

const { Text } = Typography;

type Props = {
  id: string;
  title?: string;
  status: ETicketStatus;
  createdAt: Date;
};

const DESCRIPTION_MAX_WIDTH = 200;

export const TicketItem: FC<Props> = ({ id, title, status, createdAt }) => {
  const { fireEvent } = useMixpanel();
  const navigateTo = useNavigate();

  const handleClick = () => {
    navigateTo(`/communication/tickets?ticketId=${id}`);
    fireEvent(AnalyticsTrackEvents.QUICK_ACCESS_DASHBOARD, {
      elementType: 'DOUBTS',
      elementName: title
    });
  };

  return (
    <Wrapper onClick={handleClick}>
      <TicketStatusBadge status={status} />
      <div>
        <Text style={{ width: DESCRIPTION_MAX_WIDTH }} ellipsis={{ tooltip: title }}>
          {title}
        </Text>
        <div>
          <Icon i="Calendar" />
          <span>{dateProvider.format(createdAt, 'dd/MM/yyyy')}</span>
        </div>
      </div>
    </Wrapper>
  );
};
