import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.mediaQueries.md}) {
    margin-bottom: 180px;
  }
`;

export const BoxWrapper = styled.div`
  gap: 16px;
  padding: 24px;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.boxShadow.primary};
  background-color: ${({ theme }) => theme.colors.white};

  & > :first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;

    & > div {
      display: flex;
      align-items: center;
      gap: 4px;

      & > :first-child {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
      & > :last-child {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  & > :nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;

    & > a {
      border: 1px solid ${({ theme }) => theme.colors.gunmetal};
      border-radius: 5px;
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;
    }
  }

  & > :last-child {
    flex-grow: 0;
  }

  & > section {
    display: flex;
    align-items: center;
    gap: 4px;

    & > :first-child {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    & > :last-child {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
`;

export const QWrapper = styled.div<{
  $isDone: boolean;
  $isReviewMode: boolean;
  $isCorrect: boolean;
  $isWrong: boolean;
  $isBlank: boolean;
}>`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.gunmetal};
  cursor: pointer;

  color: ${({ theme, $isDone, $isCorrect, $isReviewMode, $isWrong }) =>
    $isDone || ($isReviewMode && ($isCorrect || $isWrong))
      ? theme.colors.white
      : theme.colors.gunmetal};
  background-color: ${({ theme, $isDone, $isReviewMode, $isCorrect, $isWrong, $isBlank }) => {
    if ($isReviewMode) {
      if ($isBlank) return theme.colors.solitude;
      if ($isCorrect) return theme.colors.meadreg;
      if ($isWrong) return theme.colors.error;
      return theme.colors.white;
    }

    if ($isDone) return theme.colors.gunmetal;

    return 'transparent';
  }};
`;
