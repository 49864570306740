import { EFeatures } from '@constants';

import { EIconType } from '@/components/Icon/constants';

export const FEATURES_ICONS: Record<EFeatures, EIconType> = {
  [EFeatures.CONTENT]: 'Notebook',
  [EFeatures.QUIZZES]: 'TestTool',
  [EFeatures.LIVE_CLASSES]: 'Calendar',
  [EFeatures.TUTORING]: 'Collaborate',
  [EFeatures.ANNOUNCEMENTS]: 'LicenseGlobal',
  [EFeatures.EXAMS_SCORES]: 'DocumentRequirements'
};
