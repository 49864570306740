import {
  EDateFilterOptions,
  EDeliverableStatus,
  ETicketStatus,
  ENotificationType,
  EActivityReportOptions,
  EQuestionReportOptions,
  EExamGroupsType,
  ESectionTrackStatus,
  EUserStatus,
  ERoles,
  ECountry,
  EYearMonths,
  EQuizMode,
  EActivityAuditStatus,
  EStudyField,
  EVerticalNames,
  ETimePreference
} from '@constants';
import { EProvince, EAutonomousCommunity, EStudentPaymentStatus } from '@constants';

export const DefaultTranslations = {
  NAME: {
    en: 'Name',
    es: 'Nombre',
    cat: 'Nom',
    eus: 'Izena'
  },
  LAST_NAME: {
    en: 'Last name',
    es: 'Apellido',
    cat: 'Cognom',
    eus: 'Abizena'
  },
  EMAIL: {
    es: 'Email',
    en: 'Email',
    cat: 'Email',
    eus: 'Email'
  },
  PASSWORD: {
    es: 'Contraseña',
    en: 'Password',
    cat: 'Contrasenya',
    eus: 'Pasahitza'
  },
  LOADING: {
    es: 'Cargando...',
    en: 'Loading...',
    cat: 'Carregant...',
    eus: 'Kargatzen...'
  },
  SEARCH: {
    en: 'Search',
    es: 'Buscar...',
    cat: 'Cercar...',
    eus: 'Bilatu...'
  },
  REQUIRED_FIELD: {
    es: 'Campo requerido',
    en: 'Required field',
    cat: 'Camp requerit',
    eus: 'Beharrezko eremua'
  },
  REQUIRED_QUESTION: {
    es: 'Por favor, responde a esta pregunta',
    en: 'Please answer this question  ',
    cat: 'Per favor, respon a aquesta pregunta',
    eus: 'Mesedez, erantzun galdera hau'
  },
  WRONG_FORMAT_URL: {
    es: 'Formato de URL incorrecto',
    en: 'Wrong URL format',
    cat: 'Format de URL incorrecte',
    eus: 'URL formatu okerra'
  },
  YES: {
    es: 'Sí',
    en: 'Yes',
    cat: 'Sí',
    eus: 'Bai'
  },
  NO: {
    es: 'No',
    en: 'No',
    cat: 'No',
    eus: 'Ez'
  },
  POSITION: {
    es: 'Posición',
    en: 'Position',
    cat: 'Posició',
    eus: 'Posizioa'
  },
  SELECT_DATE: {
    es: 'Seleccionar fecha',
    en: 'Select date',
    cat: 'Seleccionar data',
    eus: 'Hautatu data'
  },
  SELECT_USER: {
    es: 'Selecciona un usuario',
    en: 'Select a user',
    cat: 'Selecciona un usuari',
    eus: 'Erabiltzaile bat hautatu'
  },
  ACCOUNT_STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera'
  },
  ACTIVE_ACCOUNT: {
    es: 'Cuenta activa',
    en: 'Active account',
    cat: 'Compte actiu',
    eus: 'Kontu aktibo'
  },
  INACTIVE_ACCOUNT: {
    es: 'Cuenta inactiva',
    en: 'Inactive account',
    cat: 'Compte inactiu',
    eus: 'Kontu erabiltzen ez dena'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desar',
    eus: 'Gorde'
  },
  SAVE_CHANGES: {
    es: 'Guardar cambios',
    en: 'Save changes',
    cat: 'Desa els canvis',
    eus: 'Aldaketak Gorde'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Utzi'
  },
  CLOSE: {
    es: 'Cerrar',
    en: 'Close',
    cat: 'Tanca',
    eus: 'Itxi'
  },
  PROFILE: {
    es: 'Perfil',
    en: 'Profile',
    cat: 'Perfil',
    eus: 'Profila'
  },
  ACCOUNT: {
    es: 'Cuenta',
    en: 'Account',
    cat: 'Compte',
    eus: 'Kontua'
  },
  LOGOUT: {
    es: 'Cerrar sesión',
    en: 'Logout',
    cat: 'Tanca la sessió',
    eus: 'Saioa itxi'
  },
  CHANGE_PASSWORD: {
    es: 'Cambiar contraseña',
    en: 'Change password',
    cat: 'Canviar contrasenya',
    eus: 'Aldatu pasahitza'
  },
  PROFILE_UPDATED: {
    es: 'Perfil actualizado 😀',
    en: 'Profile updated 😀',
    cat: 'Perfil actualitzat 😀',
    eus: 'Profila eguneratuta 😀'
  },
  CURRENT_PASSWORD: {
    es: 'Contraseña actual',
    en: 'Current password',
    cat: 'Contrasenya actual',
    eus: 'Gaurko pasahitza'
  },
  NEW_PASSWORD: {
    es: 'Nueva contraseña',
    en: 'New password',
    cat: 'Nova contrasenya',
    eus: 'Pasahitz berria'
  },
  CONFIRM_PASSWORD: {
    es: 'Confirmar contraseña',
    en: 'Confirm password',
    cat: 'Confirmar contrasenya',
    eus: 'Pasahitza baieztatu'
  },
  PASSWORDS_NOT_MATCH: {
    es: 'Las contraseñas no coinciden',
    en: 'Passwords do not match',
    cat: 'Les contrasenyes no coincideixen',
    eus: 'Pasahitzak ez datoz bat'
  },
  PASSWORD_UPDATED: {
    es: 'Contraseña actualizada',
    en: 'Password updated',
    cat: 'Contrasenya actualitzada',
    eus: 'Pasahitza eguneratuta'
  },
  UPDATE_PASSWORD: {
    es: 'Actualizar contraseña',
    en: 'Update password',
    cat: 'Actualitzar contrasenya',
    eus: 'Pasahitza eguneratu'
  },
  LOG_OUT: {
    es: 'Cerrar sesión',
    en: 'Log out',
    cat: 'Tancar sessió',
    eus: 'Saioa itxi'
  },
  LOG_OUT_CONFIRM: {
    es: '¿Estás seguro que deseas cerrar sesión?',
    en: 'Are you sure you want to log out?',
    cat: 'N`estàs segur que vols tancar sessió?',
    eus: 'Ziur zaude saioa itxi nahi duzula?'
  },
  USERNAME: {
    es: 'Nombre de usuario',
    en: 'Username',
    cat: 'Nom d`usuari',
    eus: 'Erabiltzaile izena'
  },
  AUDIO_CAN_NOT_BE_LOADED: {
    es: 'No se ha podido cargar el archivo de audio',
    en: 'Audio file could not be loaded',
    cat: 'No s`ha pogut carregar l`arxiu d`àudio',
    eus: 'Audio fitxategia ezin izan da kargatu'
  },
  SELECT_COURSE: {
    es: 'Seleccionar curso...',
    en: 'Select course...',
    cat: 'Selecciona curs...',
    eus: 'Hautatu ikastaroa...'
  },
  MY_PROFILE: {
    es: 'Mi perfil',
    en: 'My profile',
    cat: 'El meu perfil',
    eus: 'Nire profila'
  },
  EDIT_PROFILE: {
    es: 'Editar perfil',
    en: 'Edit profile',
    cat: 'Editar perfil',
    eus: 'Profila editatu'
  },
  DAY_STREAK: {
    es: 'día seguido',
    en: 'day in a row',
    cat: 'dia seguit',
    eus: 'egun segidan'
  },
  DAYS_STREAK: {
    es: 'días seguidos',
    en: 'days in a row',
    cat: 'dies seguits',
    eus: 'egun segidan'
  },
  DASHBOARD: {
    es: 'Dashboard',
    en: 'Dashboard',
    cat: 'Tauler',
    eus: 'Kontroletxe'
  },
  MY_FORMATION: {
    es: 'Mi formación',
    en: 'My formation',
    cat: 'La meva formació',
    eus: 'Nire formakuntza'
  },
  EXPLORE: {
    es: 'Explorar',
    en: 'Explore',
    cat: 'Explorar',
    eus: 'Bilatu'
  },
  TAGS: {
    es: 'Formaciones',
    en: 'Tags',
    cat: 'Formacions',
    eus: 'Formakuntzak'
  },
  OTHER: {
    es: 'Otro',
    en: 'Other',
    cat: 'Altres',
    eus: 'Besteak'
  },
  [ETicketStatus.NEW]: {
    es: 'Nueva',
    en: 'Open',
    cat: 'Nova',
    eus: 'Irekita'
  },
  [ETicketStatus.IN_PROGRESS]: {
    es: 'En curso',
    en: 'In progress',
    cat: 'En curs',
    eus: 'Hasiera bidean'
  },
  [ETicketStatus.CLOSED_NOT_RESOLVED]: {
    es: 'No resuelta',
    en: 'Not resolved',
    cat: 'No resolta',
    eus: 'Ez da konpondu'
  },
  [ETicketStatus.CLOSED_RESOLVED]: {
    es: 'Resuelta',
    en: 'Resolved',
    cat: 'Resolta',
    eus: 'Ebaztuta'
  },
  YOU_HAVE_NOT_CREATED_DOUBT_TICKETS: {
    es: 'No hay dudas abiertas',
    en: 'There are no open doubts',
    cat: 'No hi ha dubtes oberts',
    eus: 'Ez dago zalantzarik ireki'
  },
  SOMETHING_WENT_WRONG: {
    es: 'Algo salió mal',
    en: 'Something went wrong',
    cat: 'Alguna cosa ha anat malament',
    eus: 'Zerbait gaizki joan da'
  },
  UNKNOW_ERROR: {
    es: 'Error desconocido',
    en: 'Unknown error',
    cat: 'Error desconegut',
    eus: 'Errore ezezaguna'
  },
  RELOAD_COMPONENT: {
    es: 'Recargar componente',
    en: 'Reload component',
    cat: 'Recarregar component',
    eus: 'Komponentea berriz kargatu'
  },
  DATE: {
    es: 'Fecha',
    en: 'Date',
    cat: 'Data',
    eus: 'Data'
  },
  TIME: {
    es: 'Hora',
    en: 'Time',
    cat: 'Hora',
    eus: 'Ordua'
  },
  SPANISH: {
    es: 'Español',
    en: 'Spanish',
    cat: 'Espanyol',
    eus: 'Espainiera'
  },
  ENGLISH: {
    es: 'Inglés',
    en: 'English',
    cat: 'Anglès',
    eus: 'Ingelesa'
  },
  CATALAN: {
    es: 'Catalán',
    en: 'Catalan',
    cat: 'Català',
    eus: 'Katalana'
  },
  EUSKERA: {
    es: 'Euskera',
    en: 'Basque',
    cat: 'Euskera',
    eus: 'Euskara'
  },
  ASK_FOR_HELP: {
    es: 'Pedir ayuda',
    en: 'Ask for help',
    cat: 'Demanar ajuda',
    eus: 'Laguntza eskatu'
  },
  HELP_CENTER: {
    es: 'Centro de ayuda',
    en: 'Help center',
    cat: 'Centre d`ajuda',
    eus: 'Laguntza zentroa'
  },
  SORRY_WE_ARE_WORKING_WE_WILL_BE_BACK_SOON: {
    es: 'Estamos trabajando en una actualización del campus virtual que te va a ENCANTAR. Necesitamos unas horas para subirlo ¡Acabamos cuánto antes!',
    en: 'We are working on an update of the virtual campus that you will LOVE. We need a few hours to upload it. We will finish as soon as possible!',
    cat: 'Estem treballant en una actualització del campus virtual que t`ENCANTARÀ. Necessitem unes hores per pujar-ho. Acabem aviat!',
    eus: 'Campus birtualaren eguneratze baten lan egiten ari gara, zure gustukoa izango dena. Ordu gutxi behar ditugu igo dezagun. Aurrera!'
  },
  MAINTENANCE_MODE: {
    es: 'Modo mantenimiento',
    en: 'Maintenance mode',
    cat: 'Mode de manteniment',
    eus: 'Mantentze modua'
  },
  SECTION: {
    es: 'Sección',
    en: 'Section',
    cat: 'Secció',
    eus: 'Atala'
  },
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Ikastaroa'
  },
  ACTIVITY: {
    es: 'Actividad',
    en: 'Activity',
    cat: 'Activitat',
    eus: 'Jarduerak'
  },
  SELECT_RELATED_CONTENT: {
    es: 'Seleccionar contenido relacionado',
    en: 'Select related content',
    cat: 'Selecciona contingut relacionat',
    eus: 'Hautatu eduki lotura'
  },
  [EDeliverableStatus.DELIVERED]: {
    es: 'Entregado',
    en: 'Delivered',
    cat: 'Lliurat',
    eus: 'Entregatua'
  },
  [EDeliverableStatus.IN_REVIEW]: {
    es: 'En revisión',
    en: 'In review',
    cat: 'En revisió',
    eus: 'Berrikuspenetan'
  },
  [EDeliverableStatus.COMPLETED]: {
    es: 'Completado',
    en: 'Completed',
    cat: 'Completat',
    eus: 'Osatu'
  },

  SELECT_COURSE_OR_TAG: {
    es: 'Selecciona un curso o una etiqueta',
    en: 'Select a course or a tag',
    cat: 'Selecciona un curs o una etiqueta',
    eus: 'Hautatu ikastaro bat edo etiketa bat'
  },
  ASSIGN_COURSE: {
    es: 'Asignar curso',
    en: 'Assign course',
    cat: 'Assignar curs',
    eus: 'Ikastaroa asignatu'
  },
  COURSES_ASSOCIATED_TO_TAG_SUCCESSFULLY: {
    es: 'Cursos asociados a la etiqueta "{{tag}}" asignados correctamente.',
    en: 'Courses associated to the tag "{{tag}}" assigned successfully.',
    cat: 'Cursos associats a l`etiqueta "{{tag}}" assignats correctament.',
    eus: 'Tag-arekin elkargatutako ikastaroak ongi asignatu dira "{{tag}}" erabiliz.'
  },
  SEARCH_PLACEHOLDER: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu'
  },
  NAME_AND_LASTNAME: {
    es: 'Nombre y apellido',
    en: 'Name and lastname',
    cat: 'Nom i cognom',
    eus: 'Izena eta abizena'
  },
  LASTNAME: {
    es: 'Apellido',
    en: 'Lastname',
    cat: 'Cognom',
    eus: 'Abizena'
  },
  INSCRIPTION_DATE: {
    es: 'Fecha de inscripción',
    en: 'Inscription date',
    cat: 'Data d`inscripció',
    eus: 'Izena eman den data'
  },
  THERE_ARE_NOT_COURSES_ASOCIATED_TO_THIS_USER: {
    es: 'No hay cursos asociados a este usuario',
    en: 'There are no courses asociated to this user',
    cat: 'No hi ha cursos associats a aquest usuari',
    eus: 'Ez dago erabiltzaile honi atxikitako ikastaroik'
  },
  NEW_STUDENT: {
    es: 'Nuevo estudiante',
    en: 'New student',
    cat: 'Nou estudiant',
    eus: 'Ikasle berria'
  },
  AUTO_GENERATED_USERNAME: {
    es: 'Nombre de usuario autogenerado, es posible que no sea único',
    en: 'Auto generated username, it is possible that it is not unique',
    cat: 'Nom d`usuari autogenerat, potser no és únic',
    eus: 'Erabiltzaile izen autogeneratua, posible da ez izatea bakarra'
  },
  CREATE_STUDENT: {
    es: 'Crear estudiante',
    en: 'Create student',
    cat: 'Crear estudiant',
    eus: 'Ikasle berria sortu'
  },
  NEW_STUDENT_ADDED: {
    es: 'Nuevo estudiante agregado a la academia',
    en: 'New student added to the academy',
    cat: 'Nou estudiant afegit a l`acadèmia',
    eus: 'Ikasle berria akademian gehitu da'
  },
  USER_ALREADY_EXISTS: {
    es: 'Ups! Este email ya está en uso.',
    en: 'Ups! This email is already in use.',
    cat: 'Ups! Aquest email ja està en ús.',
    eus: 'Ups! Posta hau jadanik erabiltzen ari da.'
  },
  STUDENTS_TOTAL_COUNT: {
    es: '{{total}} alumnos',
    en: '{{total}} students',
    cat: '{{total}} estudiants',
    eus: '{{total}} ikasleak'
  },
  DELIVERABLES_TOTAL_COUNT: {
    es: '{{total}} entregables',
    en: '{{total}} deliverables',
    cat: '{{total}} lliurables',
    eus: '{{total}} entregagarriak'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastaroak'
  },
  STUDY_PROGRESS: {
    es: 'Progreso en el estudio',
    en: 'Study progress',
    cat: 'Progrés en l`estudi',
    eus: 'Ikasketetan aurrera egitea'
  },
  EDIT_STUDENT: {
    es: 'Editar alumno',
    en: 'Edit student',
    cat: 'Editar alumne',
    eus: 'Ikaslearen editatu'
  },
  ARE_YOU_SURE_TO_DELETE_USER: {
    es: '¿Estás seguro que deseas eliminar al usuario?',
    en: 'Are you sure you want to delete the user?',
    cat: 'N`estàs segur que vols eliminar l`usuari?',
    eus: 'Ziur zaude erabiltzailea ezabatu nahi duzula?'
  },
  USER_DELETED_SUCCESSFULLY: {
    es: 'Usuario eliminado correctamente',
    en: 'User deleted successfully',
    cat: 'Usuari eliminat correctament',
    eus: 'Erabiltzailea ongi ezabatu da'
  },
  REMOVE_STUDENT: {
    es: 'Eliminar alumno',
    en: 'Remove student',
    cat: 'Eliminar alumne',
    eus: 'Ikaslearen ezabatu'
  },
  REMOVE: {
    es: 'Eliminar',
    en: 'Remove',
    cat: 'Eliminar',
    eus: 'Kendu'
  },
  PASSWORD_RESET_SUCCESS: {
    es: 'Contraseña reiniciada correctamente, el usuario recibirá un correo con la nueva contraseña',
    en: 'Password reset successfully, the user will receive an email with the new password',
    cat: 'Contrasenya reiniciada correctament, l`usuari rebrà un correu amb la nova contrasenya',
    eus: 'Pasahitza ongi berrezarri da, erabiltzaileak posta bat jasoko du pasahitz berriarekin'
  },
  STUDENT_UPDATED_SUCCESSFULLY: {
    es: 'Estudiante actualizado correctamente',
    en: 'Student updated successfully',
    cat: 'Estudiant actualitzat correctament',
    eus: 'Ikaslea ongi eguneratuta'
  },
  USER_PREVIEW_COULD_NOT_BE_LOADED: {
    es: 'No se pudo cargar la vista previa del usuario',
    en: 'User preview could not be loaded',
    cat: 'No s`ha pogut carregar la vista prèvia de l`usuari',
    eus: 'Ezin izan da erabiltzailearen aurrebista kargatu'
  },
  RESET_STUDENT_PASSWORD: {
    es: 'Reiniciar contraseña',
    en: 'Reset student password',
    cat: 'Reiniciar contrasenya',
    eus: 'Ikaslearen pasahitza berrezarri'
  },
  ARE_YOU_SURE_TO_RESET_PASSWORD: {
    es: '¿Estás seguro que deseas reiniciar la contraseña?',
    en: 'Are you sure to reset password?',
    cat: 'N`estàs segur que vols reiniciar la contrasenya?',
    eus: 'Ziur zaude pasahitza berrezarri nahi duzula?'
  },
  CONNECTION: {
    es: 'Conexión',
    en: 'Connection',
    cat: 'Connexió',
    eus: 'Konexioa'
  },
  ARE_YOU_SURE_YOU_REMOVE_SUBSCRIPTION: {
    es: '¿Estás seguro que deseas eliminar la suscripción?',
    en: 'Are you sure you remove subscription?',
    cat: 'N`estàs segur que vols eliminar la subscripció?',
    eus: 'Ziur zaude harpidetza kendu nahi duzula?'
  },
  ACCEPT: {
    es: 'Aceptar',
    en: 'Accept',
    cat: 'Accepta',
    eus: 'Onartu'
  },
  [EDateFilterOptions.TODAY]: {
    es: 'Hoy',
    en: 'Today',
    cat: 'Avui',
    eus: 'Gaur'
  },
  [EDateFilterOptions.SEVEN_DAYS]: {
    es: '7 días',
    en: '7 days',
    cat: '7 dies',
    eus: '7 egun'
  },
  [EDateFilterOptions.THIRTY_DAYS]: {
    es: '30 días',
    en: '30 days',
    cat: '30 dies',
    eus: '30 egun'
  },
  [EDateFilterOptions.ONE_YEAR]: {
    es: '1 año',
    en: '1 year',
    cat: '1 any',
    eus: '1 urte'
  },
  [EDateFilterOptions.PERSONALIZED]: {
    es: 'Personalizado',
    en: 'Personalized',
    cat: 'Personalitzat',
    eus: 'Pertsonalizatua'
  },
  RESOLUTION_TIME: {
    es: 'Tiempo de resolución:',
    en: 'Resolution time:',
    cat: 'Temps de resolució:',
    eus: 'Ebazpen denbora:'
  },
  FIRST_RESPONSE_TIME: {
    es: 'Tiempo de 1ª respuesta:',
    en: 'First response time:',
    cat: 'Temps de 1ª resposta:',
    eus: 'Lehenengo erantzun denbora:'
  },
  RESOLUTION_TIME_YEARS: {
    es: '{{years}} a',
    en: '{{years}} y',
    cat: '{{years}} anys',
    eus: '{{years}} urte'
  },
  RESOLUTION_TIME_MONTHS: {
    es: '{{months}} m',
    en: '{{months}} m',
    cat: '{{months}} mesos',
    eus: '{{months}} hilabete'
  },
  RESOLUTION_TIME_DAYS: {
    es: '{{days}} d',
    en: '{{days}} d',
    cat: '{{days}} dies',
    eus: '{{days}} egun'
  },
  RESOLUTION_TIME_HOURS: {
    es: '{{hours}} h',
    en: '{{hours}} h',
    cat: '{{hours}} hores',
    eus: '{{hours}} ordu'
  },
  RESOLUTION_TIME_MINUTES: {
    es: '{{minutes}} min',
    en: '{{minutes}} min',
    cat: '{{minutes}} minuts',
    eus: '{{minutes}} minutu'
  },
  TOTAL: {
    es: 'Total {{total}}',
    en: 'Total {{total}}',
    cat: 'Total {{total}}',
    eus: 'Guztira {{total}}'
  },
  STATE: {
    es: 'Estatal',
    en: 'State',
    cat: 'Estat',
    eus: 'Estatu'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali'
  },
  NOTIFICATIONS: {
    es: 'Notificaciones',
    en: 'Notifications',
    cat: 'Notificacions',
    eus: 'Jakinarazpenak'
  },
  RECEIVED: {
    es: 'Recibidas',
    en: 'Received',
    cat: 'Rebudes',
    eus: 'Jasoak'
  },
  UNREAD: {
    es: 'No leídas',
    en: 'Unread',
    cat: 'No llegides',
    eus: 'Irkuskatugabeak'
  },
  ARCHIVED: {
    es: 'Archivadas',
    en: 'Archived',
    cat: 'Arxivades',
    eus: 'Arkatuak'
  },
  NO_NOTIFICATIONS: {
    es: '¡Todo limpio! No tienes notificaciones. 😀',
    en: 'All clean! You have no notifications. 😀',
    cat: 'Tot net! No tens notificacions. 😀',
    eus: 'Guztia garbia! Ez duzu jakinarik. 😀'
  },
  NEWS: {
    es: '¡Nuestras novedades!',
    en: 'Our news!',
    cat: 'Les nostres novetats!',
    eus: 'Gure berriak!'
  },
  WHATS_NEXT: {
    es: '¿Qué es lo siguiente?',
    en: "What's next?",
    cat: 'Què és el següent?',
    eus: 'Zer dator gero?'
  },
  CSV_TEMPLATE: {
    es: '¡Haz click <1>aquí</1> para descargar tu plantilla!',
    en: 'Click <1>here</1> to download your template!',
    cat: 'Fes clic <1>aquí</1> per descarregar la plantilla!',
    eus: 'Egin klik <1>hemen</1> zure txantiloi-a jeisteko!'
  },
  CONFIRM: {
    es: 'Confirmar',
    en: 'Confirm',
    cat: 'Confirmar',
    eus: 'Baieztatu'
  },
  TICKET_TITLE: {
    es: 'Asunto',
    en: 'Subject',
    cat: 'Assumpte',
    eus: 'Gaia'
  },
  ACTIVITY_TITLE: {
    es: 'Actividad',
    en: 'Activity',
    cat: 'Activitat',
    eus: 'Jarduera'
  },
  USER: {
    es: 'Usuario',
    en: 'User',
    cat: 'Usuari',
    eus: 'Erabiltzaile'
  },
  CSAT: {
    es: 'Valoración',
    en: 'Rating',
    cat: 'Avaluació',
    eus: 'Balorazioa'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak'
  },
  SELECT_PAYMENT_METHOD: {
    es: 'Selecciona tu <1>modalidad de pago:</1>',
    en: 'Select your <1>payment method:</1>',
    cat: 'Selecciona la teva <1>modalitat de pagament:</1>',
    eus: 'Hautatu zure <1>ordainketa modua:</1>'
  },
  SEQURA_6_MONTHS: {
    es: 'Hasta 6 cuotas* de <1>{{ count }} €</1>',
    en: 'Up to 6 installments* of <1>{{ count }} €</1>',
    cat: 'Fins a 6 quotes* de <1>{{ count }} €</1>',
    eus: '<1>{{ count }} €</1>-ra arteko 6 kopuru*'
  },
  STRIPE_3_MONTHS: {
    es: 'Hasta 3 cuotas* de <1>{{ count }} {{ symbol }}</1>',
    en: 'Up to 3 installments* of <1>{{ count }} {{ symbol }}</1>',
    cat: 'Fins a 3 quotes* de <1>{{ count }} {{ symbol }}</1>',
    eus: '<1>{{ count }} {{ symbol }}</1>-ra arteko 3 kopuru*'
  },
  CONTACT_INFO: {
    es: 'Para cualquier consulta o aclaración, no dudes en contactarnos en el teléfono (+34)<1>919 01 95 15</1>.',
    en: 'For any questions or clarifications, please do not hesitate to contact us at (+34)<1>919 01 95 15</1>.',
    cat: 'Per a qualsevol consulta o aclariment, no dubtis en contactar-nos al telèfon (+34)<1>919 01 95 15</1>.',
    eus: 'Kontsultarik edo argibide gehiagorako, ez ezazu ez zaudenetik kontaktatu gurekin (+34)<1>919 01 95 15</1> telefonoan.'
  },
  CERTIFICATES: {
    es: 'Certificados',
    en: 'Certificates',
    cat: 'Certificats',
    eus: 'Zirriborroak'
  },
  YOU_HAVE_NOT_CERTIFICATES: {
    es: 'No tienes certificados.',
    en: 'You have no certificates.',
    cat: 'No tens certificats.',
    eus: 'Ez duzu ziurtagiriak.'
  },
  GENERAL_INFORMATION: {
    es: 'Información general',
    en: 'General information',
    cat: 'Informació general',
    eus: 'Informazio orokorra'
  },
  SPECIFIC_INFORMATION: {
    es: 'Datos específicos',
    en: 'Specific information',
    cat: 'Dades específiques',
    eus: 'Datu espezifikoak'
  },
  BIRTHDAY: {
    es: 'Fecha de nacimiento',
    en: 'Birthday',
    cat: 'Data de naixement',
    eus: 'Jaiotze data'
  },
  MY_INFORMATION: {
    es: 'Mi información',
    en: 'My information',
    cat: 'La meva informació',
    eus: 'Nire informazioa'
  },
  REVIEW_REQUESTS: {
    es: 'Revisar mis solicitudes',
    en: 'Review my requests',
    cat: 'Revisar les meves sol·licituds',
    eus: 'Nire eskaerak berrikusi'
  },
  PAY_NOW: {
    es: 'Pagar ya',
    en: 'Pay now',
    cat: 'Paga ara',
    eus: 'Ordaindu orain'
  },
  START_NOW: {
    es: 'Empezar ya',
    en: 'Start now',
    cat: 'Comença ara',
    eus: 'Hasi orain'
  },
  SCORE: {
    es: 'Calificación',
    en: 'Score',
    cat: 'Puntuació',
    eus: 'Kalifikazioa'
  },
  CREATED_AT: {
    es: 'Fecha de creación',
    en: 'Creation date',
    cat: 'Data de creació',
    eus: 'Sortze data'
  },
  STUDENT: {
    es: 'Alumno',
    en: 'Student',
    cat: 'Alumne',
    eus: 'Ikasle'
  },
  ACCEPT_TERMS_AND_CONDITIONS: {
    es: 'He leído y acepto los <a>Términos y Condiciones</a> y <b>la Política de Privacidad</b>',
    en: 'I have read and accept the <a>Terms and Conditions</a> and <b>the Privacy Policy</b>',
    cat: 'He llegit i accepto els <a>Termes i Condicions</a> i <b>la Política de Privacitat</b>',
    eus: 'Irakurri eta onartu dut <a>Erabilera baldintzak</a> eta <b>Pribatutasun politika</b>'
  },
  TERMS_AND_CONDITIONS_INFO: {
    es: 'En UCADEMY, S.L. (en adelante, “UCADEMY”) tratamos tus datos para poder prestarte los servicios de UCADEMY y enviarte información sobre nuestros productos y/o servicios. Estos datos son necesarios para llevar a cabo la prestación de los servicios que se hayan solicitado a través del Sitio Web, así como el consentimiento que nos hayas otorgado conforme a nuestra Política de Privacidad. Tus datos no podrán cederse a terceras empresas. El interesado tiene derecho a ejercitar su derecho de: acceso, rectificación, supresión, oposición, portabilidad de los Datos, limitación del Tratamiento, no ser objeto de decisiones automatizadas individualizadas. Tanto si tienes alguna duda o sugerencia como si quieres darte de baja ponte en contacto con nosotros enviando un email a la siguiente dirección: info@ucademy.com',
    en: 'In UCADEMY, S.L. (hereinafter, "UCADEMY") we process your data in order to provide you with the services of UCADEMY and send you information about our products and / or services. This data is necessary to carry out the provision of the services that have been requested through the Website, as well as the consent you have given us in accordance with our Privacy Policy. Your data cannot be transferred to third companies. The interested party has the right to exercise their right of: access, rectification, deletion, opposition, portability of the Data, limitation of the Treatment, not to be subject to individualized automated decisions. If you have any questions or suggestions or if you want to unsubscribe, please contact us by sending an email to the following address:',
    cat: 'A UCADEMY, S.L. (d’ara endavant, "UCADEMY") tractem les teves dades per poder prestar-te els serveis d’UCADEMY i enviar-te informació sobre els nostres productes i / o serveis. Aquestes dades són necessàries per dur a terme la prestació dels serveis que s’hagin sol·licitat a través del Lloc Web, així com el consentiment que ens hagis atorgat conforme a la nostra Política de Privacitat. Les teves dades no podran cedir-se a tercers. L’interessat té dret a exercir el seu dret de: accés, rectificació, supressió, oposició, portabilitat de les Dades, limitació del Tractament, no ser objecte de decisions automatitzades individualitzades. Tant si tens algun dubte o suggeriment com si vols donar-te de baixa posa’t en contacte amb nosaltres enviant un email a la següent adreça: info@ucademy.com',
    eus: 'UCADEMY, S.L. (hemen ondoren, "UCADEMY")-n zure datuak prozesatzen ditugu UCADEMY-rena diren zerbitzuak hornitzeko eta gure produktuen eta/edo zerbitzuen informazioa bidaltzeko. Datu hauek zerbitzuak eskatzeko gune bidez eskatu diren zerbitzuak hornitzeko beharrezkoak dira, eta ere zu egindako baimenaren arabera gure Pribatutasun Politikaren arabera emandako baimena. Zure datuak ezin dira hirugarren enpresetara eraman. Interesdunak bere datuen gaineko berdintasun, zuzenketaren, ezabaketaren, aurkakoaren, Datuen Portabilitatearen, Tratamenduaren Murrizketaren, erabakia automatizatu pertsonalizatuak jasotzen ez izateko eskubidea du. Galdera edo iradokizunik baduzu edo erregistratzea nahi baduzu, jarri gurekin harremanetan helbide honetara bidali email bat: info@ucademy.com'
  },
  STUDENT_INFO_TITLE: {
    es: 'Vamos al lío.<br /> Confirma tu ficha de <a>estudiante:</a>',
    en: "Let's get down to business.<br /> Confirm your student <a>info:</a>",
    cat: 'Anem al gra.<br /> Confirma la teva ficha <a>d’estudiant:</a>',
    eus: 'Aldi honetan geratu gara.<br /> Egiaztatu zure <a>ikasle informazioa:</a>'
  },
  FUN_FACT: {
    es: 'Dato curioso: <a>¿Sabías que el primer examen formal documentado se atribuye a China en el siglo II a.C. y era para opositar al servicio civil?</a>',
    en: 'Fun fact: <a>Did you know that the first documented formal exam is attributed to China in the 2nd century BC and was for civil service exams?</a>',
    cat: 'Dada curiosa: <a>Sabies que el primer examen formal documentat es atribueix a la Xina en el segle II aC i era per oposar al servei civil?</a>',
    eus: 'Datu kurioso: <a>Zer diozu lehenengo egiaztapen dokumentatuari? Txinak II. mendea a.C. atribuitzen du eta zerbitzu zibila oposatzeko zen.</a>'
  },
  MARK_AS_READ: {
    es: 'Marcar todo como leído',
    en: 'Mark all as read',
    cat: 'Marca tot com a llegit',
    eus: 'Markatu dena irakurri gisa'
  },
  ALL_NOTIFICATIONS_FILTER: {
    es: 'Todas las notificaciones',
    en: 'All notifications',
    cat: 'Totes les notificacions',
    eus: 'Jakinarazpen guztiak'
  },
  [`${ENotificationType.NEW_EVENT}_FILTER`]: {
    es: 'Eventos',
    en: 'Events',
    cat: 'Esdeveniments',
    eus: 'Ekitaldiak'
  },
  [`${ENotificationType.NEW_RECURRING_EVENT}_FILTER`]: {
    es: 'Eventos recurrentes',
    en: 'Recurring events',
    cat: 'Esdeveniments recurrents',
    eus: 'Ekitaldi errepikatuak'
  },
  [`${ENotificationType.NEW_POST}_FILTER`]: {
    es: 'Posts',
    en: 'Posts',
    cat: 'Posts',
    eus: 'Posts'
  },
  [`${ENotificationType.NEW_TICKET}_FILTER`]: {
    es: 'Dudas',
    en: 'Tickets',
    cat: 'Dubtes',
    eus: 'Zalantzak'
  },
  [ENotificationType.NEW_TUTOR]: {
    es: 'Tutor asignado',
    en: 'Tutor assigned',
    cat: 'Tutor assignat',
    eus: 'Tutorea esleituta'
  },
  [ENotificationType.NEW_TEACHER_ACTIVITY]: {
    es: 'Nueva actividad subida',
    en: 'New activity uploaded',
    cat: 'Nova activitat pujada',
    eus: 'Jarduera berria igo da'
  },
  [ENotificationType.NEW_TEACHER_QUIZ]: {
    es: 'Nuevo test subido',
    en: 'New quiz uploaded',
    cat: 'Nou test pujat',
    eus: 'Test berria igo da'
  },
  [ENotificationType.NEW_TUTORING_GROUP_MEMBER]: {
    es: 'Nuevo alumno',
    en: 'New student',
    cat: 'Nou alumne',
    eus: 'Ikasle berria'
  },
  [ENotificationType.NEW_EVENT]: {
    es: 'Nuevo evento disponible',
    en: 'New event published',
    cat: 'Nou esdeveniment disponible',
    eus: 'Ekitaldi berria argitaratu da'
  },
  [ENotificationType.NEW_RECURRING_EVENT]: {
    es: 'Nuevo evento recurrente disponible',
    en: 'New recurring event published',
    cat: 'Nou esdeveniment recurrent disponible',
    eus: 'Ekitaldi berria errepikatua argitaratu da'
  },
  [ENotificationType.EVENT_UPDATE]: {
    es: 'Evento actualizado',
    en: 'Event updated',
    cat: 'Esdeveniment actualitzat',
    eus: 'Ekitaldia eguneratua'
  },
  [ENotificationType.NEW_POST]: {
    es: 'Nueva noticia publicada',
    en: 'New post published',
    cat: 'Nova notícia publicada',
    eus: 'Albiste berria argitaratu da'
  },
  [ENotificationType.NEW_TICKET]: {
    es: 'Nueva duda',
    en: 'New ticket',
    cat: 'Nou dubte',
    eus: 'Zalantza berria'
  },
  [ENotificationType.TICKET_UPDATE]: {
    es: 'Duda actualizada',
    en: 'Ticket updated',
    cat: 'Dubte actualitzat',
    eus: 'Zalantza eguneratua'
  },
  [ENotificationType.NEW_DELIVERABLE]: {
    es: 'Nuevo entregable',
    en: 'New deliverable',
    cat: 'Nou lliurament',
    eus: 'Entrega berria'
  },
  [ENotificationType.DELIVERABLE_UPDATE]: {
    es: 'Entregable actualizado',
    en: 'Deliverable updated',
    cat: 'Lliurament actualitzat',
    eus: 'Entrega eguneratua'
  },
  [ENotificationType.NEW_EXAM_GROUP]: {
    es: 'Nueva convocatoria publicada',
    en: 'New exam group published',
    cat: 'Nova convocatòria publicada',
    eus: 'Deialdi berria argitaratu da'
  },
  [ENotificationType.NEW_EXAM_GROUP_EXAM]: {
    es: 'Nuevo examen para tu convocatoria publicado',
    en: 'New exam for your exam group published',
    cat: 'Nou examen per a la teva convocatòria publicat',
    eus: 'Proba berria zure deialdiko argitaratu da'
  },
  [ENotificationType.NEW_LIVERECORD]: {
    es: 'Nueva grabación disponible',
    en: 'New recording available',
    cat: 'Nova gravació disponible',
    eus: 'Grabazio berria eskuragarri'
  },
  [ENotificationType.TASK_RESOURCE_UPLOADED]: {
    es: 'Tarea pasada a revisión',
    en: 'Task passed to review',
    cat: 'Tasca passada a revisió',
    eus: 'Lan bat berrikuspenetara pasatu da'
  },
  [ENotificationType.TASK_DEADLINE]: {
    es: 'Fecha de entrega',
    en: 'Due date',
    cat: 'Data de lliurament',
    eus: 'Entregatzeko data'
  },
  [ENotificationType.TASK_DEADLINE_3_DAYS]: {
    es: 'Fecha de entrega',
    en: 'Due date',
    cat: 'Data de lliurament',
    eus: 'Entregatzeko data'
  },
  [ENotificationType.TASK_REJECTED]: {
    es: 'Actividad rechazada',
    en: 'Activity rejected',
    cat: 'Activitat rebutjada',
    eus: 'Jarduera baztertua'
  },
  [ENotificationType.TASK_APPROVED]: {
    es: 'Actividad aprobada',
    en: 'Activity approved',
    cat: 'Activitat aprovada',
    eus: 'Jarduera onartua'
  },
  TEACHERS_CREATE_QUIZ_LINK: {
    es: 'Selecciona un test de la lista. Si no está creado, por favor, <1>ve a Test</1> y crea uno.',
    en: 'Select a test from the list. If it is not already created, please <1>go to Test</1> and create one.',
    cat: 'Selecciona un test de la llista. Si no està creat, si us plau, <1>vés a Test</1> i crea un.',
    eus: 'Aukeratu test bat zerrendatik. Ez badago sortuta, mesedez, <1>joan Testera</1> eta sortu bat.'
  },
  [EQuestionReportOptions.GRAMMAR]: {
    es: 'Errores ortográficos en la pregunta',
    en: 'Spelling errors in the question',
    cat: 'Errors ortogràfics a la pregunta',
    eus: 'Galderan ortografia akatsak'
  },
  [EQuestionReportOptions.QUESTION_INCOMPLETE]: {
    es: 'Pregunta o respuesta incompleta',
    en: 'Incomplete question or answer',
    cat: 'Pregunta o resposta incompleta',
    eus: 'Galdera edo erantzun oso inkompletua'
  },
  [EQuestionReportOptions.WRONG_ANSWER]: {
    es: 'La respuesta marcada como correcta es errónea',
    en: 'The answer marked as correct is wrong',
    cat: 'La resposta marcada com a correcta és errònia',
    eus: 'Zuzen moduan markatutako erantzuna okerra da'
  },
  [EQuestionReportOptions.WRONG_RELATED_COMTENT]: {
    es: 'La pregunta no corresponde al tema',
    en: 'The question does not correspond to the topic',
    cat: 'La pregunta no correspon al tema',
    eus: 'Galderak gaia ez du'
  },
  [EQuestionReportOptions.OTHER_QUESTION_REPORT]: {
    es: 'Otro',
    en: 'Other',
    cat: 'Altres',
    eus: 'Besteak'
  },
  [EActivityReportOptions.OTHER_ACTIVITY_REPORT]: {
    es: 'Otro',
    en: 'Other',
    cat: 'Altres',
    eus: 'Besteak'
  },
  [EActivityReportOptions.AUDIO_OR_VIDEO]: {
    es: 'Problemas técnicos con el audio o el vídeo',
    en: 'Technical problems with the audio or video',
    cat: "Problemes tècnics amb l'àudio o el vídeo",
    eus: 'Zalantza teknikoak audioa edo bideoa baliatzeko'
  },
  [EActivityReportOptions.LESSON_CONTENT]: {
    es: 'El contenido no corresponde con la lección',
    en: 'The information (texts, images, diagrams...) is not clear.',
    cat: 'El contingut no correspon amb la lliçó',
    eus: 'Edukiak (testuak, irudiak, diagramak...) argiak ez dira.'
  },
  [EActivityReportOptions.INCOMPLETE_CONTENT]: {
    es: 'El contenido está incompleto',
    en: 'The content is incomplete',
    cat: 'El contingut està incomplet',
    eus: 'Edukiak osatuak ez dira'
  },
  [EActivityReportOptions.TEACHER_EXPLANATION]: {
    es: 'La explicación del profesor no es clara',
    en: "The teacher's explanation is not clear",
    cat: "L'explicació del professor no és clara",
    eus: 'Irakaslearen azalpena ez da argia'
  },
  [EActivityReportOptions.REPEATED_VIDEO]: {
    es: 'Este video está repetido en otro tema',
    en: 'This video is repeated in another topic',
    cat: 'Aquest vídeo està repetit en un altre tema',
    eus: 'Bideo hau beste gaian berriz ere errepikatua dago'
  },
  [EActivityReportOptions.WRONG_INFORMATION]: {
    es: 'La información (textos, imágenes, esquemas...) no es clara',
    en: 'The information (texts, images, diagrams...) is not clear.',
    cat: 'La informació (texts, imatges, esquemes...) no és clara',
    eus: 'Informazioa (testuak, irudiak, diagramak...) ez da argia.'
  },
  [EActivityReportOptions.TECHNICAL_PROBLEM]: {
    es: 'Problema técnico / Algo no funciona',
    en: 'Technical problem / Something is not working',
    cat: 'Problema tècnic / Alguna cosa no funciona',
    eus: 'Zalantza teknikoa / Zerbait ez dago funtzionatzen'
  },
  VISIBILITY: {
    es: 'Visibilidad',
    en: 'Visibility',
    cat: 'Visibilitat',
    eus: 'Ikusgarritasuna'
  },
  VOTES: {
    es: 'Votos',
    en: 'Votes',
    cat: 'Vots',
    eus: 'Botoak'
  },
  PUBLIC: {
    es: 'Pública',
    en: 'Public',
    cat: 'Pública',
    eus: 'Publikoa'
  },
  PRIVATE: {
    es: 'Privada',
    en: 'Private',
    cat: 'Privada',
    eus: 'Pribatua'
  },
  CHANGE_VISIBILITY_CONFIRMATION: {
    es: '¿Estás seguro de que quieres hacer pública esta duda? <a>Todos los usuarios podrán verla de ahora en adelante.</a>',
    en: 'Are you sure you want to make this question public? <a>All users will be able to see it from now on.</a>',
    cat: "Estàs segur que vols fer pública aquesta pregunta? <a>Tots els usuaris la podran veure d'ara endavant.</a>",
    eus: 'Zihur zaude zalantza hau publikoa egin nahi duzula? <a>Erabiltzaile guztiak ikus dezakete geroztik.</a>'
  },
  [EExamGroupsType.OPPOSITION]: {
    es: 'Oposición',
    en: 'Opposition',
    cat: 'Oposició',
    eus: 'Oposizioa'
  },
  [EExamGroupsType.CONTEST]: {
    es: 'Concurso',
    en: 'Contest',
    cat: 'Concurs',
    eus: 'Lehiaketa'
  },
  [EExamGroupsType.CONTEST_OPPOSITION]: {
    es: 'Concurso-oposición',
    en: 'Contest-opposition',
    cat: 'Concurs-oposició',
    eus: 'Lehiaketa-oposizioa'
  },
  [EExamGroupsType.ORDINARY]: {
    es: 'Ordinaria',
    en: 'Ordinary',
    cat: 'Ordinària',
    eus: 'Ohiko'
  },
  [EExamGroupsType.EXTRAORDINARY]: {
    es: 'Extraordinaria',
    en: 'Extraordinary',
    cat: 'Extraordinària',
    eus: 'Berezi'
  },
  FILES_AND_LINKS: {
    es: 'Archivos y enlaces',
    en: 'Files and links',
    cat: 'Arxius i enllaços',
    eus: 'Fitxategiak eta estekak'
  },
  COMMUNITY: {
    es: 'Comunidad',
    en: 'Community',
    cat: 'Comunitat',
    eus: 'Komunitatea'
  },
  AUTONOMOUS_COMMUNITY: {
    es: 'Comunidad Autónoma',
    en: 'Autonomous Community',
    cat: 'Comunitat Autònoma',
    eus: 'Autonomia Erkidegoa'
  },
  PROVINCE: {
    es: 'Provincia',
    en: 'Province',
    cat: 'Província',
    eus: 'Probintzia'
  },
  [EAutonomousCommunity.ANDALUCIA]: {
    es: 'Andalucía',
    en: 'Andalucía',
    cat: 'Andalucía',
    eus: 'Andalucía'
  },
  [EAutonomousCommunity.PAIS_VASCO]: {
    es: 'País Vasco',
    en: 'País Vasco',
    cat: 'País Vasco',
    eus: 'País Vasco'
  },
  [EAutonomousCommunity.CANARIAS]: {
    es: 'Islas Canarias',
    en: 'Islas Canarias',
    cat: 'Islas Canarias',
    eus: 'Islas Canarias'
  },
  [EAutonomousCommunity.VALENCIA]: {
    es: 'Comunidad Valenciana',
    en: 'Comunidad Valenciana',
    cat: 'Comunidad Valenciana',
    eus: 'Comunidad Valenciana'
  },
  [EAutonomousCommunity.CATALUNYA]: {
    es: 'Cataluña',
    en: 'Cataluña',
    cat: 'Cataluña',
    eus: 'Cataluña'
  },
  [EAutonomousCommunity.MADRID]: {
    es: 'Madrid',
    en: 'Madrid',
    cat: 'Madrid',
    eus: 'Madrid'
  },
  [EAutonomousCommunity.ARAGON]: {
    es: 'Aragón',
    en: 'Aragón',
    cat: 'Aragón',
    eus: 'Aragón'
  },
  [EAutonomousCommunity.GALICIA]: {
    es: 'Galicia',
    en: 'Galicia',
    cat: 'Galicia',
    eus: 'Galicia'
  },
  [EAutonomousCommunity.EXTREMADURA]: {
    es: 'Extremadura',
    en: 'Extremadura',
    cat: 'Extremadura',
    eus: 'Extremadura'
  },
  [EAutonomousCommunity.CASTILLA_LEON]: {
    es: 'Castilla y León',
    en: 'Castilla y León',
    cat: 'Castilla y León',
    eus: 'Castilla y León'
  },
  [EAutonomousCommunity.CASTILLA_LA_MANCHA]: {
    es: 'Castilla-La Mancha',
    en: 'Castilla-La Mancha',
    cat: 'Castilla-La Mancha',
    eus: 'Castilla-La Mancha'
  },
  [EAutonomousCommunity.ASTURIAS]: {
    es: 'Asturias',
    en: 'Asturias',
    cat: 'Asturias',
    eus: 'Asturias'
  },
  [EAutonomousCommunity.BALEARES]: {
    es: 'Islas Baleares',
    en: 'Islas Baleares',
    cat: 'Islas Baleares',
    eus: 'Islas Baleares'
  },
  [EAutonomousCommunity.MELILLA]: {
    es: 'Melilla',
    en: 'Melilla',
    cat: 'Melilla',
    eus: 'Melilla'
  },
  [EAutonomousCommunity.CANTABRIA]: {
    es: 'Cantabria',
    en: 'Cantabria',
    cat: 'Cantabria',
    eus: 'Cantabria'
  },
  [EAutonomousCommunity.LA_RIOJA]: {
    es: 'La Rioja',
    en: 'La Rioja',
    cat: 'La Rioja',
    eus: 'La Rioja'
  },
  [EAutonomousCommunity.MURCIA]: {
    es: 'Murcia',
    en: 'Murcia',
    cat: 'Murcia',
    eus: 'Murcia'
  },
  [EAutonomousCommunity.CEUTA]: {
    es: 'Ceuta',
    en: 'Ceuta',
    cat: 'Ceuta',
    eus: 'Ceuta'
  },
  [EAutonomousCommunity.NAVARRA]: {
    es: 'Navarra',
    en: 'Navarra',
    cat: 'Navarra',
    eus: 'Navarra'
  },
  [EProvince.ALMERIA]: {
    es: 'Almería',
    en: 'Almería',
    cat: 'Almería',
    eus: 'Almería'
  },
  [EProvince.CADIZ]: {
    es: 'Cádiz',
    en: 'Cádiz',
    cat: 'Cádiz',
    eus: 'Cádiz'
  },
  [EProvince.CORDOBA]: {
    es: 'Córdoba',
    en: 'Córdoba',
    cat: 'Córdoba',
    eus: 'Córdoba'
  },
  [EProvince.GRANADA]: {
    es: 'Granada',
    en: 'Granada',
    cat: 'Granada',
    eus: 'Granada'
  },
  [EProvince.HUELVA]: {
    es: 'Huelva',
    en: 'Huelva',
    cat: 'Huelva',
    eus: 'Huelva'
  },
  [EProvince.JAEN]: {
    es: 'Jaén',
    en: 'Jaén',
    cat: 'Jaén',
    eus: 'Jaén'
  },
  [EProvince.MALAGA]: {
    es: 'Málaga',
    en: 'Málaga',
    cat: 'Málaga',
    eus: 'Málaga'
  },
  [EProvince.SEVILLA]: {
    es: 'Sevilla',
    en: 'Sevilla',
    cat: 'Sevilla',
    eus: 'Sevilla'
  },
  [EProvince.ALAVA]: {
    es: 'Álava',
    en: 'Álava',
    cat: 'Álava',
    eus: 'Álava'
  },
  [EProvince.GUIPUZCOA]: {
    es: 'Guipúzcoa',
    en: 'Gipuzkoa',
    cat: 'Gipuzkoa',
    eus: 'Gipuzkoa'
  },
  [EProvince.VIZCAYA]: {
    es: 'Vizcaya',
    en: 'Bizkaia',
    cat: 'Bizkaia',
    eus: 'Bizkaia'
  },
  [EProvince.LAS_PALMAS]: {
    es: 'Las Palmas',
    en: 'Las Palmas',
    cat: 'Las Palmas',
    eus: 'Las Palmas'
  },
  [EProvince.SANTA_CRUZ_DE_TENERIFE]: {
    es: 'Santa Cruz de Tenerife',
    en: 'Santa Cruz de Tenerife',
    cat: 'Santa Cruz de Tenerife',
    eus: 'Santa Cruz de Tenerife'
  },
  [EProvince.VALENCIA]: {
    es: 'Valencia',
    en: 'Valencia',
    cat: 'Valencia',
    eus: 'Valencia'
  },
  [EProvince.CASTELLON]: {
    es: 'Castellón',
    en: 'Castellón',
    cat: 'Castellón',
    eus: 'Castellón'
  },
  [EProvince.ALICANTE]: {
    es: 'Alicante',
    en: 'Alicante',
    cat: 'Alicante',
    eus: 'Alicante'
  },
  [EProvince.BARCELONA]: {
    es: 'Barcelona',
    en: 'Barcelona',
    cat: 'Barcelona',
    eus: 'Barcelona'
  },
  [EProvince.GERONA]: {
    es: 'Gerona',
    en: 'Girona',
    cat: 'Girona',
    eus: 'Girona'
  },
  [EProvince.LLEIDA]: {
    es: 'Lleida',
    en: 'Lleida',
    cat: 'Lleida',
    eus: 'Lleida'
  },
  [EProvince.TARRAGONA]: {
    es: 'Tarragona',
    en: 'Tarragona',
    cat: 'Tarragona',
    eus: 'Tarragona'
  },
  [EProvince.MADRID]: {
    es: 'Madrid',
    en: 'Madrid',
    cat: 'Madrid',
    eus: 'Madrid'
  },
  [EProvince.HUESCA]: {
    es: 'Huesca',
    en: 'Huesca',
    cat: 'Huesca',
    eus: 'Huesca'
  },
  [EProvince.TERUEL]: {
    es: 'Teruel',
    en: 'Teruel',
    cat: 'Teruel',
    eus: 'Teruel'
  },
  [EProvince.ZARAGOZA]: {
    es: 'Zaragoza',
    en: 'Zaragoza',
    cat: 'Zaragoza',
    eus: 'Zaragoza'
  },
  [EProvince.A_CORUNA]: {
    es: 'A Coruña',
    en: 'A Coruña',
    cat: 'A Coruña',
    eus: 'A Coruña'
  },
  [EProvince.LUGO]: {
    es: 'Lugo',
    en: 'Lugo',
    cat: 'Lugo',
    eus: 'Lugo'
  },
  [EProvince.OURENSE]: {
    es: 'Ourense',
    en: 'Ourense',
    cat: 'Ourense',
    eus: 'Ourense'
  },
  [EProvince.PONTEVEDRA]: {
    es: 'Pontevedra',
    en: 'Pontevedra',
    cat: 'Pontevedra',
    eus: 'Pontevedra'
  },
  [EProvince.BADAJOZ]: {
    es: 'Badajoz',
    en: 'Badajoz',
    cat: 'Badajoz',
    eus: 'Badajoz'
  },
  [EProvince.CACERES]: {
    es: 'Cáceres',
    en: 'Cáceres',
    cat: 'Cáceres',
    eus: 'Cáceres'
  },
  [EProvince.AVILA]: {
    es: 'Ávila',
    en: 'Ávila',
    cat: 'Ávila',
    eus: 'Ávila'
  },
  [EProvince.BURGOS]: {
    es: 'Burgos',
    en: 'Burgos',
    cat: 'Burgos',
    eus: 'Burgos'
  },
  [EProvince.LEON]: {
    es: 'León',
    en: 'León',
    cat: 'León',
    eus: 'León'
  },
  [EProvince.PALENCIA]: {
    es: 'Palencia',
    en: 'Palencia',
    cat: 'Palencia',
    eus: 'Palencia'
  },
  [EProvince.SALAMANCA]: {
    es: 'Salamanca',
    en: 'Salamanca',
    cat: 'Salamanca',
    eus: 'Salamanca'
  },
  [EProvince.SEGOVIA]: {
    es: 'Segovia',
    en: 'Segovia',
    cat: 'Segovia',
    eus: 'Segovia'
  },
  [EProvince.SORIA]: {
    es: 'Soria',
    en: 'Soria',
    cat: 'Soria',
    eus: 'Soria'
  },
  [EProvince.VALLADOLID]: {
    es: 'Valladolid',
    en: 'Valladolid',
    cat: 'Valladolid',
    eus: 'Valladolid'
  },
  [EProvince.ZAMORA]: {
    es: 'Zamora',
    en: 'Zamora',
    cat: 'Zamora',
    eus: 'Zamora'
  },
  [EProvince.ALBACETE]: {
    es: 'Albacete',
    en: 'Albacete',
    cat: 'Albacete',
    eus: 'Albacete'
  },
  [EProvince.CIUDAD_REAL]: {
    es: 'Ciudad Real',
    en: 'Ciudad Real',
    cat: 'Ciudad Real',
    eus: 'Ciudad Real'
  },
  [EProvince.CUENCA]: {
    es: 'Cuenca',
    en: 'Cuenca',
    cat: 'Cuenca',
    eus: 'Cuenca'
  },
  [EProvince.GUADALAJARA]: {
    es: 'Guadalajara',
    en: 'Guadalajara',
    cat: 'Guadalajara',
    eus: 'Guadalajara'
  },
  [EProvince.TOLEDO]: {
    es: 'Toledo',
    en: 'Toledo',
    cat: 'Toledo',
    eus: 'Toledo'
  },
  [EProvince.ASTURIAS]: {
    es: 'Asturias',
    en: 'Asturias',
    cat: 'Asturias',
    eus: 'Asturias'
  },
  [EProvince.BALEARES]: {
    es: 'Islas Baleares',
    en: 'Islas Baleares',
    cat: 'Islas Baleares',
    eus: 'Islas Baleares'
  },
  [EProvince.MELILLA]: {
    es: 'Melilla',
    en: 'Melilla',
    cat: 'Melilla',
    eus: 'Melilla'
  },
  [EProvince.CANTABRIA]: {
    es: 'Cantabria',
    en: 'Cantabria',
    cat: 'Cantabria',
    eus: 'Cantabria'
  },
  [EProvince.LA_RIOJA]: {
    es: 'La Rioja',
    en: 'La Rioja',
    cat: 'La Rioja',
    eus: 'La Rioja'
  },
  [EProvince.MURCIA]: {
    es: 'Murcia',
    en: 'Murcia',
    cat: 'Murcia',
    eus: 'Murcia'
  },
  [EProvince.CEUTA]: {
    es: 'Ceuta',
    en: 'Ceuta',
    cat: 'Ceuta',
    eus: 'Ceuta'
  },
  [EProvince.NAVARRA]: {
    es: 'Navarra',
    en: 'Navarra',
    cat: 'Navarra',
    eus: 'Navarra'
  },
  [ESectionTrackStatus.NOT_STARTED]: {
    es: 'Sin empezar',
    en: 'Not started',
    cat: 'Sense començar',
    eus: 'Hasi gabe'
  },
  [ESectionTrackStatus.SEEN]: {
    es: '👀 Visto',
    en: '👀 Seen',
    cat: '👀 Vist',
    eus: '👀 Ikusita'
  },
  [ESectionTrackStatus.SUMMARISED]: {
    es: '📝 Resumido',
    en: '📝 Summarised',
    cat: '📝 Resumit',
    eus: '📝 Laburtuta'
  },
  [ESectionTrackStatus.STUDIED]: {
    es: '🙇 Estudiado',
    en: '🙇 Studied',
    cat: '🙇 Estudiat',
    eus: '🙇 Ikasketak eginak'
  },
  [ESectionTrackStatus.REVISED]: {
    es: '🤓 Repasado',
    en: '🤓 Revised',
    cat: '🤓 Repassat',
    eus: '🤓 Berrikusita'
  },
  [ESectionTrackStatus.KNOWN]: {
    es: '✅ Me lo sé',
    en: '✅ I know it',
    cat: '✅ Me lo sé',
    eus: '✅ Dakit'
  },
  CONTRACT: {
    es: 'Contrato',
    en: 'Contract',
    cat: 'Contracte',
    eus: 'Kontratua'
  },
  DOWNLOAD_CONTRACT: {
    es: 'Descargar contrato',
    en: 'Download contract',
    cat: 'Descarregar contracte',
    eus: 'Kontratuaren deskargatzea'
  },
  DOES_HE_WORK: {
    es: '¿Trabaja?',
    en: 'Does he work?',
    cat: 'Treballa?',
    eus: 'Lan egiten du?'
  },
  AVAILABLE_TIME: {
    es: 'Tiempo disponible para estudiar',
    en: 'Available time to study',
    cat: 'Temps disponible per estudiar',
    eus: 'Ikastea daitekeen denbora'
  },
  HOURS_PER_WEEK: {
    es: '{{ investTime }} h/semana',
    en: '{{ investTime }} h/week',
    cat: '{{ investTime }} h/setmana',
    eus: '{{ investTime }} h/aste'
  },
  HISTORY: {
    es: 'Históricos',
    en: 'History',
    cat: 'Històrics',
    eus: 'Historikoak'
  },
  STATUS_HISTORY: {
    es: 'Histórico de estados',
    en: 'Status history',
    cat: "Històric d'estats",
    eus: 'Egoera historikoa'
  },
  PAYMENT_HISTORY: {
    es: 'Histórico de pagos',
    en: 'Payment history',
    cat: 'Històric de pagaments',
    eus: 'Ordainketa historikoa'
  },
  QUIZ_NUMBER: {
    es: 'Nº test realizados',
    en: 'Nº tests done',
    cat: 'Nº test realitzats',
    eus: 'Egindako test kopurua'
  },
  AVG_SCORE: {
    es: 'Nota media',
    en: 'Avg score',
    cat: 'Nota mitjana',
    eus: 'Batazbesteko puntuazioa'
  },
  TICKETS_NUMBER: {
    es: 'Nº dudas',
    en: 'Nº doubts',
    cat: 'Nº dubtes',
    eus: 'Zalantz kopurua'
  },
  CSAT_TITLE: {
    es: 'CSAT',
    en: 'CSAT',
    cat: 'CSAT',
    eus: 'CSAT'
  },
  APP_TIME: {
    es: 'Tiempo total',
    en: 'Total time',
    cat: 'Temps total',
    eus: 'Denbora guztira'
  },
  [EUserStatus.ACTIVE]: {
    es: 'Activo',
    en: 'Active',
    cat: 'Actiu',
    eus: 'Aktibo'
  },
  [EUserStatus.INACTIVE]: {
    es: 'Inactivo',
    en: 'Inactive',
    cat: 'Inactiu',
    eus: 'Inaktibo'
  },
  [EUserStatus.NOT_ACTIVATED]: {
    es: 'No activado',
    en: 'Not activated',
    cat: 'No activat',
    eus: 'Ez da aktibatu'
  },
  [EUserStatus.SLEEP]: {
    es: 'Dormido',
    en: 'Sleep',
    cat: 'Dormit',
    eus: 'Lo'
  },
  [EStudentPaymentStatus.PAID]: {
    es: 'Pago correcto',
    en: 'Paid',
    cat: 'Pagament correcte',
    eus: 'Ordainketa egokia'
  },
  [EStudentPaymentStatus.DOWNTURN]: {
    es: 'Baja',
    en: 'Downturn',
    cat: 'Baixa',
    eus: 'Baxua'
  },
  [EStudentPaymentStatus.NON_PAYMENT]: {
    es: 'Impago',
    en: 'Non payment',
    cat: 'Impagament',
    eus: 'Ordainketa ezezaguna'
  },
  [EStudentPaymentStatus.FORMER_STUDENT]: {
    es: 'Antiguo',
    en: 'Former student',
    cat: 'Antic',
    eus: 'Ohiko ikaslea'
  },
  [EStudentPaymentStatus.EXAMINED]: {
    es: 'Examinado',
    en: 'Examined',
    cat: 'Examinat',
    eus: 'Aztertua'
  },
  ACTIVITY_STATUS: {
    es: 'Actividad',
    en: 'Activity',
    cat: 'Activitat',
    eus: 'Ekintza'
  },
  PAYMENT_STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera'
  },
  APP_TIME_TOOLTIP: {
    es: 'Tiempo total que ha pasado el alumno en la plataforma',
    en: 'Total time the student has spent in the platform',
    cat: "Temps total que ha passat l'alumne a la plataforma",
    eus: 'Ikasleak plataforma guztian igaro duen denbora guztira'
  },
  SELECT_VISIBILITY: {
    es: 'Seleccionar visibilidad',
    en: 'Select visibility',
    cat: 'Seleccionar visibilitat',
    eus: 'Ikusgarritasuna aukeratu'
  },
  PUBLIC_VISIBILITY: {
    es: 'Pública',
    en: 'Public',
    cat: 'Pública',
    eus: 'Publikoa'
  },
  PRIVATE_VISIBILITY: {
    es: 'Privada',
    en: 'Private',
    cat: 'Privada',
    eus: 'Pribatua'
  },
  CLAIMED: {
    es: 'Reclamado',
    en: 'Claimed',
    cat: 'Reclamat',
    eus: 'Erreklamatuta'
  },
  NEW_USER_ADDED: {
    es: 'Nuevo usuario agregado a la academia',
    en: 'New user added to the academy',
    cat: "Nou usuari afegit a l'acadèmia",
    eus: 'Erabiltzaile berria akademiara gehitu da'
  },
  USER_UPDATED_SUCCESSFULLY: {
    es: 'Usuario actualizado correctamente',
    en: 'User updated successfully',
    cat: 'Usuari actualitzat correctament',
    eus: 'Erabiltzailea ongi eguneratu da'
  },
  [ERoles.MANAGER]: {
    es: 'Manager',
    en: 'Manager',
    cat: 'Manager',
    eus: 'Kudeatzailea'
  },
  [ERoles.TEACHER]: {
    es: 'Profesor',
    en: 'Teacher',
    cat: 'Professor',
    eus: 'Irakaslea'
  },
  [ERoles.SALES]: {
    es: 'Ventas',
    en: 'Sales',
    cat: 'Vendes',
    eus: 'Saltzak'
  },
  [ERoles.SUPPORT]: {
    es: 'Soporte',
    en: 'Support',
    cat: 'Suport',
    eus: 'Laguntza'
  },
  [ERoles.INSPECTOR]: {
    es: 'Inspector',
    en: 'Inspector',
    cat: 'Inspector',
    eus: 'Azterketa'
  },
  [ERoles.AI_TEACHER]: {
    es: 'Profesor IA',
    en: 'AI Teacher',
    cat: 'Professor IA',
    eus: 'IA irakaslea'
  },
  ARE_YOU_SURE_TO_IMPERSONATE: {
    es: '¿Estás seguro de que quieres impersonar a {{ name }}?',
    en: 'Are you sure you want to impersonate {{ name }}?',
    cat: 'Estàs segur que vols impersonar a {{ name }}?',
    eus: 'Zihur zaude {{ name }} impersonatu nahi duzula?'
  },
  TEST_WIDGET_TOTAL_QUESTIONS: {
    es: 'Preguntas realizadas',
    en: 'Questions asked',
    cat: 'Preguntes realitzades',
    eus: 'Galderei egin zaien galderak'
  },
  TEST_WIDGET_CORRECT_QUESTIONS: {
    es: 'Preguntas acertadas',
    en: 'Successful questions',
    cat: 'Preguntes encertades',
    eus: 'Ondo erantzun diren galderak'
  },
  TEST_WIDGET_WRONG_QUESTIONS: {
    es: 'Preguntas falladas',
    en: 'Failed questions',
    cat: 'Preguntes fallades',
    eus: 'Gaizki erantzun diren galderak'
  },
  TEST_WIDGET_BLANK_QUESTIONS: {
    es: 'Preguntas sin contestar',
    en: 'Unanswered questions',
    cat: 'Preguntes sense contestar',
    eus: 'Erantzunik gabeko galderak'
  },
  TEST_WIDGET_AVG_TIME: {
    es: 'Tiempo medio por pregunta',
    en: 'Average time per question',
    cat: 'Temps mitjà per pregunta',
    eus: 'Galdera bakoitzeko batazbesteko denbora'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragazkiak'
  },
  TEST_WIDGET_TITLE: {
    es: 'Test',
    en: 'Test',
    cat: 'Prova',
    eus: 'Proba'
  },
  TEST_WIDGET_SUBTITLE: {
    es: 'Estadísticas de los test realizados',
    en: 'Statistics of the tests performed',
    cat: 'Estadístiques de les proves realitzades',
    eus: 'Egin diren proben estatistikak'
  },
  TEST_WIDGET_AVG_SCORE: {
    es: 'Nota media',
    en: 'Average score',
    cat: 'Nota mitjana',
    eus: 'Batazbesteko puntuazioa'
  },
  ACTIVITY_NAME: {
    es: 'Nombre de actividad',
    en: 'Activity name',
    cat: 'Nom de l`activitat',
    eus: 'Jarduera izena'
  },
  UPLOAD_DATE: {
    es: 'Fecha de subida',
    en: 'Upload date',
    cat: 'Data de pujada',
    eus: 'Igoera data'
  },
  NUMBER_OF_PAGES: {
    es: 'Número de páginas',
    en: 'Number of pages',
    cat: 'Nombre de pàgines',
    eus: 'Orrialde kopurua'
  },
  NUMBER_OF_QUESTIONS: {
    es: 'Número de preguntas',
    en: 'Number of questions',
    cat: 'Nombre de preguntes',
    eus: 'Galdera kopurua'
  },
  LAYOUT: {
    es: 'Maquetado',
    en: 'Layout',
    cat: 'Maquetació',
    eus: 'Diseinua'
  },
  PUBLISHED: {
    es: 'Publicado',
    en: 'Published',
    cat: 'Publicat',
    eus: 'Argitaratua'
  },
  FINAL_REVISION: {
    es: 'Revisión final',
    en: 'Final revision',
    cat: 'Revisió final',
    eus: 'Azken errepaso'
  },
  TEST: {
    es: 'Test',
    en: 'Test',
    cat: 'Prova',
    eus: 'Proba'
  },
  VIDEOS: {
    es: 'Vídeos',
    en: 'Videos',
    cat: 'Vídeos',
    eus: 'Bideoak'
  },
  DOCUMENTS: {
    es: 'Apuntes',
    en: 'Documents',
    cat: 'Apunts',
    eus: 'Dokumentuak'
  },
  REVIEWED: {
    es: 'Revisada',
    en: 'Reviewed',
    cat: 'Revisada',
    eus: 'Errebisatua'
  },
  NOT_REVIEWED: {
    es: 'En revisión',
    en: 'In review',
    cat: 'En revisió',
    eus: 'Aztertzen'
  },
  QUESTIONS: {
    es: 'Nº de Preguntas',
    en: 'Nº of Questions',
    cat: 'Núm. de Preguntes',
    eus: 'Galdera zenbakia'
  },
  DURATION: {
    es: 'Duración',
    en: 'Duration',
    cat: 'Durada',
    eus: 'Iraupena'
  },
  SOUND: {
    es: 'Sonido',
    en: 'Sound',
    cat: 'So',
    eus: 'Soinua'
  },
  IMAGE: {
    es: 'Imagen',
    en: 'Image',
    cat: 'Imatge',
    eus: 'Irudia'
  },
  SETTINGS: {
    es: 'Ajustes',
    en: 'Settings',
    cat: 'Configuració',
    eus: 'Ezarpenak'
  },
  SORT: {
    es: 'Clase',
    en: 'Class',
    cat: 'Classe',
    eus: 'Klase'
  },
  HISTORIC: {
    es: 'Tabla de gestión',
    en: 'Management table',
    cat: 'Taula de gestió',
    eus: 'Kudeaketa taula'
  },
  DOWNLOAD_ALL: {
    es: 'Exportar todo',
    en: 'Export all',
    cat: 'Exportar tot',
    eus: 'Denak esportatu'
  },
  DOWNLOAD: {
    es: 'Descargar',
    en: 'Download',
    cat: 'Descarregar',
    eus: 'Deskargatu'
  },
  PROCESSING_DATA: {
    es: 'Procesando los datos...',
    en: 'Processing data...',
    cat: 'Processant les dades...',
    eus: 'Datuak prozesatzen...'
  },
  DOWNLOAD_SELECTED: {
    es: 'Descargar seleccionados',
    en: 'Download selected',
    cat: 'Descarregar seleccionats',
    eus: 'Hautatutakoak deskargatu'
  },
  TEACHER_EMAIL: {
    es: 'Profesor',
    en: 'Teacher',
    cat: 'Professor',
    eus: 'Irakaslea'
  },
  VERTICAL: {
    es: 'Vertical',
    en: 'Vertical',
    cat: 'Vertical',
    eus: 'Bertikala'
  },
  STATS: {
    es: 'Estadísticas',
    en: 'Stats',
    cat: 'Estadístiques',
    eus: 'Estatistikak'
  },
  STUDENT_TIME_WIDGET_TITLE: {
    es: 'Tiempo',
    en: 'Time',
    cat: 'Temps',
    eus: 'Denbora'
  },
  STUDENT_TIME_WIDGET_SUBTITLE: {
    es: 'De uso en la plataforma',
    en: 'Using the platform',
    cat: 'D`ús a la plataforma',
    eus: 'Plataforman erabiliz'
  },
  TOTAL_TICKETS: {
    es: 'total',
    en: 'total',
    cat: 'total',
    eus: 'guztiak'
  },
  SELECT_SECTION: {
    es: 'Seleccionar sección',
    en: 'Select section',
    cat: 'Seleccionar secció',
    eus: 'Aukeratu atala'
  },
  QUIZ_TIP: {
    es: '<a>Consejo:</a><b> usa las teclas ← y → para moverte más rápido entre las preguntas.</b>',
    en: '<a>Tip:</a><b> use the ← and → keys to move faster between questions.</b>',
    cat: '<a>Consell:</a><b> utilitza les tecles ← i → per moure`t més ràpid entre les preguntes.</b>',
    eus: '<a>Aholkua:</a><b> erabili ← eta → teklatuak galdera artean azkarrago mugitzeko.</b>'
  },
  WELCOME_SUBTITLE_1: {
    es: 'Soy <a>Umy</a>, tu compi en esta lucha hacia tus objetivos. ⚡⚡️<br>Espero que traigas muchos sandwiches de queso 🤤<br>porque te necesitamos con muuucha energía.<br>¡Vamos a por todas! 😎',
    en: "I am <a>Umy</a>, your partner in this fight towards your goals. ⚡⚡️<br>I hope you bring many cheese sandwiches 🤤<br>because we need you with a lot of energy.<br>Let's go for it! 😎",
    cat: 'Sóc <a>Umy</a>, el teu company en aquesta lluita cap als teus objectius. ⚡⚡️<br>Espero que portis molts entrepans de formatge 🤤<br>perquè et necessitem amb muuucha energia.<br>Anem a per totes! 😎',
    eus: 'Nire izena <a>Umy</a> da, zure laguna helburu horietara heltzen. ⚡⚡️<br>Espero dutela gazta-odolostu asko 🤤<br>zure energia behar dugu.<br>¡Hemen gaude! 😎'
  },
  ADD_POINTS: {
    es: '+ {{ points }} puntos',
    en: '+ {{ points }} points',
    cat: '+ {{ points }} punts',
    eus: '+ {{ points }} puntu'
  },
  CLAIM_POINTS: {
    es: 'Recibir mis puntos',
    en: 'Claim my points',
    cat: 'Rebre els meus punts',
    eus: 'Nire puntuak jaso'
  },
  CLAIM_NUMBER_OF_POINTS: {
    es: 'RECLAMAR {{ points }} PUNTOS',
    en: 'CLAIM {{ points }} POINTS',
    cat: 'RECLAMAR {{ points }} PUNTS',
    eus: '{{ points }} PUNTU JASO'
  },
  BULLET_POINT_VIDEO_SUMMARY_TITLE: {
    es: 'Como nos mola la acción, te resumo el video, {{ name }}:',
    en: 'As we love action, I summarize the video for you, {{ name }}:',
    cat: 'Com ens agrada l`acció, et resumiré el vídeo, {{ name }}:',
    eus: 'Ekintza gustatzen zaigu, bideoa laburtuko dizut, {{ name }}:'
  },
  QUESTIONS_TOTAL: {
    es: '<a>Total:</a> {{total}} preguntas',
    en: '<a>Total:</a> {{total}} questions',
    cat: '<a>Total:</a> {{total}} preguntes',
    eus: '<a>Totala:</a> {{total}} galdera'
  },
  ANSWERS_TOTAL: {
    es: '<a>{{answer}}:</a> <b>{{total}}</b>',
    en: '<a>{{answer}}:</a> <b>{{total}}</b>',
    cat: '<a>{{answer}}:</a> <b>{{total}}</b>',
    eus: '<a>{{answer}}:</a> <b>{{total}}</b>'
  },
  QUIZ_MODE: {
    es: 'Tipo',
    en: 'Mode',
    cat: 'Tipus',
    eus: 'Mota'
  },
  [EQuizMode.ACTIVITY_MODE]: {
    es: 'Actividad',
    en: 'Activity',
    cat: 'Activitat',
    eus: 'Jarduera'
  },
  [EQuizMode.FREE_MODE]: {
    es: 'Libre',
    en: 'Free',
    cat: 'Lliure',
    eus: 'Askea'
  },
  [EQuizMode.PRACTICE_MODE]: {
    es: 'Simulacro',
    en: 'Practice',
    cat: 'Simulacre',
    eus: 'Simulakro'
  },
  SELECTED_QUESTIONS_TO_ADD: {
    es: '<a>{{total}} preguntas</a> seleccionadas',
    en: '<a>{{total}} questions</a> selected',
    cat: '<a>{{total}} preguntes</a> seleccionades',
    eus: '<a>{{total}} galdera</a> hautatuak'
  },
  DONT_SHOW_AGAIN: {
    es: 'No volver a mostrar',
    en: 'Don`t show again',
    cat: 'No tornar a mostrar',
    eus: 'Ez erakutsi berriro'
  },
  GREAT_THANK_YOU: {
    es: 'Genial, gracias',
    en: 'Great, thanks',
    cat: 'Genial, gràcies',
    eus: 'Zoragarria, eskerrik asko'
  },
  DONT_SHOW_AGAIN_CONFIRMATION: {
    es: '¿Seguro? No podrás deshacer esta acción.',
    en: 'Are you sure? You won`t be able to undo this action.',
    cat: 'Segur? No podràs desfer aquesta acció.',
    eus: 'Ziur? Ezin izango duzu desegin ekintza hau.'
  },
  [ECountry.AD]: {
    es: 'Andorra',
    en: 'Andorra',
    cat: 'Andorra',
    eus: 'Andorra'
  },
  [ECountry.AR]: {
    es: 'Argentina',
    en: 'Argentina',
    cat: 'Argentina',
    eus: 'Argentina'
  },
  [ECountry.BO]: {
    es: 'Bolivia',
    en: 'Bolivia',
    cat: 'Bolívia',
    eus: 'Bolivia'
  },
  [ECountry.BR]: {
    es: 'Brasil',
    en: 'Brazil',
    cat: 'Brasil',
    eus: 'Brasil'
  },
  [ECountry.CL]: {
    es: 'Chile',
    en: 'Chile',
    cat: 'Xile',
    eus: 'Txile'
  },
  [ECountry.CO]: {
    es: 'Colombia',
    en: 'Colombia',
    cat: 'Colòmbia',
    eus: 'Kolonbia'
  },
  [ECountry.CR]: {
    es: 'Costa Rica',
    en: 'Costa Rica',
    cat: 'Costa Rica',
    eus: 'Costa Rica'
  },
  [ECountry.CU]: {
    es: 'Cuba',
    en: 'Cuba',
    cat: 'Cuba',
    eus: 'Kuba'
  },
  [ECountry.DO]: {
    es: 'República Dominicana',
    en: 'Dominican Republic',
    cat: 'República Dominicana',
    eus: 'Dominikar Errepublika'
  },
  [ECountry.EC]: {
    es: 'Ecuador',
    en: 'Ecuador',
    cat: 'Equador',
    eus: 'Ekuador'
  },
  [ECountry.ES]: {
    es: 'España',
    en: 'Spain',
    cat: 'Espanya',
    eus: 'Espainia'
  },
  [ECountry.FR]: {
    es: 'Francia',
    en: 'France',
    cat: 'França',
    eus: 'Frantzia'
  },
  [ECountry.GF]: {
    es: 'Guayana Francesa',
    en: 'French Guiana',
    cat: 'Guaiana Francesa',
    eus: 'Guyana Frantsesa'
  },
  [ECountry.MA]: {
    es: 'Marruecos',
    en: 'Morocco',
    cat: 'Marroc',
    eus: 'Maroko'
  },

  [ECountry.GT]: {
    es: 'Guatemala',
    en: 'Guatemala',
    cat: 'Guatemala',
    eus: 'Guatemala'
  },
  [ECountry.HT]: {
    es: 'Haiti',
    en: 'Haiti',
    cat: 'Haití',
    eus: 'Haiti'
  },
  [ECountry.HN]: {
    es: 'Honduras',
    en: 'Honduras',
    cat: 'Hondures',
    eus: 'Honduras'
  },
  [ECountry.JM]: {
    es: 'Jamaica',
    en: 'Jamaica',
    cat: 'Jamaica',
    eus: 'Jamaika'
  },
  [ECountry.MX]: {
    es: 'México',
    en: 'Mexico',
    cat: 'Mèxic',
    eus: 'Mexiko'
  },
  [ECountry.NI]: {
    es: 'Nicaragua',
    en: 'Nicaragua',
    cat: 'Nicaragua',
    eus: 'Nikaragua'
  },
  [ECountry.PA]: {
    es: 'Panamá',
    en: 'Panama',
    cat: 'Panamà',
    eus: 'Panama'
  },
  [ECountry.PY]: {
    es: 'Paraguay',
    en: 'Paraguay',
    cat: 'Paraguai',
    eus: 'Paraguai'
  },
  [ECountry.PE]: {
    es: 'Perú',
    en: 'Peru',
    cat: 'Perú',
    eus: 'Peru'
  },
  [ECountry.PR]: {
    es: 'Puerto Rico',
    en: 'Puerto Rico',
    cat: 'Puerto Rico',
    eus: 'Puerto Rico'
  },
  [ECountry.PT]: {
    es: 'Portugal',
    en: 'Portugal',
    cat: 'Portugal',
    eus: 'Portugal'
  },
  [ECountry.SR]: {
    es: 'Surinam',
    en: 'Suriname',
    cat: 'Surinam',
    eus: 'Surinam'
  },
  [ECountry.SV]: {
    es: 'El Salvador',
    en: 'El Salvador',
    cat: 'El Salvador',
    eus: 'El Salvador'
  },
  [ECountry.UY]: {
    es: 'Uruguay',
    en: 'Uruguay',
    cat: 'Uruguai',
    eus: 'Uruguai'
  },
  [ECountry.VE]: {
    es: 'Venezuela',
    en: 'Venezuela',
    cat: 'Veneçuela',
    eus: 'Venezuela'
  },
  [EYearMonths.JANUARY]: {
    es: 'Enero',
    en: 'January',
    cat: 'Gener',
    eus: 'Urtarrila'
  },
  [EYearMonths.FEBRUARY]: {
    es: 'Febrero',
    en: 'February',
    cat: 'Febrer',
    eus: 'Otsaila'
  },
  [EYearMonths.MARCH]: {
    es: 'Marzo',
    en: 'March',
    cat: 'Març',
    eus: 'Martxoa'
  },
  [EYearMonths.APRIL]: {
    es: 'Abril',
    en: 'April',
    cat: 'Abril',
    eus: 'Apirila'
  },
  [EYearMonths.MAY]: {
    es: 'Mayo',
    en: 'May',
    cat: 'Maig',
    eus: 'Maiatza'
  },
  [EYearMonths.JUNE]: {
    es: 'Junio',
    en: 'June',
    cat: 'Juny',
    eus: 'Ekaina'
  },
  [EYearMonths.JULY]: {
    es: 'Julio',
    en: 'July',
    cat: 'Juliol',
    eus: 'Uztaila'
  },
  [EYearMonths.AUGUST]: {
    es: 'Agosto',
    en: 'August',
    cat: 'Agost',
    eus: 'Abuztua'
  },
  [EYearMonths.SEPTEMBER]: {
    es: 'Septiembre',
    en: 'September',
    cat: 'Setembre',
    eus: 'Iraila'
  },
  [EYearMonths.OCTOBER]: {
    es: 'Octubre',
    en: 'October',
    cat: 'Octubre',
    eus: 'Urria'
  },
  [EYearMonths.NOVEMBER]: {
    es: 'Noviembre',
    en: 'November',
    cat: 'Novembre',
    eus: 'Azaroa'
  },
  [EYearMonths.DECEMBER]: {
    es: 'Diciembre',
    en: 'December',
    cat: 'Desembre',
    eus: 'Abendua'
  },
  YOUR_EXAM_GROUPS_EMPTY: {
    es: 'No estás apuntado a ninguna convocatoria',
    en: 'You are not registered for any exam group',
    cat: 'No estàs apuntat a cap convocatòria',
    eus: 'Ez zaude inor ere apuntatu'
  },
  JOIN_EXAM_GROUP: {
    es: 'Unirme a una convocatoria',
    en: 'Join exam group',
    cat: 'Unir-me a convocatòria',
    eus: 'Taldean apuntatu'
  },
  NEW_CHAT_MESSAGE: {
    es: 'Nuevo mensaje',
    en: 'New message',
    cat: 'Nou missatge',
    eus: 'Mezua berria'
  },
  HUBSPOT_CONTACT: {
    es: 'Contacto de Hubspot',
    en: 'Hubspot contact',
    cat: 'Contacte de Hubspot',
    eus: 'Hubspot kontaktua'
  },
  SELECT_HUBSPOT_CONTACT: {
    es: 'Seleccionar contacto de Hubspot',
    en: 'Select Hubspot contact',
    cat: 'Seleccionar contacte de Hubspot',
    eus: 'Hubspot kontaktua hautatu'
  },
  GO_TO_HUBSPOT_CONTACT: {
    es: 'Ir al contacto asociado de hubspot',
    en: 'Go to the associated hubspot contact',
    cat: 'Anar al contacte associat de hubspot',
    eus: 'Joan hubspot kontaktu batera'
  },
  [EActivityAuditStatus.CORRECT]: {
    es: 'Correcto',
    en: 'Correct',
    cat: 'Correcte',
    eus: 'Zuzena'
  },
  [EActivityAuditStatus.OBSOLETE]: {
    es: 'Obsoleto',
    en: 'Obsolete',
    cat: 'Obsolet',
    eus: 'Zaharkitua'
  },
  [EActivityAuditStatus.NON_COMPLIANT_STANDARDS]: {
    es: 'No cumple estándares',
    en: 'Does not meet standards',
    cat: 'No compleix estàndards',
    eus: 'Estandariek ez ditu betetzen'
  },
  [EActivityAuditStatus.OLD_FORMAT]: {
    es: 'Formato antiguo',
    en: 'Old format',
    cat: 'Format antic',
    eus: 'Formatu zaharra'
  },
  STUDENT_INFO: {
    es: 'Información del alumno',
    en: 'Student info',
    cat: 'Informació de l`alumne',
    eus: 'Ikaslearen informazioa'
  },
  STUDENT_FIRST_PAYMENT: {
    es: 'Fecha de inscripción (primer pago)',
    en: 'Registration date (first payment)',
    cat: 'Data d`inscripció (primer pagament)',
    eus: 'Izen-emate data (lehenengo ordaina)'
  },
  TAG_ASSOCIATED: {
    es: 'Categoría (tag) asociada',
    en: 'Associated category (tag)',
    cat: 'Categoria (tag) associada',
    eus: 'Kategoria (tag) lotura'
  },
  RESEND_KEYS: {
    es: 'Regenerar y enviar claves de acceso',
    en: 'Regenerate and send access keys',
    cat: 'Regenerar i enviar claus d`accés',
    eus: 'Berriz sortu eta bidali sarbide gakoak'
  },
  IMPERSONATE_STUDENT: {
    es: 'Impersonar al alumno',
    en: 'Impersonate student',
    cat: 'Impersonar a l`alumne',
    eus: 'Ikaslearen pertsona batu'
  },
  HUBSPOT_CONTACT_NOT_FOUND: {
    es: 'No se ha encontrado el contacto en Hubspot',
    en: 'Contact not found in Hubspot',
    cat: 'No s`ha trobat el contacte a Hubspot',
    eus: 'Ez da aurkitu kontaktua Hubspot-en'
  },
  SUMMARY: {
    es: 'Resumen',
    en: 'Summary',
    cat: 'Resum',
    eus: 'Laburpena'
  },
  TRAINING_INFO: {
    es: 'Datos de tu formación',
    en: 'Your training data',
    cat: 'Dades de la teva formació',
    eus: 'Zure prestakuntzaren datuak'
  },
  ALL_EXAM_GROUPS: {
    es: 'Todas las convocatorias',
    en: 'All exam groups',
    cat: 'Totes les convocatòries',
    eus: 'Azterketa talde guztiak'
  },
  NOTIFICATION_SETTINGS: {
    es: 'Configuración de notificaciones',
    en: 'Notification settings',
    cat: 'Configuració de notificacions',
    eus: 'Jakinarazpenen ezarpenak'
  },
  UCADEMY_COMMUNITY: {
    es: 'Comunidad de Ucademy',
    en: 'Ucademy community',
    cat: 'Comunitat de Ucademy',
    eus: 'Ucademy komunitatea'
  },
  PUBLISHED_AT: {
    es: 'Publicada el: {{ date }}',
    en: 'Published at: {{ date }}',
    cat: 'Publicada el: {{ date }}',
    eus: 'Argitaratua: {{ date }}'
  },
  VIEW_MORE_EXAM_GROUPS: {
    es: 'Ver más convocatorias',
    en: 'View more exam groups',
    cat: 'Veure més convocatòries',
    eus: 'Ikusi gehiago azterketak'
  },
  STREAK_DAYS: {
    es: 'Días seguidos accediendo',
    en: 'Consecutive days accessing',
    cat: 'Dies seguits accedint',
    eus: 'Egun jarraituak sartzen'
  },
  DAYS_one: {
    es: '1 día',
    en: '1 day',
    cat: '1 dia',
    eus: '1 egun'
  },
  DAYS_other: {
    es: '{{count}} días',
    en: '{{count}} days',
    cat: '{{count}} dies',
    eus: '{{count}} egun'
  },
  CHOOSE_AN_OPTION: {
    es: 'Elige una opción',
    en: 'Choose an option',
    cat: 'Tria una opció',
    eus: 'Aukeratu aukera bat'
  },
  [EStudyField.SCIENCES]: {
    es: 'Ciencias',
    en: 'Sciences',
    cat: 'Ciències',
    eus: 'Zientziak'
  },
  [EStudyField.SOCIALS]: {
    es: 'Sociales',
    en: 'Socials',
    cat: 'Socials',
    eus: 'Sozialak'
  },
  [EStudyField.HUMANITIES]: {
    es: 'Humanidades',
    en: 'Humanities',
    cat: 'Humanitats',
    eus: 'Gizarte zientziak'
  },
  [EStudyField.ARTS]: {
    es: 'Artes',
    en: 'Arts',
    cat: 'Arts',
    eus: 'Arteak'
  },
  THIS_DATA_IS_PRIVATE: {
    es: 'Este dato es completamente privado y lo usaremos solo con fines estadísticos',
    en: 'This data is completely private and we will use it only for statistical purposes',
    cat: 'Aquesta dada és completament privada i la farem servir només amb finalitats estadístiques',
    eus: 'Datu hau erabat pribatua da eta estatistika helburuetarako erabiliko dugu soilik'
  },
  [EVerticalNames.UNI]: {
    es: 'Acceso a la Universidad',
    en: 'University access',
    cat: 'Accés a la Universitat',
    eus: 'Unibertsitatean sartzeko'
  },
  [EVerticalNames.OPOS]: {
    es: 'Oposiciones',
    en: 'Oppositions',
    cat: 'Oposicions',
    eus: 'Oposizioak'
  },
  [EVerticalNames.FP]: {
    es: 'Formación Profesional',
    en: 'Professional training',
    cat: 'Formació Professional',
    eus: 'Lanbide Heziketa'
  },
  [EVerticalNames.WKC]: {
    es: 'Workcamps',
    en: 'Workcamps',
    cat: 'Workcamps',
    eus: 'Workcamps'
  },
  [ETimePreference.MORNING]: {
    es: 'Por la mañana',
    en: 'In the morning',
    cat: 'Al matí',
    eus: 'Goizean'
  },
  [ETimePreference.NOON]: {
    es: 'Al mediodía',
    en: 'At noon',
    cat: 'Al migdia',
    eus: 'Egunerako'
  },
  [ETimePreference.AFTERNOON]: {
    es: 'Por la tarde',
    en: 'In the afternoon',
    cat: 'A la tarda',
    eus: 'Arratsaldean'
  },
  [ETimePreference.EVENING]: {
    es: 'A última hora',
    en: 'At the last minute',
    cat: 'A última hora',
    eus: 'Gauan'
  },
  HOW_DID_YOU_DISCOVER_US: {
    es: 'Ficha confirmada. Dinos {{name}}: ¿Cómo nos conociste por <a>primera vez?</a>',
    en: 'Confirmed tab. Tell us {{name}}: How did you find us for the <a>first time?</a>',
    cat: 'Fitxa confirmada. Digues-nos {{name}}: Com ens vas conèixer per <a>primera vegada?</a>',
    eus: 'Fitxa baieztatua. Esan {{name}}: Nola ezagutu zaitugu <a>lehenengo aldiz?</a>'
  },
  STUDENT_SPECIFIC_INFORMATION: {
    es: 'Datos específicos',
    en: 'Specific data',
    cat: 'Dades específiques',
    eus: 'Berezko datuak'
  },
  LAST_14_DAYS: {
    es: 'Últimos 14 días',
    en: 'Last 14 days',
    cat: 'Últims 14 dies',
    eus: 'Azken 14 egunak'
  },
  NUMBER_OF_ACTIVITIES: {
    es: '{{count}} actividades',
    en: '{{count}} activities',
    cat: '{{count}} activitats',
    eus: '{{count}} eskubideak'
  },
  COMPLETED_PREVIEW: {
    es: 'completadas',
    en: 'completed',
    cat: 'completades',
    eus: 'betetzen'
  },
  NUMBER_OF_HOURS: {
    es: '{{count}} horas',
    en: '{{count}} hours',
    cat: '{{count}} hores',
    eus: '{{count}} ordu'
  },
  IN_APP: {
    es: 'en la plataforma',
    en: 'in the app',
    cat: 'en la plataforma',
    eus: 'plataforman'
  },
  NUMBER_OF_TESTS: {
    es: '{{count}} tests',
    en: '{{count}} tests',
    cat: '{{count}} tests',
    eus: '{{count}} testak'
  },
  DONE: {
    es: 'realizados',
    en: 'done',
    cat: 'realitzats',
    eus: 'egindakoak'
  },
  ACTIVITIES_COMPLETED_IN_LAST_14_DAYS: {
    es: 'Actividades completadas en los últimos 14 días',
    en: 'Activities completed in the last 14 days',
    cat: 'Activitats completades en els darrers 14 dies',
    eus: 'Eskubideak azken 14 egunetan betetzen'
  },
  DONE_ON: {
    es: 'Realizada el',
    en: 'Done on',
    cat: 'Realitzada el',
    eus: 'Eskubidearen eguna'
  },
  TUTOR: {
    es: 'Tutor',
    en: 'Tutor',
    cat: 'Tutor',
    eus: 'Tutorea'
  },
  TUTOR_NOT_ASSIGNED: {
    es: 'Tutor no asignado',
    en: 'Tutor not assigned',
    cat: 'Tutor no assignat',
    eus: 'Tutorea esleitua'
  },
  SELECT_TUTOR: {
    es: 'Seleccionar tutor',
    en: 'Select tutor',
    cat: 'Seleccionar tutor',
    eus: 'Aukeratu tutorea'
  },
  ROLE: {
    es: 'Rol',
    en: 'Role',
    cat: 'Rol',
    eus: 'Rol'
  },
  ONLINE_USERS: {
    es: 'Usuarios online',
    en: 'Online users',
    cat: 'Usuaris online',
    eus: 'Online erabiltzaileak'
  },
  LAST_SESSION_LOG: {
    es: 'Último registro de sesión',
    en: 'Last session log',
    cat: 'Últim registre de sessió',
    eus: 'Azken inprimatzen den erregistro'
  },
  NO_SESSION_LOGS: {
    es: 'No hay registros de sesión',
    en: 'No session logs',
    cat: 'No hi ha registres de sessió',
    eus: 'Ez da erregistro inprimatzen'
  },
  DOWNLOAD_APP: {
    es: 'Descarga la app',
    en: 'Download the app',
    cat: "Descarrega l'app",
    eus: 'Deskargatu aplikazioa'
  },
  ALL_TESTS: {
    es: 'Todos los tests',
    en: 'All tests',
    cat: 'Tots els tests',
    eus: 'Guztiak testak'
  },
  REVIEW_FAILURES: {
    es: 'Repaso de fallos',
    en: 'Review failures',
    cat: 'Revisió de falles',
    eus: 'Hutsak berrikustea'
  },
  STATISTICS: {
    es: 'Estadísticas',
    en: 'Statistics',
    cat: 'Estadístiques',
    eus: 'Estatistikak'
  },
  NEW_APP: {
    es: '¡Tenemos nueva app!',
    en: 'We have a new app!',
    cat: 'Tenim una nova app!',
    eus: 'Aplikazio berria daukagu!'
  },
  DOWNLOAD_APP_NOW: {
    es: 'Descárgala ahora:',
    en: 'Download it now:',
    cat: 'Descarrega-la ara:',
    eus: 'Deskargatu orain:'
  },
  GET_IT_ON_GOOGLE_PLAY: {
    es: '¡Consíguela en Google Play!',
    en: 'Get it on Google Play!',
    cat: 'Aconsegueix-la a Google Play!',
    eus: 'Lortu Google Play-en!'
  },
  DOWNLOAD_ON_THE_APP_STORE: {
    es: '¡Descárgala en la App Store!',
    en: 'Download it on the App Store!',
    cat: "Descarrega-la a l'App Store!",
    eus: 'Deskargatu App Storean!'
  },
  CHANGE_STATUS: {
    es: 'Cambiar estado',
    en: 'Change status',
    cat: 'Canviar estat',
    eus: 'Egoera aldatu'
  },
  CHANGE_STATUS_CONFIRMATION: {
    es: '¿Estás seguro de que deseas cambiar el estado de {{count}} estudiantes?',
    en: 'Are you sure you want to change the status of {{count}} students?',
    cat: 'Estàs segur que vols canviar l`estat d`aquests {{count}} alumnes?',
    eus: 'Ziur {{count}} ikasleen egoera aldatu nahi duzula?'
  },
  PROMOTION_CREATED_SUCCESSFULLY: {
    es: 'Promoción creada correctamente',
    en: 'Promotion created successfully',
    cat: 'Promoció creada correctament',
    eus: 'Promozioa ongi sortu'
  },
  PROMOTION_UPDATED_SUCCESSFULLY: {
    es: 'Promoción actualizada correctamente',
    en: 'Promotion updated successfully',
    cat: 'Promoció actualitzada correctament',
    eus: 'Promozioa ongi eguneratua'
  },
  PROMOTION_DELETED_SUCCESSFULLY: {
    es: 'Promoción eliminada correctamente',
    en: 'Promotion deleted successfully',
    cat: 'Promoció eliminada correctament',
    eus: 'Promozioa ongi ezabatua'
  },
  EXAMS_SCORES: {
    es: 'Notas de examen',
    en: 'Exam scores',
    cat: "Notes d'examen",
    eus: 'Ikasle-notak'
  },
  SELECT_PROMOTION: {
    es: 'Selecciona promoción',
    en: 'Select promotion',
    cat: 'Selecciona promoció',
    eus: 'Aukeratu promozioa'
  },
  SELECT_TEST: {
    es: 'Selecciona test',
    en: 'Select test',
    cat: 'Selecciona test',
    eus: 'Aukeratu testua'
  },
  EXAM_CREATED_SUCCESSFULLY: {
    es: 'Examen creado correctamente',
    en: 'Exam created successfully',
    cat: 'Examen creat correctament',
    eus: 'Ekzamen ongi sortu'
  },
  EXAM_UPDATED_SUCCESSFULLY: {
    es: 'Examen actualizado correctamente',
    en: 'Exam updated successfully',
    cat: 'Examen actualitzat correctament',
    eus: 'Ekzamen ongi eguneratua'
  },
  EXAM_DELETED_SUCCESSFULLY: {
    es: 'Examen eliminado correctamente',
    en: 'Exam deleted successfully',
    cat: 'Examen eliminat correctament',
    eus: 'Ekzamen ongi ezabatua'
  },
  TEACHER_SCORE: {
    es: 'Nota del profesor',
    en: 'Teacher score',
    cat: 'Nota del professor',
    eus: 'Ikasle-notak'
  },
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena'
  },
  EXAM_DATE: {
    es: 'Día del examen',
    en: 'Exam day',
    cat: 'Data de l`examen',
    eus: 'Ikasle-notak'
  },
  SEE_INFORMATION: {
    es: 'Ver información',
    en: 'See information',
    cat: 'Veure informació',
    eus: 'Ikusi informazioa'
  },
  NOT_SCORED: {
    es: 'No puntuado',
    en: 'Not scored',
    cat: 'No puntuado',
    eus: 'Ez da puntuatua'
  },
  DELIVERABLE: {
    es: 'Documento entregable',
    en: 'Deliverable document',
    cat: 'Document entregable',
    eus: 'Entregatutako dokumentua'
  },
  ONSITE: {
    es: 'Examen presencial',
    en: 'Onsite exam',
    cat: 'Examen presencial',
    eus: 'Preskribatutako ikasle-notak'
  },
  ONLINE: {
    es: 'Examen online',
    en: 'Online exam',
    cat: 'Examen online',
    eus: 'Online ikasle-notak'
  },
  LAST_UPDATE: {
    es: 'Última actualización',
    en: 'Last update',
    cat: 'Última actualització',
    eus: 'Azken eguneraketa'
  },
  FILE: {
    es: 'Archivo',
    en: 'File',
    cat: 'Fitxer',
    eus: 'Fitxategia'
  },
  EXAM_DAY: {
    es: '<a>Día del examen:</a> <b>{{date}}</b>',
    en: '<a>Exam day:</a> <b>{{date}}</b>',
    cat: "<a>Dia de l'examen:</a> <b>{{date}}</b>",
    eus: '<a>Ezamenaren eguna:</a> <b>{{date}}</b>'
  },
  SCORE_CREATED_SUCCESSFULLY: {
    es: 'Nota puesta correctamente',
    en: 'Score created successfully',
    cat: 'Nota creada correctament',
    eus: 'Ikasle-notak ongi sortu'
  },
  SCORE_UPDATED_SUCCESSFULLY: {
    es: 'Nota actualizada correctamente',
    en: 'Score updated successfully',
    cat: 'Nota actualitzada correctament',
    eus: 'Ikasle-notak ongi eguneratua'
  },
  CONTENT: {
    es: 'Contenido',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukia'
  },
  TAG: {
    es: 'Formación',
    en: 'Tag',
    cat: 'Formació',
    eus: 'Formakuntza'
  },
  REMOVE_CONTRACT: {
    es: 'Eliminar contrato',
    en: 'Remove contract',
    cat: 'Eliminar contrato',
    eus: 'Ezabatu kontratua'
  },
  REMOVE_CONTRACT_CONFIRMATION: {
    es: '¿Estás seguro de que deseas eliminar el contrato? El proceso es irreversible y conlleva un gran coste para la empresa.',
    en: 'Are you sure you want to remove the contract? The process is irreversible and involves a high cost for the company.',
    cat: 'Estàs segur que vols ezabatu kontratua? Procedimenta irreversible i suposa un alt cost per a l`empresa.',
    eus: 'Ziur zaude kontratua ezabatu nahi duzula? Prozesua irreversiboa da eta enpresa handi bati esanahi bat du.'
  }
};
