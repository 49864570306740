import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.cadet};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 20px;
  & > :first-child {
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    margin-bottom: 10px;
    & > :first-child {
      display: flex;
      cursor: pointer;
      align-items: center;
      & > :first-child {
        margin-right: 32px;
      }
    }
  }

  .ant-typography-edit-content {
    inset-inline-start: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  & > :last-child {
    margin-left: 58px;
    margin-right: 9px;
  }
`;
