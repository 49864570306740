import { Button } from 'antd';
import styled from 'styled-components';

import { Icon } from '@/components/Icon';

type IconProps = {
  isDelivered: boolean;
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px 16px 32px 16px;
  border: 1px solid ${({ theme }) => theme.colors.beau};
  background: ${({ theme }) => theme.colors.white};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const IconWrapper = styled(Icon)<IconProps>`
  padding: 10px 16px;
  background: ${({ theme, isDelivered }) =>
    isDelivered ? theme.colors.neoghost : theme.colors.solitude};
  color: ${({ theme, isDelivered }) =>
    isDelivered ? theme.colors.neonblue : theme.colors.gunmetal};
  border-radius: 4px;
  font-size: 24px;
`;

export const TitleWrapper = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DeliverableWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

export const YourWorkWrapper = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const SubtitleWrapper = styled.div`
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
`;

export const UploadedFileWrapper = styled.a`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.cadet};
  border-radius: 2px;
  padding: 12px 16px;
`;

export const UploadedFileInfo = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledButton = styled(Button)`
  min-width: 250px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
