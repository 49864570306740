import styled from 'styled-components';

type Props = {
  isChecked: boolean;
  isDisabled: boolean;
  hasProgress: boolean;
};

export const Wrapper = styled.div<Props>`
  width: fit-content;
  display: flex;
  gap: 8px;
  cursor: pointer;
  padding: ${({ hasProgress }) => (hasProgress ? '4px 4px 4px 14px' : '4px 14px')};
  border-radius: 33px;
  border: 1px solid
    ${({ theme, isDisabled, isChecked }) =>
      isDisabled ? theme.colors.cadet : isChecked ? theme.colors.neoreg : theme.colors.cadet};
  background: ${({ theme, isDisabled, isChecked }) =>
    isDisabled ? theme.colors.solitude : isChecked ? theme.colors.neoghost : theme.colors.white};

  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme, isDisabled, isChecked }) =>
    isDisabled ? theme.colors.marrow : isChecked ? theme.colors.neostrong : theme.colors.gunmetal};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  align-items: center;

  :hover {
    color: ${({ theme }) => theme.colors.neostrong};
    border: 1px solid ${({ theme }) => theme.colors.neoreg};
    background: ${({ theme }) => theme.colors.neowhite};
    span:first-of-type {
      background: ${({ theme }) => theme.colors.white} !important;
    }
  }
`;

type ProgressProps = {
  isChecked: boolean;
};

export const ProgressWrapper = styled.span<ProgressProps>`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  background: ${({ theme, isChecked }) => (isChecked ? theme.colors.white : theme.colors.solitude)};
  padding: 2px 8px 2px 8px;
  border-radius: 33px;
  color: ${({ theme }) => theme.colors.gunmetal};
`;
