import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.meadreg};
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const BadgeWrapper = styled.img`
  height: 94px;
`;

export const PreparingRankingWrapper = styled.img`
  height: 140px;
`;

export const PositionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

export const PositionText = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.2px;
`;

export const PointsWrapper = styled.div`
  color: ${({ theme }) => theme.colors.marrow};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
