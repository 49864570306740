import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  gap: 16px;
`;

export const TextWrapper = styled(Text)`
  cursor: pointer;
`;

export const EditTextWrapper = styled(Text)`
  color: ${({ theme }) => theme.colors.gunmetal}50;
`;
