import { Form } from 'antd';
import styled from 'styled-components';

export const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .fa-CheckmarkOutline {
    color: ${({ theme }) => theme.colors.meadreg};
  }

  .fa-CloseOutline {
    color: ${({ theme }) => theme.colors.error};
  }
`;

export const FormWrapper = styled(Form)`
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
  }

  .swiper-wrapper {
    border-radius: 10px;
    height: 600px;
    width: 500px;
    margin: 0 auto;
  }

  .swiper-slide-shadow {
    border-radius: 10px;
  }
`;

export const FormulaWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const ImageWrapper = styled.div`
  height: auto;
  width: 200px;
  margin: 12px;
`;

export const ImageLink = styled.a``;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;
