import { SOCKET_EVENTS } from '@constants';
import { IChatMessageSent } from '@sdk/contracts';
import { notification } from 'antd';
import { useCallback, useEffect, useRef } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import notificationSound from '@/assets/sounds/notification.mp3';
import { Icon } from '@/components/Icon';
import { useSocket } from '@/hooks';
import { useLocalChats, useMutateChatMessage, useProfile } from '@/store';

const MESSAGE_THRESHOLD = 3;

export const ChatMessagesHandler = () => {
  const { setAllMessagesAsRead } = useMutateChatMessage();
  const { saveLocalChatMessage } = useLocalChats();
  const { profile } = useProfile();
  const navigateTo = useNavigate();
  const { socket } = useSocket();
  const location = useLocation();
  const { t } = useTranslation();
  const mounted = useRef(false);

  const pathname = useRef(location.pathname);
  const profileId = useRef('');

  const [api, contextHolder] = notification.useNotification({
    stack: { threshold: MESSAGE_THRESHOLD }
  });

  const goToChat = useCallback(
    (chat: IChatMessageSent) => {
      const student = chat.participants.find(p => p.role === 'STUDENT');
      const tutor = chat.participants.find(p => p.role === 'TEACHER');
      if (!student || !tutor) return;
      navigateTo(`/chat/${tutor._id}/${student._id}`);
    },
    [navigateTo]
  );

  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.volume = 0;
    audio.play();
    audio.volume = 1;
  };

  const handleSaveLocalChatMessage = useCallback(
    (data: IChatMessageSent) => {
      const isInCurrentChatWindow =
        pathname.current.includes('/chat') &&
        pathname.current.includes(data.sender._id) &&
        pathname.current.includes(profileId.current);

      const isRecipient = data.recipients.some(r => r === profileId.current);

      saveLocalChatMessage(data.chatId, data);

      if (isRecipient && !isInCurrentChatWindow) {
        api.open({
          type: 'info',
          key: data._id,
          description: <div dangerouslySetInnerHTML={{ __html: data.content }} />,
          onClick: () => goToChat(data),
          icon: <Icon i="Chat" size="32" color="meadreg" />,
          message: <strong>{t('NEW_CHAT_MESSAGE')}</strong>
        });
        playNotificationSound();
      }

      if (isRecipient && isInCurrentChatWindow) {
        setAllMessagesAsRead(data.chatId);
      }
    },
    [saveLocalChatMessage, profileId.current, pathname.current]
  );

  useEffect(() => {
    if (!socket) return;
    if (mounted.current) return;
    socket?.on(SOCKET_EVENTS.CHAT_MESSAGE, (data: IChatMessageSent) => {
      handleSaveLocalChatMessage(data);
    });
    mounted.current = true;
  }, [socket, handleSaveLocalChatMessage]);

  useEffect(() => {
    pathname.current = location.pathname;
    profileId.current = profile?._id || '';
  }, [location.pathname, profile?._id]);

  return <React.StrictMode>{contextHolder}</React.StrictMode>;
};
