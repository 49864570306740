import { RequestService } from '../request';

import { ICreatePlanification, IPlanification } from './planification.contract';

export class PlanificationService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async getPlanificationByUser(userId: string) {
    return this.fetcher<IPlanification>(`/planification/user/${userId}`);
  }

  async createPlanification(createPlanification: ICreatePlanification) {
    return this.poster(`/planification`, createPlanification);
  }
}
