import { Checkbox } from 'antd';
import styled from 'styled-components';

export const ActivityStatus = styled.div<{ active: boolean }>`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${({ theme, active }) => (active ? theme.colors.meadreg : theme.colors.error)};
  position: absolute;
  top: 2px;
  left: 2px;
`;

export const Cover = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.gunmetal};
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px;
  width: 42px;

  i {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.meadreg};
  }

  .fa-circle-exclamation {
    font-size: 6px;
    border-radius: 50%;
    position: absolute;
    color: ${({ theme }) => theme.colors.warning};
    top: 2px;
    left: 2px;
  }
`;

export const Wrapper = styled.div`
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.solitude};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
  width: 100%;

  & > :last-child {
    margin-left: auto;
    margin-right: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    & > i {
      cursor: pointer;
    }
  }

  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid ${({ theme }) => theme.colors.gunmetal};
  }
`;

export const ActivityNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const WrapperActivity = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-right: 20px;
`;
