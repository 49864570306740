import { Form } from 'antd';
import styled from 'styled-components';

import { Icon } from '@/components/Icon';

export const Wrapper = styled.div`
  position: relative;
  border-radius: 10px;
  min-height: 600px;
  box-shadow: ${({ theme }) => theme.boxShadow.primary};
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 500px;
  margin: 0 auto;
`;

export const QuestionWrapper = styled.div`
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.solitude};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  height: 100%;
`;

export const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .fa-CheckmarkOutline {
    color: ${({ theme }) => theme.colors.meadreg};
  }

  .fa-CloseOutline {
    color: ${({ theme }) => theme.colors.error};
  }
`;

export const FormulaWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const StyledIcon = styled(Icon)`
  font-size: 14px;
`;

export const ImageWrapper = styled.div`
  height: auto;
  width: 200px;
  margin: 12px;
`;

export const ImageLink = styled.a``;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

export const NavigationButtons = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.beau};
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    position: relative;
    max-width: 500px;
    margin: 32px auto 0 auto;
    background-color: transparent;
    border-top: none;
  }
`;

export const QuestionCount = styled.span`
  padding: 2px 10px;
  text-align: center;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.neoreg};
`;

export const QuestionText = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const AnswerTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-weight: 700;
  margin-bottom: 16px;
`;

export const FormWrapper = styled(Form)`
  height: 600px;
  width: 500px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.solitude};
  box-shadow: 2px 12px 5px 0px #0000001a;
  margin: 0 auto;
  border-radius: 10px;
`;
