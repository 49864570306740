import styled from 'styled-components';

import { Icon } from '@/components/Icon';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: 8px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    gap: 16px;
    flex-direction: row;
  }
`;

export const AddonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const AddonTextWrapper = styled.div`
  color: ${({ theme }) => theme.colors.charcoal};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

export const StyledIcon = styled(Icon)`
  transition: transform 0.3s ease;
  transform-origin: left;
`;
