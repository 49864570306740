export enum EActivityType {
  VIDEO = 'VIDEO',
  INTERACTIVE_VIDEO = 'INTERACTIVE_VIDEO',
  PDF = 'PDF',
  AUDIO = 'AUDIO',
  QUIZ = 'QUIZ',
  DELIVERABLE = 'DELIVERABLE'
}

export enum EAttachmentType {
  LINK = 'LINK',
  FILE = 'FILE'
}

export enum EActivityAuditStatus {
  CORRECT = 'CORRECT',
  OLD_FORMAT = 'OLD_FORMAT',
  NON_COMPLIANT_STANDARDS = 'NON_COMPLIANT_STANDARDS',
  OBSOLETE = 'OBSOLETE'
}
