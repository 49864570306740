import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const PostCategoryService = sdk.getService('PostCategoryService');

export const usePostCategoriesList = () => {
  const queryKey = ['post-categories'];

  const { isPending, data: postCategories } = useQuery({
    queryKey,
    queryFn: () => PostCategoryService.getPostCategories()
  });

  return { postCategories, isPending };
};
