import styled from 'styled-components';

export const OpenTicketWrapper = styled.div`
  display: flex;
  padding: 8px 16px;
  gap: 16px;
  width: 100%;
  overflow-x: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.colors.beau};
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > span {
    width: 140px;
  }
`;

export const AvatarContainer = styled.div`
  width: 24px;
`;

export const TitleContainer = styled.div`
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;

  & > div {
    margin: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  padding: 8px;
  border: 1px solid #000;
`;
