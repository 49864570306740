import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-height: 66vh;
  overflow: scroll;
  .ant-table-row {
    background: ${({ theme }) => theme.colors.solitude};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.meadreg};
    background-color: transparent;
    border-width: 2px;
    :after {
      background-color: ${({ theme }) => theme.colors.meadreg};
    }
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.meadreg};
  }

  .ant-form-item {
    margin-bottom: 0px;
  }
`;

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 80fr 20fr;
  background: ${({ theme }) => theme.colors.meadghost};
  height: 48px;
  padding: 8px 24px 8px 48px;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.solitude};
  border-radius: 8px 8px 0px 0px;
  font-weight: 600;
`;

export const TableActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${({ theme }) => theme.colors.white};
`;
