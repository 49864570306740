import { ETicketStatus } from '@constants';

import { defaultTheme } from '../styles/theme';

export const TICKET_STATUS_COLORS = {
  [ETicketStatus.NEW]: defaultTheme.colors.neoreg,
  [ETicketStatus.IN_PROGRESS]: defaultTheme.colors.warning,
  [ETicketStatus.CLOSED_NOT_RESOLVED]: defaultTheme.colors.error,
  [ETicketStatus.CLOSED_RESOLVED]: defaultTheme.colors.meadstrong
};
