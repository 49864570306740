import { IPost } from '@sdk/contracts';
import { dateProvider } from '@utils';

import {
  ContentWrapper,
  DateWrapper,
  InfoWrapper,
  PostWrapper,
  StyledImage,
  TitleWrapper
} from './PostItem.styles';

import { Icon } from '@/components/Icon';

type Props = {
  onClick: (id: string) => void;
  post: IPost;
};

export const PostItem: React.FC<Props> = ({ onClick, post }) => {
  const { _id: id, title, createdAt, featuredImage, content } = post;

  return (
    <PostWrapper onClick={() => onClick(id)}>
      {featuredImage && <StyledImage src={featuredImage?.url} alt="News preview" />}
      <InfoWrapper>
        <DateWrapper>
          <Icon i="Calendar" />
          <div>{dateProvider.format(createdAt, 'dd/MM/yyyy')}</div>
        </DateWrapper>
        <TitleWrapper>{title}</TitleWrapper>
        <ContentWrapper dangerouslySetInnerHTML={{ __html: content }} />
      </InfoWrapper>
    </PostWrapper>
  );
};
