import { ICreatePostTopic } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const PostTopicService = sdk.getService('PostTopicService');

export const useMutatePostTopic = () => {
  const { t } = useTranslation('CommonPostTranslations');
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['post-topics'] });

  const { isPending: isCreating, mutate: createPostTopic } = useMutation({
    mutationFn: (data: ICreatePostTopic) => PostTopicService.createTopic(data),
    onSuccess: () => {
      onSuccess();
      message.success(t('TOPIC_CREATED_SUCCESSFULLY'));
    }
  });

  const { isPending: isDeleting, mutate: removePostTopic } = useMutation({
    mutationFn: (topicId: string) => PostTopicService.remove(topicId),
    onSuccess: () => {
      onSuccess();
      message.success(t('TOPIC_REMOVED_SUCCESSFULLY'));
    }
  });

  return {
    isPending: isCreating || isDeleting,
    createPostTopic,
    removePostTopic
  };
};
