import { Button } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: ${({ isCurrent }) => (isCurrent ? 'column' : 'row')};
  justify-content: ${({ isCurrent }) => (isCurrent ? 'unset' : 'space-between')};
  gap: 16px;
  cursor: ${({ isLocked }) => (isLocked ? 'not-allowed' : 'pointer')};

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

type Props = {
  isCurrent: boolean;
  isLocked?: boolean;
  isCompleted?: boolean;
};

export const IconWrapper = styled.div<Props>`
  display: flex;
  padding: 4px;
  border-radius: 4px;
  color: ${({ theme, isLocked }) => isLocked && theme.colors.white};
  background: ${({ theme, isCurrent, isCompleted, isLocked }) =>
    isLocked
      ? theme.colors.cadet
      : isCurrent
      ? theme.colors.neoghost
      : isCompleted
      ? theme.colors.meadghost
      : theme.colors.white};
  pointer-events: none;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.span<Props>`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 14px;
  font-style: normal;
  font-weight: ${({ isCurrent }) => (isCurrent ? 600 : 400)};
  line-height: 22px;
  color: ${({ theme, isCurrent, isLocked }) =>
    isLocked ? theme.colors.marrow : isCurrent ? theme.colors.neoreg : theme.colors.gunmetal};
`;

export const SubtitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.marrow};
`;

export const StyledButton = styled(Button)<Props>`
  width: 100%;
  display: ${({ isCurrent, isCompleted, isLocked }) =>
    isCurrent ? 'block' : isCompleted || isLocked ? 'flex' : 'none'};
  color: ${({ isCompleted, isCurrent, theme }) =>
    isCompleted && !isCurrent ? theme.colors.meadreg : ''};
  align-self: center;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    display: ${({ isCurrent, isCompleted, isLocked }) =>
      isCurrent || isCompleted || isLocked ? 'block' : 'none'};
    width: 161px;
  }
`;
