import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  cursor: pointer;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const LoadingWrapper = styled.div`
  height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AddonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const AddonTextWrapper = styled.div`
  color: ${({ theme }) => theme.colors.charcoal};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  transition: color 300ms;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ActivityNameWrapper = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
`;

export const StyledImage = styled.img`
  transform: translate(0, 18px);
`;

export const NextClassWrapper = styled.div`
  display: flex;
  padding: 6px 8px;
  align-items: flex-start;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.meadghost};
  width: fit-content;
  position: relative;
  color: ${({ theme }) => theme.colors.gunmetal};

  ::before {
    content: '';
    position: absolute;
    left: -5px;
    top: 40%;
    transform: rotate(-45deg);
    width: 10px;
    height: 10px;
    background: ${({ theme }) => theme.colors.meadghost};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
