import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ScoreWrapper = styled.div`
  background: ${({ theme }) => theme.colors.solitude};
  border-radius: 4px;
  padding: 0px 16px;
`;

export const ScoreTextWrapper = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
