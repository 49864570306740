import { ERoles } from '@constants';
import { IUser } from '@sdk/contracts';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useIsMobile } from './useIsMobile';

import { sdk } from '@/services';

const UserService = sdk.getService('UserService');

const EXCLUDED_PATHS = ['/chat'];

export const useHubspot = () => {
  const [widgetLoaded, setWidgetLoaded] = useState(false);
  const scriptLoaded = useRef(false);
  const location = useLocation();
  const isMobile = useIsMobile();

  const init = (profile: IUser) => {
    const { role } = profile;
    if (isMobile || role !== ERoles.STUDENT || scriptLoaded.current) return;
    const script = document.createElement('script');
    script.src = 'https://js-eu1.hs-scripts.com/25603833.js';
    script.async = true;
    script.defer = true;
    script.id = 'hs-script-loader';
    document.body.appendChild(script);
    scriptLoaded.current = true;
  };

  const initChatWidget = () => {
    window.HubSpotConversations?.clear();
    window.HubSpotConversations?.widget?.load();
  };

  const removeChatWidget = () => {
    window.HubSpotConversations?.widget?.remove();
  };

  const getHubspotVisitorToken = useCallback(async () => {
    const data = await UserService.getHubspotVisitorToken();
    window.hsConversationsSettings = {
      loadImmediately: false,
      identificationEmail: data.email,
      identificationToken: data.token
    };
    initChatWidget();
  }, []);

  useEffect(() => {
    if (widgetLoaded) {
      const hiddeChatWidget = EXCLUDED_PATHS.some(path => location.pathname.includes(path));
      if (hiddeChatWidget) {
        removeChatWidget();
      } else {
        initChatWidget();
      }
    }
  }, [widgetLoaded, location]);

  useEffect(() => {
    if (scriptLoaded.current) {
      const onConversationsAPIReady = () => {
        getHubspotVisitorToken();
      };

      window.hsConversationsSettings = {};
      if (window.HubSpotConversations) {
        onConversationsAPIReady();
        window.HubSpotConversations.on('widgetLoaded', () => {
          setWidgetLoaded(true);
        });
      } else {
        window.hsConversationsOnReady = [onConversationsAPIReady];
      }
    }
  }, [scriptLoaded.current]);

  return { init };
};
