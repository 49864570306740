import { MONTHS } from '@constants';
import { dateProvider } from '@utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  TitleWrapper,
  Wrapper,
  InfoWrapper,
  BadgeWrapper,
  PositionWrapper,
  PositionText,
  PointsWrapper,
  LoadingWrapper,
  PreparingRankingWrapper
} from './RankingPosition.styles';

import LEAGUE_BRONZE from '@/assets/league-bronze.svg';
import LEAGUE_GOLD from '@/assets/league-gold.svg';
import LEAGUE_SILVER from '@/assets/league-silver.svg';
import LEAGUE_UCA from '@/assets/league-ucademy.svg';
import LOCKED_RANKING from '@/assets/locked_ranking.svg';
import UMY_PREPARING_RANKING from '@/assets/umy_preparing_ranking.svg';
import { LoadingCircleSpinner } from '@/components/Loaders';
import { useMySubscriptions, useMyWeeklyPointCount, useRankingByCourse } from '@/store';

const now = dateProvider.now();
const todayNumber = dateProvider.getDayOfMonth(now);
const isRankingLocked = todayNumber < 5;
const month = now.getMonth();

export const RankingPosition = () => {
  const { t } = useTranslation(['DefaultTranslations', 'CommonDashboardTranslations']);
  const { selectedSubscription } = useMySubscriptions();
  const { weeklyPointCount } = useMyWeeklyPointCount();
  const { myPosition, isLoading } = useRankingByCourse();
  const navigateTo = useNavigate();

  const getLeagueBadge = (pos: number) => {
    if (pos === 1) return LEAGUE_GOLD;
    if (pos === 2) return LEAGUE_SILVER;
    if (pos === 3) return LEAGUE_BRONZE;
    return LEAGUE_UCA;
  };

  const badge = getLeagueBadge(myPosition + 1);
  const monthName = t(`DefaultTranslations:${MONTHS[month]}`);

  if (isRankingLocked)
    return (
      <Wrapper>
        <TitleWrapper>
          {t('CommonDashboardTranslations:PREPARING_MONTHLY_RANKING', { month: monthName })}
        </TitleWrapper>
        <InfoWrapper>
          <PreparingRankingWrapper src={UMY_PREPARING_RANKING} alt="League badge" />
          <PositionWrapper>
            <div>{t('CommonDashboardTranslations:PREPARING_MONTHLY_RANKING_DESCRIPTION')}</div>
          </PositionWrapper>
        </InfoWrapper>
      </Wrapper>
    );

  if (!selectedSubscription || isLoading)
    return (
      <LoadingWrapper>
        <LoadingCircleSpinner />
      </LoadingWrapper>
    );

  return (
    <Wrapper>
      {myPosition !== -1 ? (
        <>
          <TitleWrapper onClick={() => navigateTo('/community/ranking')}>
            {t('CommonDashboardTranslations:MONTHLY_RANKING', {
              courseName: selectedSubscription?.course.name
            })}
          </TitleWrapper>
          <InfoWrapper>
            <BadgeWrapper src={badge} alt="League badge" />
            <PositionWrapper>
              <PositionText>{`#${myPosition + 1}`}</PositionText>
              <PointsWrapper>
                {t('CommonDashboardTranslations:WEEKLY_POINTS', {
                  points: Math.floor(weeklyPointCount || 0)
                })}
              </PointsWrapper>
            </PositionWrapper>
          </InfoWrapper>
        </>
      ) : (
        <>
          <TitleWrapper onClick={() => navigateTo('/community/ranking')}>
            {t('CommonDashboardTranslations:UNLOCK_RANKING')}
          </TitleWrapper>
          <InfoWrapper>
            <BadgeWrapper src={LOCKED_RANKING} alt="League badge" />
            <PositionWrapper>
              <div>{t('CommonDashboardTranslations:GET_20_POINTS_TO_UNLOCK')}</div>
            </PositionWrapper>
          </InfoWrapper>
        </>
      )}
    </Wrapper>
  );
};
