import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid ${({ theme }) => theme.colors.solitude};

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ant-radio-wrapper span.ant-radio + * {
    width: 100%;
  }
`;

export const AddAnswerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
`;
