import { Empty } from 'antd';
import styled from 'styled-components';

import { Icon } from '@/components/Icon';

export const Wrapper = styled.div`
  margin-top: 35px;
  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const EmptyStyled = styled(Empty)`
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TagWrapper = styled.div`
  min-height: 25px;
  span {
    background-color: ${({ theme }) => theme.colors.solitude};
    padding: 5px 10px;
    border-radius: 5px;
  }
`;

export const ScoreContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const IconStyled = styled(Icon)`
  margin-right: 5px;
`;

export const Score = styled.div`
  border: 10px solid ${({ theme }) => theme.colors.meadreg};
  border-radius: 50%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 147px;
  height: 147px;
  min-width: 147px;
  width: 147px;
  font-size: ${({ theme }) => theme.fontSize.extraLarge};
`;
