import { RequestService } from '../request';

import { ILiveClassVote, IVoteLiveClass } from './live-class-vote.contract';

export class LiveClassVoteService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async checkPendingVotes() {
    return this.fetcher<ILiveClassVote[]>(`/live-class-vote/check-pending-votes`);
  }

  async voteLiveClass(data: IVoteLiveClass) {
    return this.putter<ILiveClassVote, IVoteLiveClass>(`/live-class-vote/vote`, data);
  }
}
