import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  justify-content: space-between;
  height: 80%;
  height: 200px;

  .ant-divider-horizontal {
    margin: 16px 0;
  }
`;

export const EmptyWrapper = styled.div`
  color: ${({ theme }) => theme.colors.marrow};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

export const ViewMoreWrapper = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;
`;
