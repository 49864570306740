import { Button } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.cadet};
  background: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledButton = styled(Button)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.link} !important;
  align-self: flex-end;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    align-self: center;
  }
`;
