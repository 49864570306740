import { AnalyticsTrackEvents } from '@constants';
import { dateProvider } from '@utils';
import { Badge, Calendar, Empty, Typography } from 'antd';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EventListWrapper, Header, Wrapper } from './CalendarWidget.styles';
import { EventItem } from './EventItem';

import { Icon } from '@/components/Icon';
import { WidgetBase } from '@/components/WidgetBase';
import { useMixpanel, useIsMobile } from '@/hooks';
import { useEventsList } from '@/store';
import { WidgetProps } from '@/types';
import { getEventsOfMonth, groupEventsByDate } from '@/utils';

const { Text } = Typography;

const EVENT_TITLE_LENGTH = 220;

export const CalendarWidget: React.FC<WidgetProps> = props => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const isMobile = useIsMobile();
  const [currentDate, setCurrentDate] = useState(dateProvider.now());
  const navigateTo = useNavigate();
  const { fireEvent } = useMixpanel();
  const { events = [] } = useEventsList();

  const groupedEvents = useMemo(() => (events ? groupEventsByDate(events) : []), [events]);
  const eventsOfMonth = useMemo(
    () => getEventsOfMonth(groupedEvents, currentDate),
    [groupedEvents, currentDate]
  );

  const onClick = (eventId: string, eventTitle: string) => () => {
    navigateTo(`/content/calendar/${eventId}`);
    fireEvent(AnalyticsTrackEvents.QUICK_ACCESS_DASHBOARD, {
      elementType: 'EVENT',
      elementName: eventTitle
    });
  };

  return (
    <WidgetBase title={t('NEXT_EVENTS')} {...props}>
      <Wrapper>
        <Calendar
          fullscreen={false}
          headerRender={({ value, onChange }) => {
            let current = value.clone();

            const months = [];
            const year = value.year();
            const month = value.month();
            const localeData = value.localeData();

            for (let i = 0; i < 12; i++) {
              current = current.month(i);
              months.push(localeData.months(current));
            }

            const handdleChangeMonth = (to: 'add' | 'subtract') => {
              const updateAction = to === 'add' ? dateProvider.add : dateProvider.subtract;
              const updateDate = updateAction(currentDate, 1, 'months');
              setCurrentDate(updateDate);
              onChange(dayjs(updateDate));
            };

            return (
              <Header>
                <Icon i="ChevronLeft" onClick={() => handdleChangeMonth('subtract')} size="24" />
                <div>{`${months[month]} ${year}`}</div>
                <Icon i="ChevronRight" onClick={() => handdleChangeMonth('add')} size="24" />
              </Header>
            );
          }}
          fullCellRender={date => {
            const key = dayjs(date).unix();
            const isSameDate = events.some(event => dateProvider.isToday(event.startDate.dateTime));
            return (
              <div key={key}>
                <div>{date.format('D')}</div>
                {isSameDate && <Badge dot />}
              </div>
            );
          }}
        />
        <EventListWrapper>
          {eventsOfMonth.length === 0 && (
            <Empty
              description={t('NO_EVENTS_IN_THIS_MONTH', {
                month: dateProvider.format(currentDate, 'MMMM')
              })}
            />
          )}
          {eventsOfMonth.map(({ _id, title, startDate, endDate }) => (
            <EventItem
              key={_id}
              startDate={startDate}
              endDate={endDate}
              title={
                <Text
                  style={{ width: isMobile ? '45vw' : EVENT_TITLE_LENGTH }}
                  ellipsis={{ tooltip: title }}>
                  {title}
                </Text>
              }
              onClick={onClick(_id, title)}
            />
          ))}
        </EventListWrapper>
      </Wrapper>
    </WidgetBase>
  );
};
