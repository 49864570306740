import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  width: 100%;
  height: 90%;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const PendingTasksWrapper = styled.div`
  color: ${({ theme }) => theme.colors.charcoal};
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
`;
