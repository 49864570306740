import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  & > :last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
`;

export const StudentNameWrapper = styled.div`
  color: ${({ theme }) => theme.colors.gunmetal};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;
