import styled from 'styled-components';

export const SubMenuWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 250px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1000;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.cadet};

  .ant-divider-horizontal {
    margin: 0;
  }
`;

export const SubMenuTitleWrapper = styled.div`
  cursor: pointer;
  padding: 4px 8px;
  width: 100%;
  border-radius: 4px;

  &:hover {
    color: ${({ theme }) => theme.colors.meadreg};
    background-color: ${({ theme }) => theme.colors.ghost};
  }
`;

export const SubMenuItemWrapper = styled.div`
  cursor: pointer;
  padding: 4px 8px;
  width: 100%;
  border-radius: 4px;
`;
