import styled from 'styled-components';

import { Icon } from '@/components/Icon';

export const Wrapper = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
`;

export const MessageCounterWrapper = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

export const FileWrapper = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.cadet};
  color: ${({ theme }) => theme.colors.gunmetal};
  border-radius: 2px;
  padding: 12px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  justify-content: flex-start;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    width: 480px;
  }
`;

export const NameText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
`;

export const DescriptionWrapper = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

export const TicketResolvedWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.neoghost};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 10px 0px;
`;

export const UploadedFileInfo = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
`;

export const FileNameWrapper = styled.div`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  white-space: nowrap;
`;

export const PendingFileIconWrapper = styled(Icon)`
  padding: 4px 6px;
  background: ${({ theme }) => theme.colors.solitude};
  color: ${({ theme }) => theme.colors.gunmetal};
  border-radius: 2px;
  padding: 4px;
  width: 24px;
  height: 24px;
`;
