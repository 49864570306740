import { ETaskStatus } from '@constants';
import { DefaultTheme } from 'styled-components';

export const getTaskColorsByStatus = (status: ETaskStatus, colors: DefaultTheme['colors']) => {
  switch (status) {
    case ETaskStatus.APPROVED:
      return {
        backgroundColor: colors.meadwhite,
        color: colors.meadreg,
        borderColor: colors.meadreg
      };
    case ETaskStatus.IN_REVIEW:
      return {
        backgroundColor: 'rgba(254, 184, 22, 0.10)',
        color: colors.warning,
        borderColor: colors.warning
      };
    case ETaskStatus.PENDING:
      return {
        backgroundColor: colors.solitude,
        color: colors.gunmetal,
        borderColor: colors.beau
      };
    case ETaskStatus.REJECTED:
      return {
        backgroundColor: 'rgba(227, 96, 88, 0.20)',
        color: colors.error,
        borderColor: colors.error
      };
  }
};
