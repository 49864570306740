import styled from 'styled-components';

export const EmptyWidgetWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 24px;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.solitude};
  border-radius: 8px;
`;

export const ImageWrapper = styled.div`
  flex: 0 0 25%;
  max-width: 200px;
  & img {
    width: 100%;
    height: auto;
  }
`;

export const TextWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const EmptyTitleWrapper = styled.span`
  text-align: left;
  color: ${({ theme }) => theme.colors.charcoal};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.2px;
  margin-bottom: 8px; /* Adjust as needed */
`;

export const EmptyDescriptionWrapper = styled.span`
  text-align: left;
  color: ${({ theme }) => theme.colors.gunmetal};
  font-size: 14px;
  line-height: 22px;
  word-wrap: break-word;
`;
