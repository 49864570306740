import styled from 'styled-components';

export const FooterWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  padding-top: 8px;
  border-top: ${({ isMobile, theme }) => (!isMobile ? `1px solid ${theme.colors.beau}` : 'unset')};
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const ButtonWrapper = styled.div<{ isDisabled: boolean }>`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  color: ${({ isDisabled, theme }) => (isDisabled ? theme.colors.cadet : 'unset')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  cursor: pointer;
`;

export const TextWrapper = styled.span<{ isDisabled: boolean }>`
  color: ${({ isDisabled, theme }) => (isDisabled ? 'unset' : theme.colors.link)};
`;
