import { EFeatures, ERoles } from '@constants';

import { AppWidget } from '../components/AppWidget';
import { CalendarWidget } from '../components/CalendarWidget';
import { GamificationWidget } from '../components/GamificationWidget';
import { InfoWidget } from '../components/InfoWidget';
import { LiveClassesWidget } from '../components/LiveClassesWidget';
import { OpenTicketsWidget } from '../components/OpenTicketsWidget';
import { PendingTasksWidget } from '../components/PendingTasksWidget';
import { PostWidget } from '../components/PostWidget';
import { QuizModeWidget } from '../components/QuizModeWidget';
import { ReportsWidget } from '../components/ReportsWidget';
import { StudentContentWidget } from '../components/StudentContentWidget';
import { TicketsWidget } from '../components/TicketsWidget';

import { ExamGroupsWidget, StudentLiveClassesWidget } from '@/components/Widgets';
import { WidgetProps } from '@/types';

type Props = Record<
  ERoles,
  {
    Widget: React.FC<WidgetProps>;
    position: string;
    availableForFeature?: EFeatures;
  }[]
>;

export const WIDGETS: Props = {
  [ERoles.ADMIN]: [],
  [ERoles.MANAGER]: [
    { Widget: InfoWidget, position: '1 / 1 / 2 / 3' },
    { Widget: TicketsWidget, position: '1 / 3 / 3 / 5' },
    { Widget: CalendarWidget, position: '1 / 5 / 5 / 7' },
    { Widget: PostWidget, position: '2 / 1 / 5 / 3' },
    { Widget: ReportsWidget, position: '3 / 3 / 5 / 5' }
  ],
  [ERoles.TEACHER]: [
    { Widget: PendingTasksWidget, position: '1 / 1 / 2 / 7' },
    { Widget: LiveClassesWidget, position: '2 / 1 / 4 / 4' },
    { Widget: OpenTicketsWidget, position: '2 / 4 / 3 / 7' }
  ],
  [ERoles.STUDENT]: [
    {
      Widget: StudentContentWidget,
      position: '1 / 1 / 2 / 4',
      availableForFeature: EFeatures.CONTENT
    },
    {
      Widget: QuizModeWidget,
      position: '1 / 4 / 2 / 7',
      availableForFeature: EFeatures.QUIZZES
    },
    {
      Widget: PostWidget,
      position: '2 / 1 / 5 / 3'
    },
    {
      Widget: GamificationWidget,
      position: '4 / 3 / 5 / 7'
    },
    {
      Widget: StudentLiveClassesWidget,
      position: '2 / 3 / 4 / 5',
      availableForFeature: EFeatures.LIVE_CLASSES
    },
    {
      Widget: ExamGroupsWidget,
      position: '2 / 5 / 4 / 7',
      availableForFeature: EFeatures.ANNOUNCEMENTS
    },
    {
      Widget: AppWidget,
      position: '5 / 3 / 5 / 5'
    }
  ],
  [ERoles.SALES]: [{ Widget: InfoWidget, position: '1 / 1 / 2 / 3' }],
  [ERoles.SUPPORT]: [{ Widget: InfoWidget, position: '1 / 1 / 2 / 3' }],
  [ERoles.INSPECTOR]: [{ Widget: InfoWidget, position: '1 / 1 / 2 / 3' }],
  [ERoles.AI_TEACHER]: []
};
