import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.solitude};
  align-items: flex-start;
`;

export const AnswerInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 78%;

  .ant-form-item {
    width: 100%;
  }
`;

export const AnswerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 15px 10px 47px;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
`;

export const FormulaWrapper = styled.div`
  display: flex;
  padding: 0px 0px 8px 80px;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  padding-left: 80px;
`;
