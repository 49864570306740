import { isNumber } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { ScoreWrapper, Wrapper } from './QuizScoreDetail.styles';

import { twoDecimals } from '@/utils';

type Props = {
  score?: number;
};

const MID_SCORE = 5;
const HIGH_SCORE = 7.5;

export const QuizScoreDetail: React.FC<Props> = ({ score }) => {
  const { colors } = useTheme();

  const getQuizStyle = useCallback(() => {
    if (!score) return { color: colors.meadreg, backgroundColor: colors.meadwhite };

    if (score >= HIGH_SCORE) {
      return {
        color: colors.meadreg,
        backgroundColor: colors.meadwhite
      };
    }

    if (score >= MID_SCORE) {
      return {
        color: colors.warning,
        backgroundColor: `${colors.warning}10`
      };
    }

    return {
      color: colors.error,
      backgroundColor: `${colors.error}10`
    };
  }, [score, colors]);

  const { color, backgroundColor } = useMemo(getQuizStyle, [getQuizStyle]);
  if (score === undefined) return null;

  return (
    <Wrapper>
      <ScoreWrapper color={color} backgroundColor={backgroundColor}>
        {isNumber(score) ? twoDecimals(score) : '-'}
      </ScoreWrapper>
    </Wrapper>
  );
};
