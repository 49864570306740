import styled from 'styled-components';

import bg from '@/assets/banner-background.png';

export const Wrapper = styled.div<{ active: boolean }>`
  border-radius: 8px;
  position: relative;
  background-image: url(${bg});
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  cursor: ${({ active }) => (active ? 'pointer' : 'not-allowed')};
  color: ${({ theme }) => theme.colors.white};

  & > i {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.warning};
  }

  & > :nth-child(2) {
    font-size: 18px;
    font-weight: 500;
  }

  & > :nth-child(3) {
    display: ${({ active }) => (active ? 'none' : 'block')};
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.77);
    left: 0;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
