import { Button } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  .IconViewFilled {
    fill: ${({ theme, disabled }) => (disabled ? theme.colors.cadet : theme.colors.neonblue)};
  }
  .IconViewOffFilled {
    fill: ${({ theme, disabled }) => (disabled ? theme.colors.cadet : theme.colors.error)};
  }
`;
