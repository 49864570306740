import { Empty, List } from 'antd';
import { useTranslation } from 'react-i18next';

import { useMyTicketsWidget } from './hooks';
import { TicketItem } from './TicketItem';
import { Wrapper } from './TicketsWidget.styles';

import { WidgetBase } from '@/components/WidgetBase';
import { WidgetProps } from '@/types';

export const TicketsWidget: React.FC<WidgetProps> = props => {
  const { t } = useTranslation(['CommonDashboardTranslations', 'DefaultTranslations']);
  const { isLoading, tickets, pagination, setPageAndLimit } = useMyTicketsWidget();

  return (
    <WidgetBase title={t('CommonDashboardTranslations:DOUBTS_TITLE')} {...props}>
      <Wrapper>
        <List
          loading={isLoading}
          pagination={{
            current: pagination?.page,
            pageSize: pagination?.limit,
            total: pagination?.totalDocs,
            simple: true,
            align: 'center',
            onChange: (page, limit) => setPageAndLimit(page, limit)
          }}
          dataSource={tickets}
          renderItem={({ _id, title, status, createdAt }) => (
            <List.Item>
              <TicketItem createdAt={createdAt} id={_id} status={status} title={title} />
            </List.Item>
          )}
          locale={{
            emptyText: (
              <Empty description={t('DefaultTranslations:YOU_HAVE_NOT_CREATED_DOUBT_TICKETS')} />
            )
          }}
        />
      </Wrapper>
    </WidgetBase>
  );
};
