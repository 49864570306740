import styled from 'styled-components';

export const Wrapper = styled.div`
  & > :first-child {
    margin-bottom: 8px;
  }
`;

export const Item = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;

  transition: background-color 0.3s;

  & :hover {
    background-color: ${({ theme }) => theme.colors.ghost};
    & > :last-child {
      opacity: 1;
    }
  }

  & > * {
    color: ${({ theme }) => theme.colors.gunmetal};
  }

  & > :first-child {
    color: rgba(0, 0, 0, 0.45);
  }

  & > :nth-child(2) {
    flex: auto;
    margin: 0;
    padding: 0 8px;
  }

  & > :last-child {
    opacity: 0;
    color: rgba(0, 0, 0, 0.45);
    padding-right: 4px;
  }

  & > :last-child:hover {
    display: block;
    color: ${({ theme }) => theme.colors.gunmetal};
  }
`;
