import { Tag } from 'antd';
import styled from 'styled-components';

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    flex-direction: row;
  }
`;

export const NameWrapper = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.gunmetal};
`;

export const SubtitleWrapper = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gunmetal};
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

export const StyledTag = styled(Tag)`
  padding: 2px 8px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.beau};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    min-width: 516px;
    padding: 24px;
  }
`;

export const WidgetsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;

  & > div {
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.cadet};
`;
