import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const PostService = sdk.getService('PostService');

export const usePostDetail = (slug?: string) => {
  const queryKey = ['posts', slug];

  const { isPending, data: post } = useQuery({
    queryKey,
    queryFn: () => PostService.findOne(slug!),
    enabled: !!slug
  });

  return { isPending, post };
};
