import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-picker-content > thead > tr > th {
    color: ${({ theme }) => theme.colors.gunmetal};
    width: 15px !important;
  }

  .ant-picker-cell {
    position: relative;
    & > :first-child {
      width: 15px !important;
      height: 15px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      & > :nth-child(2) {
        position: absolute;
        bottom: 2px;
        left: 2px;
        sup {
          background: ${({ theme }) => theme.colors.cadet};
        }

        @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
          bottom: 4px;
          left: 12px;
        }
      }

      @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        width: 36px !important;
        height: 36px !important;
      }
    }
  }

  .ant-picker-cell-selected {
    border: none;
    color: ${({ theme }) => theme.colors.white};
    & > :first-child {
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.gunmetal};
    }
  }

  & > :first-child {
    padding: 16px 20px;
    box-shadow: ${({ theme }) => theme.boxShadow.primary};
  }

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  i {
    cursor: pointer;
    font-size: 22px;
  }
  margin-bottom: 22px;

  & > :nth-child(2) {
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
  }
`;

export const EventListWrapper = styled.div`
  max-height: 25vh;
  overflow: scroll;
`;
