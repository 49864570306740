import {
  ICreateNote,
  IRemoveNote,
  IUpdateNote,
  IUpdateActivityTrack,
  IUpdateSectionTrack
} from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const ActivityTrackingService = sdk.getService('ActivityTrackingService');
const SectionTrackingService = sdk.getService('SectionTrackingService');
const ActivityNoteService = sdk.getService('ActivityNoteService');
const SubscriptionService = sdk.getService('SubscriptionService');

export const useMutateMySubscriptions = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['subscriptions'] });
  const { t } = useTranslation('StudentCourseTranslations');

  const { mutate: updateActivityTrack, isPending: isUpdatingActivityTrack } = useMutation({
    mutationFn: (update: IUpdateActivityTrack) =>
      ActivityTrackingService.updateActivityTrack(update),
    onSuccess
  });

  const { mutateAsync: toggleSubscriptionVisibility, isPending: isToggling } = useMutation({
    mutationFn: (subscriptionId: string) =>
      SubscriptionService.toggleSubscriptionVisibility(subscriptionId),
    onSuccess
  });

  const { mutate: createNote, isPending: isCreatingNote } = useMutation({
    mutationFn: (note: ICreateNote) => ActivityNoteService.create(note),
    onSuccess: () => {
      onSuccess();
      message.success(t('NOTE_CREATED'));
    },
    onError: () => message.error(t('NOTE_ERROR'))
  });

  const { mutate: updateNote, isPending: isUpdatingNote } = useMutation({
    mutationFn: (note: IUpdateNote) => ActivityNoteService.update(note),
    onSuccess: () => {
      onSuccess();
      message.success(t('NOTE_UPDATED'));
    },
    onError: () => message.error(t('NOTE_ERROR'))
  });

  const { mutate: removeNote, isPending: isRemovingNote } = useMutation({
    mutationFn: (note: IRemoveNote) => ActivityNoteService.remove(note),
    onSuccess: () => {
      onSuccess();
      message.warning(t('NOTE_REMOVED'));
    },
    onError: () => message.error(t('NOTE_ERROR'))
  });

  const { mutate: updateSectionTrack, isPending: isUpdatingSectionTrack } = useMutation({
    mutationFn: (update: IUpdateSectionTrack) => SectionTrackingService.updateSectionTrack(update),
    onSuccess
  });

  return {
    updateActivityTrack,
    toggleSubscriptionVisibility,
    createNote,
    updateNote,
    removeNote,
    updateSectionTrack,
    isWorking:
      isUpdatingActivityTrack ||
      isToggling ||
      isCreatingNote ||
      isUpdatingNote ||
      isRemovingNote ||
      isUpdatingSectionTrack
  };
};
