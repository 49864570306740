import { ICreatePost, IPost } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const PostService = sdk.getService('PostService');

export const useMutatePost = () => {
  const { t } = useTranslation('CommonPostTranslations');
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['posts'] });

  const { isPending: isCreating, mutate: createPost } = useMutation({
    mutationFn: (data: ICreatePost) => PostService.createPost(data),
    onSuccess: () => {
      onSuccess();
      message.success(t('POST_CREATED_SUCCESSFULLY'));
    }
  });

  const { mutate: updatePost, isPending: isUpdating } = useMutation({
    mutationFn: (data: Partial<IPost>) => PostService.updatePost(data),
    onSuccess: () => {
      onSuccess();
      message.success(t('POST_UPDATED_SUCCESSFULLY'));
    }
  });

  const { mutate: removePost, isPending: isDeleting } = useMutation({
    mutationFn: (postId: string) => PostService.remove(postId),
    onSuccess: () => {
      onSuccess();
      message.success(t('POST_DELETED_SUCCESSFULLY'));
    }
  });

  return { isPending: isCreating || isUpdating || isDeleting, createPost, updatePost, removePost };
};
