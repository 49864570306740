import styled from 'styled-components';

export const Wrapper = styled.div<{ showtabs: boolean }>`
  .ant-tabs-nav {
    display: ${({ showtabs }) => (showtabs ? 'flex' : 'none')};
  }
  .ant-tabs-tab-active {
    font-weight: 600;
  }

  .ant-tabs-ink-bar {
    height: 5px !important;
    background: ${({ theme }) => theme.colors.meadreg};
  }

  overflow: auto;
  max-height: 720px;
  height: 100%;
`;
