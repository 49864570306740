import styled from 'styled-components';

export const PostWrapper = styled.div`
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  cursor: pointer;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.solitude};
`;

export const StyledImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 4px;
  flex: none;
  object-fit: cover;
`;

export const InfoWrapper = styled.div`
  display: flex;
  max-height: 108px;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: hidden;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.marrow};
  font-weight: 600;
  font-size: 12px;
`;

export const TitleWrapper = styled.div`
  font-weight: 600;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.2em;
  max-height: 2.4em;
  text-overflow: ellipsis;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.2em;
  max-height: 2.4em;
  text-overflow: ellipsis;
  & > p {
    margin: 0;
  }
`;
