import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.cadet};
  background: ${({ theme }) => theme.colors.white};
`;

export const TicketHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
  }
`;

export const HeaderInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const CloseFormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  & > :first-child {
    color: ${({ theme }) => theme.colors.charcoal};
  }
`;

export const LinkWrapper = styled.div`
  color: ${({ theme }) => theme.colors.link};
  cursor: pointer;
  font-weight: 600;
`;

export const GeneratingAnswerTextWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 460px;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  justify-content: center;
  text-align: center;
`;

export const DetailTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`;

export const LottieWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;
