import styled from 'styled-components';

export const Wrapper = styled.div<{ isinchat: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  & > :first-child {
    color: ${({ theme }) => theme.colors.meadreg};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  ${({ isinchat, theme }) =>
    isinchat &&
    `& > :nth-child(3) {
    color: ${theme.colors.meadreg};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }`}
`;
