import { Avatar, Collapse } from 'antd';
import styled from 'styled-components';

import { Icon } from '@/components/Icon';

type IconProps = {
  isActive: boolean;
};

export const DetailTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`;

export const StyledCollapse = styled(Collapse)<{ isExpanded: boolean; isNewTicket: boolean }>`
  display: flex;
  padding: 16px 16px 16px 8px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  background-color: ${({ theme, isNewTicket }) =>
    isNewTicket ? theme.colors.meadwhite : theme.colors.white};
  border-radius: 4px;
  border-bottom: ${({ theme, isExpanded }) =>
    isExpanded ? `1px solid ${theme.colors.cadet}` : 'unset'};
  .ant-collapse-item {
    width: 100%;
    border-bottom: unset;
    .ant-collapse-content {
      border-top: 0px;

      .ant-collapse-content-box {
      }
    }
    .ant-collapse-header {
      padding: 0px;
      .ant-collapse-arrow {
        font-size: 16px;
      }
    }
  }
`;

export const StyledIcon = styled(Icon)<IconProps>`
  rotate: ${({ isActive }) => (isActive ? '90deg' : '0deg')};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;

export const TicketDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`;

export const DetailSubtitle = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
  }
`;

export const ActivityLinkWrapper = styled.div`
  color: ${({ theme }) => theme.colors.meadreg};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 2em;
  white-space: nowrap;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    max-width: 250px;
  }
`;

export const StyledAvatar = styled(Avatar)`
  flex: none;
`;

export const FileWrapper = styled.a`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.cadet};
  color: ${({ theme }) => theme.colors.gunmetal};
  border-radius: 2px;
  padding: 12px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  justify-content: flex-start;

  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    width: 545px;
    margin-left: 50px;
  }
`;

export const IconWrapper = styled(Icon)`
  background: ${({ theme }) => theme.colors.neoghost};
  color: ${({ theme }) => theme.colors.neoreg};
  border-radius: 2px;
  padding: 4px;
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

export const NameWrapper = styled.div`
  white-space: nowrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.marrow};
`;
