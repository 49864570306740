import styled from 'styled-components';

export const Wrapper = styled.div<{ 'is-pointer': boolean }>`
  display: flex;
  padding: 16px;
  gap: 16px;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.solitude};
  border-radius: 4px;
  cursor: ${({ 'is-pointer': isPointer }) => (isPointer ? 'pointer' : 'default')};

  .ant-form-item {
    width: 100% !important;
    margin-bottom: 16px !important;
  }
`;

export const TitleWrapper = styled.span`
  font-weight: 600;
`;

export const CreatedByWrapper = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const MandatoryWrapper = styled.span`
  color: ${({ theme }) => theme.colors.marrow};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;
