import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-alert-message {
    font-weight: 700;
  }
`;

export const SectionsWrapper = styled.div`
  flex-grow: 1;

  .ant-empty {
    margin-top: 20px;
  }
`;

export const Banner = styled.span`
  color: ${({ theme }) => theme.colors.white};
  display: inline-block;
  width: 25px;
  border-radius: 8px;
  text-align: center;
  height: 25px;
  background-color: ${({ theme }) => theme.colors.gunmetal};
`;

export const SectionWrapper = styled.div`
  display: grid;
  gap: 20px;
  padding: 20px;
  grid-template-columns: 1fr 200px 1fr;
  border-bottom: 1px solid ${({ theme }) => theme.colors.beau};
  & > :first-child {
    cursor: pointer;
    user-select: none;
    margin: auto 0;
    font-weight: 600;
    & > span {
      margin-right: 8px;
    }
  }

  & > :nth-child(2) {
    font-weight: 300;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.beau};
    margin: auto 0;
  }
`;
