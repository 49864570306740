import styled from 'styled-components';

export const Wrapper = styled.div<{ rating: number }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1px;

  & > .anticon,
  & > .anticon:hover ~ .anticon {
    color: ${({ theme }) => theme.colors.gunmetal};
    text-shadow: none;
    transition: color 200ms, text-shadow 200ms;
    transition-delay: 0;
  }

  & > .anticon.active {
    color: red;
  }

  &:hover .anticon,
  & > .anticon.active {
    color: ${({ theme }) => theme.colors.meadreg};
    fill: ${({ theme }) => theme.colors.meadreg};
  }

  &:hover .anticon:nth-child(2) {
    transition-delay: 30ms;
  }

  &:hover .anticon:nth-child(3) {
    transition-delay: 60ms;
  }

  &:hover .anticon:nth-child(4) {
    transition-delay: 90ms;
  }

  &:hover .anticon:nth-child(5) {
    transition-delay: 120ms;
  }
`;
