import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  .ant-avatar {
    margin: 0 auto 20px auto;
    .ant-avatar-string {
      line-height: 160px !important;
    }
    i {
      font-size: 50px;
    }
  }
`;

export const Item = styled.div<{ bordered?: boolean }>`
  display: flex;
  padding: 20px 88px;
  align-items: center;
  font-size: 13px;
  gap: 8px;
  & > .anticon {
    align-self: flex-start;
    margin-top: 3px;
  }
  & > :nth-child(2) {
    & > :nth-child(1) {
      font-size: 14px;
      font-weight: 600;
    }
  }
  border-bottom: ${({ bordered, theme }) =>
    bordered ? `1px solid ${theme.colors.solitude}` : 'none'};
`;
