import { IPaginatedResponse, IPagination } from '@sdk/contracts';
import { createQueryString } from '@utils';

import { RequestService } from '../request';

import { ICreatePost, IPost } from './post.contract';

export class PostService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async getPosts(pagination: IPagination) {
    const { page, limit } = pagination;
    const query = createQueryString({ page, limit });
    return this.fetcher<IPaginatedResponse<IPost>>(`/blog/posts/paginated?${query}`);
  }

  async createPost(data: ICreatePost) {
    return this.poster<IPost, ICreatePost>('/blog/posts', data);
  }

  async updatePost(data: Partial<IPost>) {
    return this.putter<IPost, Partial<IPost>>(`/blog/posts/${data._id}`, data);
  }

  async findOne(slug: string) {
    return this.fetcher<IPost>(`/blog/posts/${slug}`);
  }

  async remove(postId: string) {
    return this.deleter(`/blog/posts/${postId}`);
  }
}
