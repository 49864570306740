import { EAutonomousCommunity, EVerticalNames } from '@constants';
import { IExamDate, IUserOnboarding } from '@sdk/contracts';
import { Drawer, Form, message } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import { ChosenReason } from './components/ChosenReason';
import { ContractSign } from './components/ContractSign';
import { DiscoverySource } from './components/DiscoverySource';
import { DoYouKnowOtherPeople } from './components/DoYouKnowOtherPeople';
import { LoadingPlatform } from './components/LoadingPlatform';
import { NotificationSettings } from './components/NotificationSettings';
import { StudentInfo } from './components/StudentInfo';
import { StudySteps } from './components/StudySteps';
import { Welcome } from './components/Welcome';
import {
  CurvedArrowVector,
  ButtonWrapper,
  ContentWrapper,
  StyledButton,
  Wrapper,
  StraightRightArrowVector,
  StraightLeftArrowVector,
  StyledForm,
  StyledUmyImg1,
  StyledUmyImg2,
  StepperWrapper,
  SlidesWrapper,
  SlideWrapper
} from './styles';

import CURVED_ARROW_VECTOR from '@/assets/purple_curved_arrow_vector.svg';
import STRAIGHT_ARROW_LEFT_VECTOR from '@/assets/purple_straight_left_arrow_vector.svg';
import STRAIGHT_ARROW_RIGHT_VECTOR from '@/assets/purple_straight_right_arrow_vector.svg';
import UMY_ONBOARDING_MARKETING from '@/assets/umy_bullet_point_summary.svg';
import UMY_ONBOARDING_OBJECTIVES from '@/assets/umy_onboarding_objectives.svg';
import { Icon } from '@/components/Icon';
import { Stepper } from '@/components/Stepper';
import { useIsMobile } from '@/hooks';
import { useProfile, useMutateHelloSignContract, useMutateProfile } from '@/store';

interface FormValues {
  name: string;
  lastName: string;
  legalId?: string;
  birthday?: Date;
  examDate?: IExamDate;
  autonomousCommunities?: EAutonomousCommunity[];
  onBoarding?: IUserOnboarding;
}

const NOTIFICATION_VERTICALS = [EVerticalNames.OPOS, EVerticalNames.UNI];

// TODO Cambiar la lógica que los steps, es confusa
export const OnboardingPage = () => {
  const { t } = useTranslation('StudentCourseSummaryTranslations');
  const { signatureReqId } = useMutateHelloSignContract();
  const { profile, isOnboardingCompleted } = useProfile();
  const { updateProfile } = useMutateProfile();
  const [form] = Form.useForm();
  const [step, setStep] = useState(0);
  const [isPlatformReady, setIsPlatformReady] = useState(true);
  const [isContractSigned, setIsContractSigned] = useState(false);
  const navigateTo = useNavigate();
  const isMobile = useIsMobile('sm');

  const legalId = Form.useWatch('legalId', form);
  const birthday = Form.useWatch('birthday', form);

  const isFirstFormCompleted = legalId && birthday;

  const hasFinishedOnboarding = step === 4 && isContractSigned && !isMobile;

  if (!profile) return null;
  if (isOnboardingCompleted) return <Navigate to="/dashboard" replace={true} />;

  const handleNextStep = () => {
    switch (step) {
      case 2:
      case 3:
      case 4:
      case 6:
        form.submit();
        setStep(prevIndex => (prevIndex + 1) % slides.length);
        break;
      case 5:
        form.submit();
        if (!profile.tags.some(tag => NOTIFICATION_VERTICALS.includes(tag.vertical))) {
          setStep(prevIndex => (prevIndex + 2) % slides.length);
          break;
        }
        setStep(prevIndex => (prevIndex + 1) % slides.length);
        break;
      case 8:
        handleUpdateContract();
        break;

      default:
        setStep(prevIndex => (prevIndex + 1) % slides.length);
    }
  };

  const handleUpdateProfile = async (values: Partial<FormValues>) => {
    updateProfile({
      ...values,
      notificationSettings: {
        examGroupNotificationSettings: {
          isActive: true,
          examGroupNotifications: profile.tags.map(tag => ({
            tag: tag._id,
            autonomousCommunities: values.autonomousCommunities ?? []
          }))
        }
      }
    });
  };

  const handleUpdateContract = () =>
    updateProfile(
      { contract: { signatureRequestId: signatureReqId } },
      {
        onSuccess: () => {
          message.success(t('WELCOME_MESSAGE'));
          navigateTo('/dashboard');
        }
      }
    );

  const getButtonText = (onboardingStep: number) => {
    switch (onboardingStep) {
      case 0:
        return t('START');
      case 8:
        return t('START_NOW');
      default:
        return t('CONTINUE');
    }
  };

  const isButtonDisabled =
    !isPlatformReady || (step === 8 && !isContractSigned) || (step === 2 && !isFirstFormCompleted);

  const slides = [
    { key: 0, content: <Welcome /> },
    { key: 1, content: <StudySteps /> },
    { key: 2, content: <StudentInfo /> },
    { key: 3, content: <DiscoverySource handleNext={handleNextStep} /> },
    { key: 4, content: <ChosenReason handleNext={handleNextStep} /> },
    { key: 5, content: <DoYouKnowOtherPeople handleNext={handleNextStep} /> },
    { key: 6, content: <NotificationSettings /> },
    { key: 7, content: <LoadingPlatform step={step} setIsPlatformReady={setIsPlatformReady} /> },
    {
      key: 8,
      content: (
        <ContractSign
          step={step}
          isContractSigned={isContractSigned}
          setIsContractSigned={setIsContractSigned}
        />
      )
    }
  ];

  return (
    <Drawer styles={{ body: { padding: 0 } }} closable={false} open footer={null} width="100%">
      <Wrapper>
        <StyledForm
          form={form}
          layout="vertical"
          onFinish={handleUpdateProfile}
          initialValues={{ ...profile, birthday: profile?.birthday && dayjs(profile.birthday) }}>
          <ContentWrapper>
            <SlidesWrapper style={{ transform: `translateX(-${step * 100}%)` }}>
              {slides.map(slide => (
                <SlideWrapper key={slide.key}>{slide.content}</SlideWrapper>
              ))}
            </SlidesWrapper>
            <ButtonWrapper step={step}>
              {hasFinishedOnboarding && (
                <>
                  <CurvedArrowVector src={CURVED_ARROW_VECTOR} alt="Purple curved arrow vector" />
                  <StraightRightArrowVector
                    src={STRAIGHT_ARROW_RIGHT_VECTOR}
                    alt="Purple arrow vector"
                  />
                </>
              )}
              {step > 0 && step < 7 && (
                <StepperWrapper>
                  <Stepper steps={slides.length} step={step} />
                </StepperWrapper>
              )}
              {(step < 3 || step > 5) && (
                <StyledButton
                  icon={<Icon i="ArrowRight" />}
                  type="primary"
                  onClick={handleNextStep}
                  disabled={isButtonDisabled}
                  step={step}>
                  {getButtonText(step)}
                </StyledButton>
              )}
              {hasFinishedOnboarding && (
                <StraightLeftArrowVector
                  src={STRAIGHT_ARROW_LEFT_VECTOR}
                  alt="Purple straight arrow vector"
                />
              )}
            </ButtonWrapper>
            {!isMobile && (
              <>
                <StyledUmyImg1
                  className={step === 1 ? 'umy-image' : ''}
                  src={UMY_ONBOARDING_OBJECTIVES}
                  alt="Umy Onboarding Objectives"
                />
                <StyledUmyImg2
                  className={step === 3 || step === 4 ? 'umy-image' : ''}
                  src={UMY_ONBOARDING_MARKETING}
                  alt="Umy Onboarding Objectives"
                />
              </>
            )}
          </ContentWrapper>
        </StyledForm>
      </Wrapper>
    </Drawer>
  );
};
