import styled from 'styled-components';

import { WidgetWrapper } from '@/components/Event';
import { Icon } from '@/components/Icon';

export const Wrapper = styled(WidgetWrapper)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const TitleWrapper = styled.div`
  font-weight: 600;
  margin-bottom: 5px;
`;

export const LinkWrapper = styled.div`
  font-weight: 600;
`;

export const IconWrapper = styled(Icon)`
  margin-right: 4px;
`;

export const MaterialItem = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cadet};
  margin-bottom: 8px;

  & > :last-child > button {
    & i {
      margin: auto;
    }
    :hover {
      color: ${({ theme }) => theme.colors.error};
      background-color: rgba(227, 96, 88, 0.2);
    }
  }
`;
