import styled from 'styled-components';

// TODO: style in global config
export const InputWrapper = styled.div`
  .ant-input {
    :hover {
      border-color: ${({ theme }) => theme.colors.meadlight};
    }
    :focus {
      border-color: ${({ theme }) => theme.colors.meadlight};
      box-shadow: unset;
    }
  }
`;

export const FormTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
