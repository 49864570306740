export const CommonPostTranslations = {
  RETURN: {
    es: 'Volver al inicio',
    en: 'Return to home',
    cat: "Tornar a l'inici",
    eus: 'Itzuli hasierara'
  },
  EDIT: {
    es: 'Editar',
    en: 'Edit',
    cat: 'Editar',
    eus: 'Editatu'
  },
  DELETE: {
    es: 'Eliminar',
    en: 'Delete',
    cat: 'Eliminar',
    eus: 'Ezabatu'
  },
  POST_CREATED_SUCCESSFULLY: {
    es: 'Post creado correctamente',
    en: 'Post created successfully',
    cat: 'Post creat correctament',
    eus: 'Posta behar bezala sortua'
  },
  POST_UPDATED_SUCCESSFULLY: {
    es: 'Post actualizado correctamente',
    en: 'Post updated successfully',
    cat: 'Post actualitzat correctament',
    eus: 'Posta behar bezala eguneratua'
  },
  POST_DELETED_SUCCESSFULLY: {
    es: 'Post eliminado correctamente',
    en: 'Post deleted successfully',
    cat: 'Post eliminat correctament',
    eus: 'Posta behar bezala ezabatua'
  },
  TOPIC_CREATED_SUCCESSFULLY: {
    es: 'Tema creado correctamente',
    en: 'Topic created successfully',
    cat: 'Tema creat correctament',
    eus: 'Gai behar bezala sortua'
  },
  CATEGORY_CREATED_SUCCESSFULLY: {
    es: 'Categoría creada correctamente',
    en: 'Category created successfully',
    cat: 'Categoria creada correctament',
    eus: 'Kategoria behar bezala sortua'
  },
  TOPIC_REMOVED_SUCCESSFULLY: {
    es: 'Tema eliminado correctamente',
    en: 'Topic removed successfully',
    cat: 'Tema eliminat correctament',
    eus: 'Gai behar bezala ezabatua'
  },
  CATEGORY_REMOVED_SUCCESSFULLY: {
    es: 'Categoría eliminada correctamente',
    en: 'Category removed successfully',
    cat: 'Categoria eliminada correctament',
    eus: 'Kategoria behar bezala ezabatua'
  }
};
