import styled from 'styled-components';

export const StepButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface StepButtonProps {
  selected?: boolean;
}

export const StyledUl = styled.ul`
  display: flex;
`;

export const StyledLi = styled.li<StepButtonProps>`
  margin: 0 8px;
  cursor: pointer;
  transition: all 0.3s linear;

  &::marker {
    font-size: 20px;
    color: ${({ theme, selected }) => (selected ? theme.colors.neonblue : theme.colors.beau)};
  }
`;
