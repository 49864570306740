import styled from 'styled-components';

export const PointsWrapper = styled.div<{ isdisabled: boolean }>`
  height: 100%;
  position: absolute;
  padding-right: 50px;
  padding-left: 8px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  color: ${({ theme, isdisabled }) => (isdisabled ? theme.colors.marrow : theme.colors.charcoal)};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ theme, isdisabled }) => (isdisabled ? theme.colors.marrow : theme.colors.warning)};
  z-index: 0;
`;

export const Wrapper = styled.div`
  width: 80%;
  position: relative;
  margin-left: 50px;

  & > :last-child {
    z-index: 1;
  }
`;
