import styled from 'styled-components';

import { Icon } from '@/components/Icon';

export const Wrapper = styled.div`
  div i {
    font-size: 14px;
  }
`;

export const GenerateVideoCheckPointWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const GenerateVideoCheckPointTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const AddQuestion = styled.div`
  display: flex;
  align-items: baseline;
`;

export const GenerateVideoCheckPointIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.meadreg};
`;

export const RemoveIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.error};
  font-size: 14px;
`;
