import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    width: 50%;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const PointsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.warning};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.2px;
`;

export const DescriptionWrapper = styled.span`
  color: ${({ theme }) => theme.colors.gunmetal};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const DividerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ant-divider-horizontal {
    margin: 0 0 8px 0;
  }
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.meadghost};
  border-radius: 100px;
  overflow: hidden;
  position: relative;
`;

export const ProgressBarFiller = styled.div<{ percentage: number }>`
  height: 100%;
  width: ${({ percentage }) => percentage}%;
  background-color: ${({ theme }) => theme.colors.meadreg};
  border-radius: 100px;
  transition: width 0.2s ease-in;
`;

export const ProgressBarFillerDetail = styled.div<{ percentage: number }>`
  position: absolute;
  left: 6px;
  top: 3px;
  width: ${({ percentage }) => percentage - 3}%;
  height: 4px;
  border-radius: 100px;
  opacity: 0.5;
  background-color: ${({ theme }) => theme.colors.meadghost};
`;

export const ProgressBarLabel = styled.span`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.meadberseker};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`;
