import { AnalyticsTrackEvents } from '@constants';
import { Button } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import {
  ActivityNameWrapper,
  AddonTextWrapper,
  AddonWrapper,
  ButtonsWrapper,
  ColumnWrapper,
  LoadingWrapper,
  NextClassWrapper,
  StyledImage,
  Wrapper
} from './StudentContentWidget.styles';
import { SubscriptionSelectionModal } from './SubscriptionSelectionModal';

import UMY_DASHBOARD from '@/assets/umy_dashboard.svg';
import { LoadingCircleSpinner } from '@/components/Loaders';
import { WidgetBase } from '@/components/WidgetBase';
import { useIsMobile, useMixpanel } from '@/hooks';
import { useActivityTrack, useMySubscriptions } from '@/store';
import { WidgetProps } from '@/types';

type Props = WidgetProps;

export const StudentContentWidget: React.FC<Props> = props => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const { selectedSubscription, isLoading, setIsOpen } = useMySubscriptions();
  const { getCurrentActivity } = useActivityTrack();
  const navigateTo = useNavigate();
  const { fireEvent } = useMixpanel();
  const isMobile = useIsMobile('xs');
  const { colors } = useTheme();

  const isFirstActivity = useMemo(() => {
    if (!selectedSubscription) return false;
    const { activity } = getCurrentActivity(selectedSubscription);
    return activity?._id === selectedSubscription.course.sections?.[0]?.activities?.[0]?._id;
  }, [selectedSubscription]);

  if (!selectedSubscription || isLoading)
    return (
      <WidgetBase title={t('CLASSES_AND_SYLLABUS')} backgroundcolor={colors.meadwhite} {...props}>
        <Wrapper>
          <LoadingWrapper>
            <LoadingCircleSpinner />
          </LoadingWrapper>
        </Wrapper>
      </WidgetBase>
    );

  const { activity, section } = getCurrentActivity(selectedSubscription);

  const {
    _id,
    course: { name }
  } = selectedSubscription;

  const handleNavigateToSummary = () => {
    navigateTo(`/subscription/summary`);
    fireEvent(AnalyticsTrackEvents.QUICK_ACCESS_DASHBOARD, {
      courseName: name
    });
  };

  const handleChangeCourse = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const onClick = () => {
    if (!activity) return;
    navigateTo(`/subscription/${_id}?activity=${activity._id}`);
    fireEvent(AnalyticsTrackEvents.QUICK_ACCESS_DASHBOARD, {
      courseName: name,
      activityName: activity.name
    });
  };

  return (
    <WidgetBase
      title={name}
      {...props}
      backgroundcolor={colors.meadwhite}
      onHeaderClick={handleNavigateToSummary}
      hoverable
      addon={
        <AddonWrapper onClick={handleChangeCourse}>
          <AddonTextWrapper className="addon-text">{t('CHANGE_COURSE')}</AddonTextWrapper>
        </AddonWrapper>
      }>
      <Wrapper>
        {isLoading ? (
          <LoadingCircleSpinner />
        ) : (
          <>
            {!isMobile && <StyledImage src={UMY_DASHBOARD} alt="Umy Dashboard" />}
            <ColumnWrapper>
              <NextClassWrapper>
                {t(isFirstActivity ? 'ADVENTURE_STARTS_HERE' : 'CONTINUE_YOUR_STUDY')}
              </NextClassWrapper>
              <ActivityNameWrapper>{`${section?.name} > ${activity?.name}`}</ActivityNameWrapper>
              <ButtonsWrapper>
                <Button onClick={onClick} type="primary">
                  {t(isFirstActivity ? 'START' : 'CONTINUE')}
                </Button>
                <Button onClick={handleNavigateToSummary}>{t('GO_TO_CONTENT')}</Button>
              </ButtonsWrapper>
            </ColumnWrapper>
          </>
        )}
      </Wrapper>
      <SubscriptionSelectionModal />
    </WidgetBase>
  );
};
