import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 180px;
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    margin: 0;
  }
`;

export const Description = styled.div`
  display: flex;
`;

export const CardContent = styled.div`
  margin-top: 24px;
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 32px;
  }
`;

export const RelatedContent = styled.div`
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    width: 75%;
  }
`;

export const QuestionCard = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.beau};

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    position: relative;
    width: 25%;
    background-color: transparent;
    border: none;
    padding: 0;
  }
`;

export const WrongQuestions = styled.div`
  margin-top: 8px;
`;

export const QuestionText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.normal};
  text-align: center;
  padding-bottom: 20px;
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    border-radius: 8px;
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.solitude};
    margin-bottom: 32px;
  }
`;

export const QuestionCount = styled.span`
  color: ${({ theme }) => theme.colors.neoreg};
  font-size: ${({ theme }) => theme.fontSize.extraLarge};
  vertical-align: sub;
`;
