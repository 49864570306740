import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  .ant-tabs-tab-active {
    font-weight: 600;
  }

  .ant-tabs-ink-bar {
    height: 5px !important;
    background: ${({ theme }) => theme.colors.meadreg};
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > :first-child {
    display: flex;
    align-items: center;
    gap: 8px;

    & > :last-child {
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
`;
