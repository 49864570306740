import { Button, Divider, Input, Select } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddItemWrapper, OptionWrapper } from './CategorySelector.styles';

import { Icon } from '@/components/Icon';
import { useMutatePostCategory, usePostCategoriesList } from '@/store';

type Props = {
  value?: string;
  onChange?: (value: string) => void;
};

export const CategorySelector: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const { postCategories, isPending } = usePostCategoriesList();
  const { createPostCategory, isPending: isWorking, removePostCategory } = useMutatePostCategory();
  const [newCategory, setNewCategory] = useState('');

  const options = useMemo(
    () => postCategories?.map(category => ({ label: category.name, value: category._id })),
    [postCategories]
  );

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setNewCategory(e.target.value);

  const handleAddCategory = () => {
    if (!newCategory) return;
    createPostCategory(
      { name: newCategory },
      {
        onSuccess: () => setNewCategory('')
      }
    );
  };

  const handleRemoveCategory = (categoryId?: string) => {
    if (!categoryId) return;
    removePostCategory(categoryId);
  };

  return (
    <Select
      loading={isPending}
      options={options}
      value={value}
      mode="tags"
      onChange={onChange}
      placeholder={t('SELECT_CATEGORY')}
      optionRender={option => (
        <OptionWrapper>
          <div>{option.label}</div>
          <Button
            loading={isWorking}
            danger
            size="small"
            type="link"
            icon={<Icon i="TrashCan" />}
            onClick={e => {
              e.stopPropagation();
              handleRemoveCategory(option.value as string);
            }}
          />
        </OptionWrapper>
      )}
      dropdownRender={menu => (
        <>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <AddItemWrapper>
            <Input
              disabled={isWorking}
              placeholder={t('NEW_CATEGORY')}
              onChange={onNameChange}
              value={newCategory}
              onKeyDown={e => e.stopPropagation()}
            />
            <Button
              onClick={handleAddCategory}
              loading={isWorking}
              icon={<Icon i="AddAlt" />}
              type="primary">
              {t('ADD')}
            </Button>
          </AddItemWrapper>
        </>
      )}
    />
  );
};
