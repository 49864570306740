import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  .ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected {
    & > .ant-table-cell {
      background-color: ${({ theme }) => theme.colors.meadghost} !important;
    }
  }
`;
