import { useFlagsStatus } from '@unleash/proxy-client-react';
import { lazy } from 'react';
import { useLocation } from 'react-router-dom';

import { FullPageLoader } from './components/Loaders';

import { useSentry, useFeatureFlag, useHubspot, useMixpanel } from '@/hooks';
import { useProfile } from '@/store';

const AppView = lazy(() => import('@/views').then(module => ({ default: module.AppView })));
const PublicView = lazy(() => import('@/views').then(module => ({ default: module.PublicView })));
const MaintenanceView = lazy(() =>
  import('@/views').then(module => ({ default: module.MaintenanceView }))
);

export const App = () => {
  const location = useLocation();
  const {
    profile,
    isLoading,
    roleCheck: { isStudent, isTeacher }
  } = useProfile();

  const { init: initSentry } = useSentry();
  const { init: initMixpanel } = useMixpanel();
  const { init: initHubspot } = useHubspot();

  const { flagsReady } = useFlagsStatus();
  const isMaintenanceMode = useFeatureFlag('MAINTENANCE_MODE');

  if (isLoading || !flagsReady) return <FullPageLoader />;

  if (profile) {
    initSentry(profile);
    initHubspot(profile);
    initMixpanel(profile, location.state?.utm);
    if (isMaintenanceMode && (isStudent || isTeacher)) return <MaintenanceView />;
    return <AppView />;
  }

  return <PublicView />;
};

export default App;
