import { ISubscription } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useMemo } from 'react';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const SubscriptionService = sdk.getService('SubscriptionService');

interface IStore {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedSubscription?: ISubscription;
  setSelectedSubscription: (subscriptionSelected?: ISubscription) => void;
}

const store = createStore<IStore>(set => ({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => set({ isOpen }),
  setSelectedSubscription: (selectedSubscription?: ISubscription) => {
    set({ selectedSubscription });
    if (selectedSubscription) {
      Cookies.set('selectedSubscriptionId', selectedSubscription._id, { expires: 365 });
    }
  }
}));

export const useMySubscriptions = () => {
  const { selectedSubscription, setSelectedSubscription, isOpen, setIsOpen } = store();

  const { isLoading, data, error } = useQuery({
    queryKey: ['subscriptions'],
    queryFn: () => SubscriptionService.getMySubscriptions(),
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  const filteredSubscriptions = useMemo(() => data?.filter(s => !s.isHidden), [data]);

  return {
    isOpen,
    setIsOpen,
    error,
    isLoading,
    subscriptions: data,
    selectedSubscription,
    filteredSubscriptions,
    setSelectedSubscription
  };
};
