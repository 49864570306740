import styled, { css } from 'styled-components';

const HEADER_SIZE = {
  small: '16px',
  medium: '24px',
  large: '28px'
};

export const Wrapper = styled.div<{
  headersize: keyof typeof HEADER_SIZE;
  backgroundcolor?: string;
  hoverable?: boolean;
  special?: boolean;
}>`
  width: 100%;
  padding: 18px;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${({ theme, backgroundcolor }) => backgroundcolor ?? theme.colors.white};
  box-sizing: border-box;
  transition: outline-width 100ms;

  ${({ special }) =>
    special &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.neonblue};
      background-color: ${({ theme }) => theme.colors.neowhite};
    `}

  :hover {
    outline: ${({ hoverable, theme }) =>
      hoverable ? `2px solid ${theme.colors.meadreg}` : 'none'};
  }

  .ant-divider-horizontal {
    margin: 0 0 16px 0;
  }

  & > div:first-of-type {
    font-family: ${({ theme }) => theme.fontFamily.primary};
    font-style: normal;
    font-weight: 600;
    font-size: ${({ headersize }) => HEADER_SIZE[headersize]};
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    :hover {
      & > div:first-of-type {
        color: ${({ theme }) => theme.colors.meadreg};
      }
      .addon-icon {
        transform: scaleX(1.2) scaleY(1.1);
        color: ${({ theme }) => theme.colors.meadreg};
      }
      .addon-text {
        color: ${({ theme }) => theme.colors.meadreg};
      }
    }
  }
  & > section {
    height: 100%;
    :hover {
      .activity-button {
        background-color: ${({ theme }) => theme.colors.meadreg};
        border: 1px solid ${({ theme }) => theme.colors.meadreg};
      }
    }
  }

  .subtitle {
    font-size: 12px;
  }
`;
