import styled from 'styled-components';

export const SubscriptionSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  color: ${({ theme }) => theme.colors.gunmetal};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const SubscriptionWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 12px;
  padding-bottom: 20px;
  flex-wrap: wrap;
  align-self: stretch;
`;
