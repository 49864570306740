import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  padding-bottom: 60px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 600px;
`;
