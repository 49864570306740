import styled from 'styled-components';

export const EventWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 15px;
  border-radius: 5px;
  gap: 21px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.solitude};
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 46px;
  height: 46px;
  background-color: ${({ theme }) => theme.colors.solitude};
  text-transform: uppercase;

  & > :first-child {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.01em;
  }

  & > :last-child {
    font-size: 12px;
    font-weight: 400;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  user-select: none;
  & > :first-child {
    font-size: 16px;
  }

  & > :last-child {
    font-size: 14px;

    & > :first-child {
      display: flex;
      align-items: center;

      & > :first-child {
        margin-right: 5px;
      }
    }
  }
`;
