import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  img {
    cursor: pointer;
  }
`;

export const Title = styled.div`
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
`;

export const Subtitle = styled.div`
  overflow: hidden;
  color: ${({ theme }) => theme.colors.neonblue};
  text-overflow: ellipsis;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 700;
`;
