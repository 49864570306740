import styled from 'styled-components';

export const Wrapper = styled.div`
  width: max-content;
`;

export const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
`;

export const StatusWrapper = styled.div`
  cursor: pointer;
`;

export const TextWrapper = styled.div<{ isInReview: boolean }>`
  padding: 4px 8px;
  font-weight: 600;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, isInReview }) =>
    isInReview ? theme.colors.error : theme.colors.neoreg};
`;
