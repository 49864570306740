import { EGamificationRewardType } from '@constants';

import { IGamification } from '../gamification/gamification.contract';
import { RequestService } from '../request';

export class GamificationService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getMyRewards() {
    return this.fetcher<IGamification>(`/gamification/rewards/me`);
  }

  public getUserRewards(userId: string) {
    return this.fetcher<IGamification>(`/gamification/rewards/${userId}`);
  }

  public claimReward(type: EGamificationRewardType) {
    return this.putter(`/gamification/claim-reward/${type}`);
  }

  public getWeeklyPointCount() {
    return this.fetcher<number>(`/gamification/rewards/me/week`);
  }
}
